/* 
選手名をクリックした際のモーダルCSS（ピットストップ詳細）
Modal.cssに移行するべきか 
*/
.race-data {
    display: flex;
    flex-direction: column;
    min-height: 800px;
    padding: 10px;
    color: #ffffff;
}

.top-table {
    display: block;
    max-width: none;
    color: gray;
}

.driver-information {
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 1000px;
    margin: 0 160px auto 0;
}

.driver-information .driver-name-base {
    display: block;
    position: relative;
    width: 463px;
    height: 66px;
    background: url(../image/driver-name-info.png);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}

.driver-information .driver-name-info {
    position: absolute;
    top: 0;
    left: 145px;
    width: 65%;
    margin: 0;
    color: #ffffff;
    font-size: 32px;
    text-align: center;
    word-wrap: normal;
}

.driver-information .driver-carNo-base {
    display: block;
    position: relative;
    width: 262px;
    height: 66px;
    background: url(../image/driver-carNo-info.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    content: '';
}

.driver-information .driver-carNo-info {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 195px;
    width: 22%;
    margin: 0;
    color: #ffffff;
    font-size: 32px;
    text-align: center;
}

.top-table .top-table-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 20px;
}

.top-table-buttons {
    position: relative;
    margin: 70px 0;
    border: none;
    text-align: center;
}

.top-table-buttons button {
    width: 303px;
    height: 53px;
    border: none;
    background-color: #d9d9d9;
    font-weight: bold;
}
.top-table-buttons .display-on {
    background-color: #000000;
    color: #ffffff;
}

.top-table-buttons .lap-data-button {
    border-radius: 3px 0 0 3px;
}

.top-table-buttons .ots-usage-button {
    border-radius: 0 3px 3px 0;
}

.race-data .heatmap-example-table th, .race-data .heatmap-example-table td, .race-data .best-race-data-table th, .race-data .best-race-data-table td {
    color: #ffffff;
}

.driver-table .best-race-data-table {
    padding-top: 4vh;
    position: relative;
    font-family: 'Saira Condensed', sans-serif;
    border-collapse: separate;
    border-spacing: 0 15px;
}

.best-race-data-table::before {
    display: block;
    position: absolute;
    top: 35%;
    left: -5%;
    width: 5px;
    height: 60%;
    border-right: solid #ffffff .5px;
    border-left: solid #ffffff .5px;
    content: '';
}

.best-race-data-table::after {
    display: block;
    position: absolute;
    top: 35%;
    right: -40px;
    width: 5px;
    height: 60%;
    border-right: solid #ffffff .5px;
    border-left: solid #ffffff .5px;
    content: '';
}

/* .best-race-data-table thead ::before{
    content: '◯◯◯';
    position: absolute;
    top: -200%;
    left: -70px;
    display: inline-block;
    font-size: 0.55rem;
    font-weight: bold;
    color: #fff;
    transform: rotate(90deg);
    text-shadow: #fff 0px 0 4px;
}

.best-race-data-table thead ::after{
    content: '◯◯◯';
    position: absolute;
    bottom: -120px;
    right: -70px;
    display: inline-block;
    font-size: 0.55rem;
    font-weight: bold;
    color: #fff;
    transform: rotate(90deg);
    text-shadow: #fff 0px 0 4px;
} */

.best-race-data-table .table-tit ::before {
    content: none;
}

.race-data .best-race-data-table th, .race-data .best-race-data-table td {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    padding-bottom: 2vh;
}

.race-data .best-race-data-table tr {
    position: relative;
}

.race-data .best-race-data-table tr::before {
    display: block;
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.race-data .best-race-data-table tr::after {
    display: block;
    position: absolute;
    right: 0;
    bottom: -4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.lapdata_table {
    height: 1200px;
    overflow: scroll;
}


.driver-table {
    display: flex;
    flex-direction: column;
    width: 87%;
    margin: 0 auto;
    color: #ffffff;
}


.heatmap-example-table .heatmap-example {
    padding: 0;
}

.heatmap-example-table .heatmap-bar {
    height: 3vh;
}

.totalLap-driver-table-cover {
    position: relative;
    background-image: url(../image/totalLap-driver-table-bg.png);
    background-size: auto;
    background-repeat: no-repeat;
}

.tableBg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 101%;
}

.driver-table .totalLap-driver-table {
    display: table;
    width: 85%;
    margin: 0 auto;
    border-collapse: separate ;
    border-spacing: 0 15px;
}

.totalLap-driver-table thead {
    position: sticky;
    top: 0;
    background-color: #8B8B8B;
    z-index: 1;
}

.totalLap-driver-table th {
    font-weight: bolder;
    font-size: 24px;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
}

.totalLap-driver-table td {
    position: relative;
    border: 0;
    font-weight: bolder;
    font-size: 18px;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
}

.totalLap-driver-table td::before {
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    content: '';
}

.lap-data {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
}

.lap-data h3 {
    width: 125px;
}

.lap-data .lap-number {
    width: 75px;
}

.lap-data .ots-remainingtime-time {
    width: 250px;
}

.progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #FFE500;
    transition: width .5s;
}

.averages {
    display: flex;
    flex-direction: row;
    justify-content: left;
    min-width: 130px;
    margin: 0 auto;
    padding: 0 0 0 5px;
    gap: 3px;
}

.deg {
    flex-direction: row;
    justify-content: left;
    min-width: 78px;
    margin: 0 auto;

    gap: 3px;
}


/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    /* ピットストップ詳細 */
    .driver-information {
        display: block;
        width: 100%;
        margin: 0 auto;
    }

    .heatmap-bar tr {
        height: 2vh;
    }

    .best-race-data-table tr {
        height: 2vh;
    }

    .heatmap-example-table .heatmap-example {
        padding: 0;
    }

    .heatmap-example-table .heatmap-example tr {
        height: 2vh;
        padding: 0;
    }

    .averages {
        min-width: auto;
    }


    /* スマホ版にテーブルに疑似要素つけるとバグ発生 */
    .best-race-data-table::before, .best-race-data-table::after {
        content: none;
    }

    .race-data .best-race-data-table tr::before, .race-data .best-race-data-table tr::after {
        content: none;
    }

    .best-race-data-table thead ::before, .best-race-data-table thead ::after {
        content: none;
    }

    .totalLap-driver-table th {
        padding: 8px;
    }
}


@media only screen and (max-width: 743px) and (orientation: portrait) {
    /* 車両レコード */

    .driver-information .driver-name-base {
        width: 72vw;
        margin-right: auto;
        background-size: 100%;
    }

    .driver-information .driver-carNo-base {
        width: 36vw;
        margin-right: auto;
        background-size: 100%;
    }

    .driver-information .driver-name-info {
        top: 4vw;
        left: 24vw;
        font-size: min(5vw, 24px) ;
    }

    .driver-information .driver-carNo-info {
        top: 5vw;
        left: 24vw;
        font-size: min(5vw, 24px) ;
    }

    .best-race-data-table th {
        font-size: min(5vw, 24px) ;
    }

    .race-data .best-race-data-table th, .race-data .best-race-data-table td {
        font-size: min(3vw, 12px) ;
    }

    .totalLap-driver-table th {
        padding: 0;
        color: #000000;
        font-size: min(3vw, 12px);
    }

    .totalLap-driver-table td {
        font-size: min(3vw, 12px) ;
    }

    .top-table-buttons .lap-data-button, .top-table-buttons .ots-usage-button {
        width: 30vw;
    }

    .totalLap-driver-table tr {
        color: #000000;
    }

    .top-dialog-background {
        margin: 0;
    }

    .car-driver-top-info {
        flex-wrap: wrap;
        height: auto;
    }

    .car-driver-top-info div {
        width: 100%;
    }

    .car-driver-top-info .driver-name-info h4 {
        top: 1vw;
        left: 27vw;
        font-size: min(5vw, 24px);
    }

    .currentLap-info {
        height: 64%;
    }

    .pit-select-area .pit-action-area span {
        font-size: min(3vw, 12px);
    }

    .car-driver-top-info .pit-stop-times::before, .car-driver-top-info .pit-stop-times::after {
        display: none;
    }

    .currentLap-info {
        width: 32%;
    }

    .pit-stop-info {
        /* flex-direction:row; */
        justify-content: flex-start;
        align-items: center;
        height: auto;
    }

    .currentLap-info {
        background-size: 32%;
    }


    .pit-stop-info .pit-stop-times-tit-bg {
        width: 48%;
    }

    .car-driver-top-info .pit-select-area {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pit-stop-info .pit-stop-times-tit {
        top: 20%;
        left: 0;
        font-size: min(3vw, 24px);
    }

    .car-driver-middle-info {
        flex-direction: column;
        height: auto;

        gap: 0;
    }

    .after-line-graph .timeComparison-flex-container {
        height: 100%;
    }

    .currentLap-info {
        height: 24vh;
    }

    .after-line-graph, .pit-bar-graph {
        width: 80%;
    }

    .after-line-inner {
        width: 90%;
    }

    .timeComparison-flex-container {
        justify-content: flex-start;
        height: 100%;
    }

    .after-line-graph .timeComparison-flex-container .css-i1h90w-MuiStack-root {
        height: 16vh;
    }

    .after-line-graph .timeComparison-flex-container {
        justify-content: flex-start;
        width: 10%;
    }

    .fivelap-before-average, .fivelap-after-average {
        flex-direction: column;
    }

    .pit-table {
        width: 80vw;
        margin: 0 auto;
        font-size: 12px;
    }

    .pit-slower, .pit-faster {
        width: auto;
        margin: 0 auto;
        white-space: nowrap;
    }

    .scroll-content td span {
        font-size: 12px;
    }

    .pit-table td::after, .pit-table td::before, .separator-line, .separator-line, .sp-none {
        display: none;
    }
}

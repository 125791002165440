@charset 'UTF-8';

/* スマホ版　テーブルタイトル */
.sp-raceinfo-block {
    display: flex;
    align-items: center;
}

.event-choice .sp-raceinfo-block .sp-word {
    display: none;
}
/* スマホ版　テーブルタイトル　ここまで */

/* ========== Event Page ========== */
/* イベントページ全体 */
.event-choice-container {
    margin-bottom: 48px;
    font-family: 'Saira Condensed', sans-serif;
}

/* イベントテーブル タイトル　*/
.event-choice {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    width: 85vw;
    max-width: 1720px;
    margin-bottom: 48px;
    padding: 8px 80px 8px 0;
    background-color: rgba(65, 76, 77, .5);
}

.event-choice-container .event-tit-container {
    width: 451px;
    height: 124px;
    margin: 0;
    background-image: url(../image/evet-tit-bg.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.event-choice-container .event-tit-container h1{
    line-height: 116px;
}


.event-choice-tit .event-choice-tit-name {
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
    /*text-shadow: #4affff 1px 0 10px;*/
}

/* イベントテーブル タイトル　*/


/* イベントテーブル 各レースボックス　*/
.event-choice-tit {
    position: relative;
    margin-bottom: 72px;
    background-color: transparent;
}

.event-choice div {
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    text-align: left;
}

/* イベントテーブル 各レースボックス　ここまで*/

/* イベントテーブル 各レースボックス　左側の装飾　*/
.event-choice::before {
    position: absolute;
    top: 50%;
    left: 16px;
    width: 24px;
    height: 2px;
    background-color: #ffffff;
    content: '';
}

.event-after-item-choice::after {
    position: absolute;
    top: 33%;
    left: 48px;
    width: 16px;
    height: 16px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    background-color: transparent;
    content: '';
}
/* イベントテーブル 各レースボックス　左側の装飾　ここまで　*/

/* イベントテーブル 各レースボックス　右側の装飾　*/
.event-choice span {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: solid 2px #ffffff;
    border-left: solid 2px #ffffff;
    content: '';
}

.event-choice .top-page-dec-top-right {
    top: -8px;
    right: -8px;
    transform: rotate(90deg);
}

.event-choice .top-page-dec-bottom-right {
    right: -8px;
    bottom: -8px;
    transform: rotate(180deg);
}
/* イベントテーブル 各レースボックス　右側の装飾　ここまで　*/

/* .event-top-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1000px;
    margin: 0 auto;
    font-size: 20px;
} */

/* イベントページ　セレクター */
.event-filter {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    width: calc(85vw + 80px);
    max-width: 1720px;
    margin-bottom: 72px;
    font-size: 24px;
}

.event-filter div {
    position: relative;
}

.event-filter div:last-of-type select {
    margin-left: 32px;
}

.event-filter select {
    position: relative;
    width: 200px;
    padding: 16px;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    /* デフォルトのセレクター　矢印消す */

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

/* デフォルトのセレクター　矢印　水色追加 */
.event-filter div::before {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';

    pointer-events: none;
}

/* イベントページ　セレクター 　ここまで*/

/* イベント名・サーキット名・シリーズ名　各　幅調整 */
.event-choice-name {
    width: 20vw;
}

.event-choice-circuit-name {
    width: 15vw;
}

.event-choice-series-name {
    width: 18vw;
}

.dummy-text {
    width: 8vw;
}
/* イベント名・サーキット名・シリーズ名　各　幅調整　ここまで */

/* 選択ボタン */
.event-choice-container .event-button {
    position: relative;
    width: 8vw;
    height: 40px;
    padding: 5px;
    border: none;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.event-choice-container .event-button:hover {
    border: 2px solid #4affff;
    box-shadow: 0 0 4px rgba(255, 255, 255, .7);
    background-color: transparent;
    color: #4affff;
    line-height: 0;
    /*text-shadow: 0 0 4px rgba(255, 255, 255, .7);*/
}

.event-choice-container .event-button::before {
    position: absolute;
    top: 0;
    left: -16px;
    width: 8px;
    height: 40px;
    background-color: #ffffff;
    content: '';
}

.event-choice-container .event-button:hover:before {
    position: absolute;
    top: -2px;
    left: -18px;
    border: 2px solid #4affff;
    box-sizing: border-box;
    box-shadow: 0 0 4px rgba(255, 255, 255, .7);
    background-color: transparent;
}
/* 選択ボタン　ここまで */


/* イベント各詳細ページ */

/* ヘッダー */
.event-selected-top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin: 0 auto 10px;
    font-family: 'Noto Sans JP', sans-serif;
}

.event-choice-tit::before {
    position: absolute;
    bottom: -48px;
    left: 0;
    width: 100%;
    max-width: 1724px;
    height: 89px;
    background-image: url(../image/event-choice-tit-bk.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    content: '';
}
/* タイトル */
.event-selected-top-text h1 {
    font-size: 32px;
    text-align: center;
}

/* イベント一覧に戻るボタン */
.event-selected-top-text .event-list-button {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 200px;
    height: 56px;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.event-selected-top-text .event-list-button .border-line {
    display: block;
    display: block;
    width: 80%;
    height: 2px;
    background-color: #000000;
}

.event-choice-container .event-list-button::after {
    position: absolute;
    top: -199%;
    right: -55%;
    transform: scale(.11) rotate(180deg);
    content: url(../image/allow-right.png);
}

/* イベント一覧に戻るボタン　ここまで */

/* SP版　イベント一覧に戻るボタン　ここまで */
.backBtn-img {
    display: none;
}

/* .event-choice-container .header-menu {
    display: flex;
    justify-content: flex-start;

    gap: 64px;
} */

/* 燃焼・ピットインシュミレーション・タイヤ内圧　ボタン　エリア */
.eventPage-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 5vw;
    margin-bottom: 72px;
    margin-top: 64px;
    gap: 48px;
}

/* 燃焼・ピットインシュミレーション・タイヤ内圧　ボタン */
.event-choice-container .eventPage-btn-container button {
    position: relative;
    width: 305px;
    height: 46px;
    padding: 12px 10px;
    border: none;
    box-sizing: border-box;
    background-image: url(../image/common-button.svg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

/* .event-choice-container .eventPage-btn-container button::before {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 57px;
    background-color: #fff;
    bottom: -14px;
    right: 5px;
    transform: rotate(47deg);
} */

/* レースタイプ　選択エリア */
.event-race-menu {
    font-family: 'Noto Sans JP', sans-serif;
}

/* レースタイプ */
.event-race {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    min-width: 100%;
    min-height: 50px;
    margin: 0 auto 40px;
    padding: 0 0 0 106px;
    box-sizing: border-box;
}

.event-race::before {
    display: inline-block;
    position: absolute;
    bottom: -30px;
    left: 3vw;
    width: 50vw;
    height: 60px;
    background-image: url(../image/event-race-tit.png);
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}

.event-race .race-date, .event-race .race-name {
    font-weight: bold;
    font-size: min(1.3vw, 24px);
}

.race-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.5vw;
    max-width: 40%;
}

/* .race-name {
    margin-left: 1vw;
} */

/* レースタイプ　ここまで */

/* .event-race::after {
    position: absolute;
    content: '';
    display: block;
    width: 10%;
    height: 60px;
    right: 8vw;
    top: 2vh;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJsAAABACAYAAAANmAVgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQPSURBVHgB7Z2Bbds8EIUvPzKAR9A/QTWCNmg2qEbwBnUncDawO0HbCZQNnA2kTqBs8EqWVCBRpCjbShpT7wMOUkGKLpCHO95RZ4sQQgghhBBCCCGEEEIIuRnuhCQHgExdOuto7PX57u7uRf4BFNuNo4S1UZdc2Wd71baJPNYoe1b2S9mTEl8jhIRQIiuUHZS1uJ6D9YZvCj3bjaLEUalLERjWYbKx1oXMTIzHyyXMUdk3ejoyQImt7HmmWtmjsgcbVmPP5sq2yk4eL6c95VYI6dCiUrbT4VSuwArvGAitGyFkDlaQmbVNZG5uPSQcj5kJIS5WVF/1fg7+xKG1Y3strsAaOwqOBIHJTCucj96zlZ71th7BMaSuGZgwuZ8QU2uFUmO6RPLD9V4YJiCaSsg6gQmZtUc4lRVKFnhGZ6y+hECvVTjzXcExS10bMJv5NuadImtkAdF9cea5e7hcyDqwIukLTd8/yIUEPGThzKl6YwynawBmj1Y7oS+TK/EIru2v6xF4KSRtYMoaXkEssLYruMoZ74fTWki6WDHgLb0LTAnFG06tV+17t4tDN/ngwBwfeb1O5Dktktk1MgyThinvxr1bqjhepYjM3diQW/dDH2a8SuTxoBtn3Y4WLPSmB0xt7FU0kbmh+ltfdHlkjao3f+uM6bdLthRaotg/cMdjZG6NOPWUWDAs5v4Ush4cT1NMzCsxn93EOv1QysxzTWDorfKJeRXm00Y+8xVZiHsq9ybIevcvM+fF+JulTnRaNd16kXmzuZfz/oMkISICWjwB0GL7X8hHR9e0shnzGpnvPJrI+KvYluozvWcnzcdHhTHd45nZf+o9WxOY+l3CHVe+uaHP6+8Ln2Uh/hNyC/zu3RehScpxHNXlSeI0yqZKKHngs0lqwKmBYXhmGcsiddniNJGF1oifIlS9+aWQ9MCwy90V3OzjKju/xLDxRd/vEO+yco+rMiHp4XiUnTP2LofgGB74H4SkCYYnAK0z5r7is3g/AMYnEJmQNIkJCuN+gEIWAuMDfHq11HEE5du7nZzxqxtQPEKr6dVWAMZ9Bntn3PcK0cUhFeb1JbeBJhOyDjB+PfvBGfcJ7nROWIVpB6ycNRbxlOTGgOkDDXobhF+S1KLbWsG6b9l2X5VVeZ6rKbSVgpltexgnDZfwCL55u24w7nyvEf5ahSPOp8KCGS25cTCufdUIbOCt6EorPN+RlX72APYQkBAewWlvVwohbwHGJQrgnb7lm6wQhDPQi0QHk4RsuXcjXqxAQsmA3qftrRfMPc91e7o9xuUPHk8RP/AXZa+hBpMGMgVM8faSskdHhXfKTvkLL4lgxVJY+yTjH0p7saZ7CvSr3k9ifrdqkWYWQgghhBBCCCGEEEIIIYnxByL8xn8CX0ePAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: bottom;
  } */

/* レースタイプ　選択ボタン */
.event-race-buttons {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 15px;
    left: 55vw;
    z-index: 1;
    width: 31vw;
    height: 60px;
}

.event-race-buttons::after {
    display: block;
    position: absolute;
    top: -15px;
    right: -11vw;
    width: 10vw;
    height: 64px;
    background-image: url(../image/event-race-tit-after.png);
    background-position: right;
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
}

.event-race-button {
    position: relative;
    height: 36px;
    padding: 5px;
    border: none;
    border: solid 2px #ffffff;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.event-race-button:nth-of-type(2), .event-race-button:nth-of-type(3) {
    margin-left: 5vw;
}

.view-button:hover, .analysis-button:hover {
    border: 2px solid #4affff;
    box-shadow: 0 0 4px rgba(255, 255, 255, .7);
    background-color: transparent;
    color: #4affff;
    /*text-shadow: 0 0 4px rgba(255, 255, 255, .7);*/
}

.event-race-button::before {
    position: absolute;
    top: -2px;
    left: -16px;
    width: 8px;
    height: 36px;
    background-color: #ffffff;
    content: '';
}

.view-button:hover::before, .analysis-button:hover::before {
    position: absolute;
    top: -2px;
    left: -18px;
    border: 2px solid #4affff;
    box-sizing: border-box;
    box-shadow: 0 0 4px rgba(255, 255, 255, .7);
    background-color: transparent;
}
/* レースタイプ　選択ボタン ここまで */


/* B5サイズ ipad横　*/
@media only screen and (max-width: 1366px) {
    /* イベント各詳細ページ */
    /* レースタイプ　文字サイズ */
    .event-race .race-date, .event-race .race-name {
        font-weight: bold;
        font-size: min(1.5vw, 24px);
    }

    .event-choice-container .eventPage-btn-container button:first-child {
        margin-right: 0;
        margin-bottom: 16px;
    }

    .race-date {
        gap: 3vw;
    }

    .event-race {
        padding: 0 0 0 72px;
    }
    .eventPage-btn-container {
        display: flex;
    }
}


/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .event-race .race-date, .event-race .race-name {
        font-weight: bold;
        font-size: 12px;
    }
    /* イベントテーブル タイトル　*/
    .event-choice {
        width: 95vw;
        padding: 1% 4% 1% 0;
        box-sizing: border-box;
    }

    /* イベントページ　セレクター */
    .event-filter {
        margin-bottom: 4%;
    }

    .event-filter select {
        padding: 6% 0;
    }

    .event-filter div:last-of-type select {
        margin-left: 4%;
    }
    /* イベントページ　セレクター ここまで */

    /* 文字サイズ　 */
    .event-choice-tit .event-choice-tit-name {
        font-size: min(2vw, 24px);
    }

    .event-choice div {
        font-size: min(2vw, 20px);
    }

    .dummy-text {
        width: 12vw;
    }

    .event-choice-container .event-button {
        width: 12vw;
    }



    .event-choice-container .event-tit-container {
        width: 43%;
    }

    .event-choice-container .header-menu {
        flex-direction: column-reverse;
        width: 55%;

        gap: 24px;
    }

    .event-selected-top-text .event-tit-container h1 {
        font-size: 24px;
    }

    .event-selected-top-text .event-list-button {
        margin-left: auto;
    }

    .eventPage-btn-container {
        display: flex;
        width: 90vw;
        margin: 0 auto 8%;

        gap: 4%;
    }
    .event-choice-container .eventPage-btn-container button {
        width: 52%;
        font-size: min(1.5vw, 16px);
    }
    .event-choice-container .eventPage-btn-container button::before {
        right: 6%;
        bottom: -42%;
        transform: rotate(51deg);
    }

    .event-race {
        margin: 0 auto 8%;
        padding: 0 0 0 3%;
    }

    .event-race::before {
        left: 0;
        width: 45vw;
    }

    .event-race-buttons {
        left: 48vw;
        width: 40vw;
    }

    .race-date {
        gap: 1vw;
    }
}

@media only screen and (max-width: 743px) and (orientation: portrait) {
    .sp-raceinfo-block {
        display: block;
        width: 64vw;
    }

    /* SP版　イベント一覧に戻るボタン　ここまで */
    .backBtn-img {
        display: block;
    }

    .event-choice .sp-raceinfo-block .sp-word {
        display: inline-block;
    }

    .sp-raceinfo-block div {
        margin-bottom: 8%;
        padding-bottom: 1%;
        border-bottom: solid 1px #ffffff;
    }

    .sp-raceinfo-block div:last-of-type {
        margin-bottom: 0;
    }

    .sp-raceinfo-block div div {
        border-bottom: none;
    }

    /* セレクターの幅*/
    .event-filter {
        justify-content: center;
        width: 100vw;
    }

    /* セレクターの各ボタン */
    .event-filter select {
    }

    /* セレクターの各ボタン　余白*/
    .event-filter select:last-of-type {
        width: 160px;
        margin: 0;
        padding: 4% 0;
        font-size: 12px;
    }

    .event-filter div::before {
        top: 60%;
        right: 8px;
    }
    /* イベント一覧　タイトル文字サイズ */
    .event-choice-tit .event-choice-tit-name, .event-choice div {
        font-size: min(4vw, 14px);
    }

    .event-choice-tit {
        justify-content: space-between;
        margin-bottom: 6%;
        padding: 0 4%;
    }

    .event-choice {
        display: none;
    }

    .event-after-item-choice {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100vw;
        padding: 4vw;
    }


    .event-choice::before, .event-choice-tit::before, .event-after-item-choice::after, .event-choice span {
        display: none;
    }

    .event-choice-container .event-button {
        height: 24px;
        font-size: min(3vw, 12px);
    }

    .event-choice-container .event-button::before {
        height: 24px;
    }

    .event-choice-name, .event-choice-circuit-name, .event-choice-series-nam, .event-choice-series-name {
        width: auto;
        padding-left: 88px;
    }

    .dummy-text {
        display: none;
    }

    .event-choice-container .event-button {
        width: 14vw;
    }

    .event-choice .sp-word {
        display: inline-block;
        width: 72px;
        border: none;
    }

    .event-filter {
        margin-bottom: 8%;
    }

    .sp-word {
        color: #ffffff;
        font-weight: bold;
        font-size: 14px;
        /*text-shadow: #4affff 1px 0 10px;*/
    }


    .event-selected-top-text {
        flex-direction: row;
        margin-bottom: 4%;
    }

    .event-selected-top-text .event-list-button {
        justify-content: center;
        width: 100%;
        height: 42px;
        padding: 0;
    }

    .event-selected-top-text .event-list-button {
        /* font-size: 12px;
        background-color: transparent;
        border: none; */
        width: 40px;
        height: 40px;
        padding: 0;
        border-radius: 50%;
                box-shadow: 0 3px 5px rgba(255, 255, 255, .3);
        background: #ffffff;

        -webkit-box-shadow: 0 3px 5px rgba(255, 255, 255, .3);
    }

    .event-selected-top-text .event-list-button img {
        width: 56%;
        height: 56%;
        margin: 0 auto;
    }

    .event-selected-top-text .event-list-button span {
        display: none;
    }

    .event-selected-top-text .event-list-button .border-line {
        display: none;
    }

    .event-choice-container .header-menu {
        width: 10%;
    }

    .event-choice-container .eventPage-btn-container button {
        height: 60px;
        margin-bottom: 0;
        background-image: url(../image/common-button-sp.png);
        font-size: min(2.5vw, 16px);
    }

    .event-selected-top-text .event-list-button .border-line {
        width: 64%;
        height: 1px;
    }

    .event-choice-container .event-list-button::after {
        position: absolute;
        top: -112px;
        right: -112px;
        content: none;
    }

    .event-choice-container .event-tit-container {
        width: 80%;
    }

    .event-race {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        margin-bottom: 8%;
    }

    .event-race .race-date {
        margin-bottom: 4%;
    }

    .event-race::before {
        width: 100vw;
    }

    .event-race-buttons::after {
        display: none;
    }

    .event-race-buttons {
        justify-content: start;
        position: relative;
        top: 0;
        left: 16px;
    }

    .event-race .race-date, .event-race .race-name {
        align-items: flex-start;
        font-size: 14px;
    }

    .event-race-buttons {
        justify-content: space-around;
        width: 80vw;
        height: 56px;
    }

    .event-race-buttons .event-race-button {
        width: 16vw;
        height: 24px;
        font-size: min(3vw, 12px);
        line-height: 0;
    }

    .event-race-button::before {
        height: 24px;
    }

    .event-race-button:nth-of-type(2), .event-race-button:nth-of-type(3) {
        margin-left: 8vw;
    }

    .event-time {
        display: flex;
        flex-direction: column;
    }

    .event-time {
        display: flex;
        flex-direction: column;
    }
}

/* 横画面のスマホ版 */

@media only screen and (max-width: 932px) and (orientation: landscape) {
    .sp-raceinfo-block {
        display: block;
        width: 64vw;
    }

    .event-choice .sp-raceinfo-block .sp-word {
        display: inline-block;
    }

    .sp-raceinfo-block div {
        margin-bottom: 8%;
        padding-bottom: 1%;
        border-bottom: solid 1px #ffffff;
    }

    .sp-raceinfo-block div:last-of-type {
        margin-bottom: 0;
    }

    .sp-raceinfo-block div div {
        border-bottom: none;
    }

    /* セレクターの幅*/
    .event-filter {
        justify-content: center;
        width: 100vw;
    }

    /* セレクターの各ボタン */
    .event-filter select {
    }

    /* セレクターの各ボタン　余白*/
    .event-filter select:last-of-type {
        margin: 0;
        padding: 4% 0;
        font-size: 12px;
    }

    .event-filter div {
        width: 32%;
    }

    .event-filter div::before {
        right: 20px;
    }
    /* イベント一覧　タイトル文字サイズ */
    .event-choice-tit .event-choice-tit-name, .event-choice div {
        font-size: min(4vw, 14px);
    }

    .event-choice-tit {
        justify-content: space-between;
        margin-bottom: 6%;
        padding: 0 4%;
    }

    .event-choice {
        display: none;
    }

    .event-after-item-choice {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100vw;
        padding: 4vw;
    }


    .event-choice::before, .event-choice-tit::before, .event-after-item-choice::after, .event-choice span {
        display: none;
    }

    .event-choice-container .event-button {
        height: 24px;
        font-size: min(3vw, 12px);
    }

    .event-choice-container .event-button::before {
        height: 24px;
    }

    .event-choice-name, .event-choice-circuit-name, .event-choice-series-nam, .event-choice-series-name {
        width: auto;
        padding-left: 80px;
    }

    .dummy-text {
        display: none;
    }

    .event-choice-container .event-button {
        width: 14vw;
    }

    .event-choice .sp-word {
        display: inline-block;
        width: 72px;
        border: none;
    }

    .event-filter {
        margin-bottom: 8%;
    }

    .sp-word {
        color: #ffffff;
        font-weight: bold;
        font-size: 14px;
        /*text-shadow: #4affff 1px 0 10px;*/
    }


    .event-selected-top-text {
        flex-direction: column-reverse;
        margin-bottom: 4%;
    }

    .event-selected-top-text .event-list-button {
        justify-content: center;
        width: 32vw;
        height: 42px;
        padding: 0;
    }

    .event-selected-top-text .event-list-button {
        font-size: 12px;
    }

    .event-choice-container .header-menu {
        width: 85vw;
    }

    .event-selected-top-text .event-list-button .border-line {
        width: 64%;
        height: 1px;
    }

    .event-choice-container .event-list-button::after {
        position: absolute;
        top: -112px;
        right: -112px;
    }

    .event-choice-container .event-tit-container {
        width: 85vw;
    }

    .event-race {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        margin-bottom: 8%;
    }

    .event-race .race-date {
        margin-bottom: 4%;
    }

    .event-race::before {
        width: 100vw;
    }

    .event-race-buttons::after {
        display: none;
    }

    .event-race-buttons {
        justify-content: start;
        position: relative;
        top: 0;
        left: 16px;
    }

    .event-race .race-date, .event-race .race-name {
        align-items: flex-start;
        font-size: 14px;
    }

    .event-race-buttons {
        justify-content: space-around;
        width: 80vw;
        height: 56px;
    }

    .event-race-buttons .event-race-button {
        width: 16vw;
        height: 24px;
        font-size: min(3vw, 12px);
    }

    .event-race-button::before {
        height: 24px;
    }

    .event-race-button:nth-of-type(2), .event-race-button:nth-of-type(3) {
        margin-left: 8vw;
    }

    .event-time {
        display: flex;
        flex-direction: column;
    }

    .event-time {
        display: flex;
        flex-direction: column;
    }
}
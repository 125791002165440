/* タイトル下ボタン　全体 */
.main-buttons {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    gap: 8px;
}

.main-button-dropdown.select-container {
    position: relative;
    font-family: 'Noto Sans JP', sans-serif;
}

/*タイトル下ボタン　 個々*/
.main-button.dropdown-button {
    height: 35px;
    width: 160px;
    font-size: 16px;
    text-align: center;
    line-height: 35px;
    background: url(../image/button-container.png) no-repeat center center / 100% 100%;
    cursor: pointer;
}

.main-button.dropdown-button.active{
    height: 35px;
    width: 160px;
    font-size: 16px;
    color: #4AFFFF;
    /*text-shadow: #4AFFFF 1px 0 10px;*/
    text-align: center;
    line-height: 35px;
    background: url(../image/active-button-container.png) no-repeat center center / 100% 100%;
    cursor: pointer;
}

/* .main-button.dropdown-button::before {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 44px;
    background-color: #fff;
    bottom: -15px;
    right: 7px;
    transform: rotate(43deg);
} */

/*タイトル下ボタン　 個々　ホバー時*/
.dropdown-button:hover {
    opacity: 0.8;
}

/*タイトル下ボタン　 個々　選択時*/
/* .dropdown-button.active {
    background-color: rgb(82, 205, 224);
    border: 1px solid rgb(82, 205, 224);
} */

/*最適化ボタン*/
.dropdown-item {
    padding-left: 5px;
    margin: 6px 0;
}

.dropdown-item:hover {
    color: #4AFFFF;
    cursor: pointer;
}

/*最適化ボタン　ここまで*/


/*PW/BOP情報ボタン*/
#main-button-dropdown-pwBop .dropdown-content {
    min-width: 200px;
}

.sub-dropdown-content.show,
.dropdown-content.show {
    display: block;
}

#main-button-dropdown-pwBop .sub-dropdown-content {
    left: 210px;
}

.sub-dropdown-content {
    display: none;
    min-width: 150px;
    background-color: #000000;
    color: #FFFFFF;
    position: absolute;
    z-index: 1;
    left: 180px;
    margin-top: 0.7vh;
    padding: 10px;
    border: 1px solid #FFFFFF;
    font-weight: bold;
}

/*PW/BOP情報ボタン　ここまで*/

/*マニュアルDLボタン*/
.dropdown-content {
    display: none;
    min-width: 150px;
    background-color: #000000;
    color: #FFFFFF;
    position: absolute;
    z-index: 1;
    left: 1px;
    margin-top: 0.7vh;
    padding: 10px;
    border: 1px solid #FFFFFF;
    font-weight: bold;
    z-index: 2;
}

/* 最適化　プログレスバー　 */
.loading-container {
    position: absolute;
    right: 24px;
    width: 15%;
    height: 40%;
    display: none;
}

#myProgress {
    height: 100%;
    width: 100%;
    /* 初期のスタイルをリセット */
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #fff;
    border-radius: 10px;
}

#myProgress::-webkit-progress-bar {
    background-color: none;
    background-color: #0060c2;
    border-radius: 10px;
    /* opacity: 0; */
}

/* バーの色 */
#myProgress::-webkit-progress-value {
    background-color: #03DBD0;
    border: 1px solid #0060c2;
    border-radius: 10px;
}

/* 最適化　プログレスバー　ここまで　 */


/* 横画面のタブレット */

@media only screen and (max-width: 1366px) and (orientation: landscape) {
    .main-button.dropdown-button {
        padding: 2px 7px;
    }
}

/* 縦画面のタブレット */

@media only screen and (max-width: 1366px) and (orientation: portrait) {
    .main-button.dropdown-button {
        padding: 2px 7px;
    }
}
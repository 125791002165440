@charset 'UTF-8';
/* @import 'reset.css'; */

/*　ーーーーー　グーグルフォント　ーーーーー　*/
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/* F1フォント参考フォント */
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;300;400;600;700;900&display=swap');

html body {
    font-family: 'Noto Sans JP', sans-serif;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    padding: 0;
    background-size: 20px 20px;
    background-color: #242424;
    color: #ffffff;
    font-weight: 400;
    font-size: min(1.0vw,16px);
    font-family: 'Saira Condensed', sans-serif;
    cursor: default;

    scrollbar-width: none;
    -ms-overflow-style: none;
}

body::-webkit-scrollbar {
    display: none;
}

p {
    display: inline-block;
}

/* フォントサイズ　デフォルト */
h1 {
    font-size: 32px;
}
h3 {
    font-size: 24px;
}

button {
    font-size: 16px;
}

form input, button {
    width: 300px;
    height: 56px;
    padding: 5px;
    border: solid 2px #ffffff;
    box-sizing: border-box;
    background: transparent;
    font-size: 16px;
    cursor: pointer;
}


input[type='text']:focus, .login-form input[type='password']:focus, select:focus {
    outline: solid 2px #4affff;
}

input[type='checkbox'] {
    position: relative;
    width: 23px;
    height: 23px;
    margin: 0 8px 0 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #d9d9d9;
    outline: none;
    cursor: pointer;


    appearance: none;
}

/* 2番目以降のinput要素に対して設定 */
input[type='checkbox']:nth-child(n + 2) {
    margin-left: 2rem;
}

input[type='checkbox']:checked {
    border-color: #d9d9d9;
    background-color: #d9d9d9;
}

/* チェックマークのスタイル */
input[type='checkbox']:checked::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 12px;
    height: 5px;
    border: 1px solid #4286da;
    border-width: 0 0 2px 2px;
    content: ''; 
}

/* 0613追記(app.cssからコピー) */
.text {
    font-weight: bolder;
    font-size: 25px;
    fill: #000000; /* テキストの色 */
    stroke: #ccdfcb; /* ふちどりの色 */
    stroke-width: 2px;  /* ふちどりのふとさ */
}

.result_container_displaynone {
    display: none;
    padding-top: 10px;
}

.svg_view {
    margin-left: 10px;
}

@keyframes flash {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }


    100% {
        opacity: 1.0;
    }
}

/* .relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.flex {
    display: flex;
} */
/* 横画面のタブレット */
@media only screen and (max-width: 1366px) and (orientation: landscape) {
}

/* 縦画面のタブレット */
@media only screen and (max-width: 1366px) and (orientation: portrait) {
}

@media only screen and (max-width: 743px) and (orientation: portrait) {
}
/* ========== Live ========== */

/* デフォルト設定 */
.live-table {
    height: 10%;
    overflow-x: hidden;
}

.live-table-header {
    position: sticky;
    top: 0;
    z-index: 996;
    background-color: #404b50;
}

.live-table .live-table-header tr th {
    vertical-align: middle;
}

.live-table .live-table-header tr .css-1ndpvdd-MuiTableCell-root {
    padding: 0;
}

.cell-items{
    height: 30px;
}

/* 偶数行のスタイル */
.table-row-even {
    background-color: #f0f0f0;
    /* 偶数行の背景色 */
}

/* 奇数行のスタイル */
.table-row-odd {
    background-color: #ffffff;
    /* 奇数行の背景色 */
}

/* ピット・インしたTableRowの背景 */
.isInPit {
    opacity: .6;
    background-color: black; 
}

.current-sector {
    background-color: #FFE500;
}

.no-data {
    min-width: 50px;
    background: white;
}

.live-table-row .no-data-black {
    background: white;
    color: black;  
}

.overall-best {
    background-color: #b8a1e6;
}

.personal-best {
    background-color: #a1e6c3;
}

.first-row .sector-cell {
    border-top: none;
}

.numberOfLaps {
    font-size: .7rem;
}


/* liveのヘッダー */
/* 携帯版のヘッダーを非表示 */
/* .mobile-header {
    display: none;
} */

/* クリックできるところのカーソル変更 */
.live-table .follow-parent,
.live-table .race-data-clicker,
.live-table .name-and-number,
.live-table .pit-cells {
    cursor: pointer;
}

/* 走行時間ヘッダー */
.race-data-driver-time-white.bpdRxC {
    min-width: 100px;
    color: white;
    font-weight: 700;
    font-size: min(1.0vw, 20px);
}
/* 走行時間ヘッダー 　警告*/
.race-data-driver-time-red.bpdRxC {
    min-width: 100px;
    color: rgb(250, 106, 106);
    font-weight: 700;
    font-size: 1vw;
}

/* 天気予想カード */
.sidemenu-weather-card {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 30;
    width: 250px;
    padding: 4px;
    border: 1px solid #ffffff;
    border-right: none;
    border-left: none;
    background-image: linear-gradient(rgba(225, 225, 225, .6) 0%, rgba(225, 225, 225, .8) 2%, rgba(87, 107, 115, .6) 8%, rgba(87, 107, 115, .6) 97%, rgba(225, 225, 225, .8) 100%);
    cursor: pointer;
    Height: 96px;
}

.sidemenu-weather-card img {
    width: 60px;
    height: auto;
    margin-right: 5px;
}

.sidemenu-weather-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: .3vw;
    font-size: 12px;
    line-height: 1.5;
}

.sidemenu-weather-inner {
    display: flex;
    align-items: center;
    margin: 3px 0 1px 0;
}

.sidemenu-weather-wrapper {
    display: flex;
    align-items: center;
    min-width: 64px;
}

.sidemenu-weather-wrapper.precipitation-forecast {
    min-width: auto;
    margin-right: 5px;
}

.sidemenu-weather-text.big {
    margin: -6px 5px 0 0;
    margin-right: 5px;
}

.sidemenu-weather-title {
    margin: -6px 0 0 0;
}

.sidemenu-weather-img {
    width: 20px;
    height: 20px;
    vertical-align: middle;
}
/* 天気予想カード ここまで*/


/* ダッシュボード */
.container_dashboard{
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 5px;
}

.result_container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.result_container > div::-webkit-scrollbar {
    display: none;
}

/* ライバルとの差　コンテンツ */
.result_container .rival-tavle-container {
    margin-top: 32px;
    background-color: transparent;
    overflow: visible;
}

.map-container {
    position: relative;
    width: 470px;
    height: 100vh;
    margin: 0 3%;
    background: center center / 100% 100%  no-repeat url('../image/map-container_live.png');
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 28px;
    padding-left: 32px;
    box-sizing: border-box;
    gap: 16px;
    height: 100vh;

}

.map-container-size {
    display: flex;
    font-size: 14px;
    gap: 10px;
    order: 0;
}

/* mapのサイズ変更 */
.map-container-size-clicked {
    text-decoration: underline;
    cursor: pointer;
}

.map-container-size-not-clicked {
    cursor: pointer;
}
/* mapのサイズ変更　ここまで */


.map-container svg {
    left: 50%;
    z-index: 20;
    margin-left: 0;
    order: 1;
    box-shadow: 1px 1px 5px rgba(255,255,255,.8),
    1px -1px 5px rgba(255,255,255,.8),
    -1px 1px 5px rgba(255,255,255,.8),
    -1px -1px 5px rgba(255,255,255,.8);
}

.map-container .pit-info-container {
    width: calc(100% - 50px);
    order: 4;
}

.map-container .pit-info-container span {
    margin-top: 8px;
}

.result_container > div:nth-of-type(2) {
    max-height: calc(100vh - 15px);
    margin-top: 16px;
    overflow-y: scroll;
    background-color: transparent;
}

.result_container.live {
    height: 100vh;
    padding: 0 2px 0 0;
} 


.result_container_map > div {
    overflow: auto;
}

.map-container.map-container-medium .map-in-pit-container, 
.map-container.map-container-large .map-in-pit-container {
    display: inline;
}

/* 「中」サイズMap */
.map-container-medium {
    width: 550px;
}

.map-container.map-container-medium p {
    left: 107px;
}

.map-container.map-container-medium .predict-container {
    top: 65%;
}

.map-container.map-container-medium .pit-info-container {
    top: 77%;
}

/* 「中」サイズMap　ここまで */

/* 「大」サイズMap */
.map-container-large {
    width: 610px;
}

.map-container.map-container-large p {
    left: 120px;
}

.map-container.map-container-large .predict-container{
    top: 72%;
}

.map-container.map-container-large .pit-info-container {
    top: 83%;
}
/* 「大」サイズMap　ここまで */


/* smallのとき */
.map-container .predict-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 50px);
    order: 2;
}



.map-in-pit-container div {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 3px 3px 3px 0;
    border: 1px solid #000000;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1rem;
    line-height: 30px;
    text-align: center;
}

.map_option_container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    order: 3;
}

.map_option_container::after {
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 2px;
    box-shadow: 0 0 7px #ffffff;
    background-color: #ffffff;
    content: '';
}

.map_option_container div{
    align-items: center;
    display: flex;
}

.map_option_carNumber {
    display: inline-block;
    width: 45px;
    height: 26px;
    border-radius: 3px;
    background-color: #ffffff;
    color: #000000;
    text-align: center;
}

.map_option_button {
    width: 140px;
    height: 48px;
    border-radius: 5px;
    background-color: #ffffff;
}

.map_option_button:hover{
    opacity: .7;
}

.display-race-none {
    text-align: center;
}

/* 表示切り替えで表示したマップ（一画面マップ） */

.one-screen-map {
    width: 90vw;
    height: 100vh;
    overflow-y: scroll;
    background: center center / 100% 100%  no-repeat url('../image/map-container.png');
    max-height: calc(100vh - 296px);
    flex-direction: row;
    justify-content: center;
    padding-top: 5%;
    padding-left: 0;
}

.one-screen-map {
    width: 90vw;
    height: 100vh;
    overflow-y: scroll;
    background: center center / 100% 100%  no-repeat url('../image/map-container.png');
    position: absolute;
    left: calc(100vw - 95%);
    max-height: calc(100vh - 296px);
}



/* マップ本体 */
.one-screen-map svg {
    order: 1;
    width: 66%;
    height: 80%;
}

/* ピットイン情報 */
.one-screen-map .pit-info-container {
    order: 0;
    width: 20%;
    height: 80%;
    padding: 0;
    padding: 15px 0 0;
    border-top: solid 1px #ffffff;
    border-bottom: solid 1px #ffffff;
    box-sizing: border-box;
    background-color: rgba(255,255,255,.2);
}

.one-screen-map .pit-info-container span {
    display: block;
    margin-top: 0;
    margin-bottom: 2%;
}

.one-screen-map .predict-container{
    width: auto;
    position: absolute;
    top: 4vh;
    left: 6vw;
}

.one-screen-map .map_option_container{
    position: absolute;
    top: 4vh;
    left: 16vw;
    width: auto;
    gap: 16px;
}
.one-screen-map .map_option_container::after{
content: none;
}

.one-screen-map .map_option_button{
    height: 32px;
    line-height: 20px;
}

.one-screen-map section:nth-of-type(2){
    width: 66%;
    order: 2;
}
.one-screen-map section:nth-of-type(2) svg{
    width: 100%;
    height: revert-layer;
}



/* Liveテーブル　ヘッダー */

.header-div {
    font-family: 'Titillium Web', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0;
}

.header-inner-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 18px;
    gap: 8px;
}

.header-inner-div select {
    width: 90px;
    padding: 5px;
    border: solid 2px #ffffff;
    border-radius: 0;
    background-color: #404b50;
    color: #ffffff;
}


/* フォロー */
.live-table .follow-parent {
    position: relative;
}

.follow-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* フォローの列ここまで */

/* ライバルの列 */

.rival-img {
    width: 28px;
    height: 16px;
    vertical-align: middle;
}

/* ライバルの列ここまで */

/* 順位とドライバー名 */

.position-cell{
    min-width: 50px;
}

.race-name{
    min-width: 100px;
}

.image-and-position, .name-and-number {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

    gap: 8px;
}

.image-and-position .tableCell-icon {
    margin-left: 0;
}

.name-and-number {
    width: max(8vw, 110px);
    padding: 5px 8px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: inset 0 0 5px #4affff;
    background: #222f3f;
}

.name-and-number:hover {
    box-shadow: inset 0 0 5px #4affff;
    background: #4affff;
}

.table-position {
    position: absolute;
    left: calc(100% - 20px);
}

.table-name {
    overflow: hidden;
    white-space: nowrap;
}

.table-number {
    width: 35%;
    height: 10%;
    border-radius: 5px;
    text-align: center;
}

/* 順位とドライバー名 ここまで */


/* セクター */
.header-inner-div-width {
    min-width: 50px;
    /*font-size: min(.8vw, 15.3px);*/
}

.header-sector-cells {
    width: 72px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0 4px;
}

.header-sector-cells div:first-of-type{
    padding-right: 2px;
}

.number-of-cars {
    position: relative;
    width: 32px;
    height: 32px;
    line-height: 22px;
    padding: 4px;
    box-sizing: border-box;
    background-color: #222f3f;
    font-size: 14px;
}

.number-of-cars::after, .number-of-cars::before {
    position: absolute;
    width: 5%;
    height: 85%;
    content: '';
}

.number-of-cars::before {
    top: 0;
    left: 0;
    border-top: solid 2px #ffffff;
    border-bottom: solid 2px #ffffff;
    border-left: solid 2px #ffffff;
}

.number-of-cars::after {
    top: 0;
    right: 0;
    border-top: solid 2px #ffffff;
    border-right: solid 2px #ffffff;
    border-bottom: solid 2px #ffffff;
}

/* セクター ここまで*/

/* .header-inner-div-single {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
} */


/* ots */

.ots-td{
    cursor: default;
}

.header-inner-div-row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
}

.ots-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.ots-left-header {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90px;
    padding: 0 8px;
}

.ots-limit-bar-container {
    position: relative;
    width: 100%;
    min-width: 90px;
    height: 70%;
    border: 1px solid #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 0 4px rgba(74, 255, 255, .6),
    0 0 4px rgba(74, 255, 255, .6) inset;
    background-color: rgba(211, 242, 255, .4);
}

.ots-limit-bar-container.ots-max-used {
    border: none;
    box-shadow: none;
    background: transparent;
}

.ots-limit-text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
    color: #000000;
    font-size: .75vw;
}

.ots-limit-bar-container.ots-max-used .ots-limit-text {
    color: #ffffff;
    /*text-shadow: 0 0 4px rgba(74, 255, 255, .6), 0 0 4px rgba(74, 255, 255, .6);*/
}

.ots-limit-bar {
    position: absolute;
    width: 0;
    height: 100%;
    /* border-radius: 5px; */
    border-radius: 4px;
}

.ots-limit-bar-container.ots-unavailable {
    z-index: 3;
    background-size: 50px 50px;
}

.ots-limit-bar-container.ots-unavailable .ots-limit-border {
    position: absolute;
    z-index: 3;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-image: repeating-linear-gradient(55deg, #ffffff, #ffffff 2px, transparent 0, transparent 7px);
    background-color: transparent;
}

.ots-unavailable .ots-limit-text {
    color: #ffffff;
    text-shadow: 2px 2px 1px #000000, -2px -2px 1px #000000,
    -2px 2px 1px #000000, 2px -2px 1px #000000,
    2px 0 1px #000000, -2px -0 1px #000000,
    0 2px 1px #000000, 0 -2px 1px #000000;
}

.ots-unavailable > .ots-limit-bar {
    z-index: 0;
    background-color: #000000 !important;   /* スクレイピングテストをして修正 */
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 1px rgba(74, 255, 255, .4), 0 0 2px rgba(74, 255, 255, .4), 0 0 2px rgba(74, 255, 255, .4), 0 0 6px rgba(74, 255, 255, .4);
    }

    50% {
        box-shadow: 0 0 2px rgba(74, 255, 255, .9), 0 0 3px rgba(74, 255, 255, .9), 0 0 3px rgba(74, 255, 255, .9), 0 0 10px rgba(74, 255, 255, .9);
    }

    100% {
        box-shadow: 0 0 1px rgba(74, 255, 255, .4), 0 0 2px rgba(74, 255, 255, .4), 0 0 2px rgba(74, 255, 255, .4), 0 0 6px rgba(74, 255, 255, .4);
    }
}

.ots-limit-bar-container.ots-using {
    position: relative;
    border: 2px solid rgb(74, 255, 255);
    animation: glowing .5s infinite alternate;
}

/* otsここまで */

/* OTS・セクターの文字色 */
.header-inner-div-small-font {
    font-weight: bold;
    font-size: 1rem;
}

.base-lapTime-font, .base-secter-font {
    color: #4affff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    /*text-shadow: 0 0 2.5px #4affff;*/
}

/* OTS・セクターの文字ここまで */

/* ベストタイム */

.best-lap-count, .best-lap-display {
    display: block;
    position: relative;
    margin-left: 16px;
    padding: 4px 8px;
    box-sizing: border-box;
    background-color: #222f3f;
    background-color: #485c75;
}

tr .best-time-tr {
    display: flex;
    align-items: center;
    height: 50%;
    margin: 8px;
}

.bestLap-items span {
    display: inline-block;
    max-width: 84px;
    font-size: min(1.8vw,20px);
    word-break: break-all;
}

.best-lap-display {
    display: inline-block;
    width: 40%;
    max-width: 29px;
    margin-left: 16px;
    padding: 4px;
    box-sizing: content-box;
    font-size: 1.1rem;
}


.best-lap-count::after {
    position: absolute;
    bottom: -5%;
    left: 25%;
    width: 50%;
    height: 7%;
    background-color: #ffffff;
    content: '';
}

/*.best-lap-display::after {
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 50%;
    height: 7%;
    background-color: #ffffff;
    content: '';
}*/
/* ベストタイム */


/* .rece-detail-area {
    display: flex;
    flex-direction: column;
    align-items: center;
} */

/* ピット */
.pit-cells {
    background-image: url(../image/pit-count.png);
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
}

.pit-cells:hover {
    background-image: url(../image/pit-count-hover.png);
}

/* class */
.class-items{
    min-width: 80px;
}

/* バトルグループ　・　Def ・ Avg*/
.image-and-text {
    position: relative;
    min-width: 70px;
}

.image-and-text-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 80px;
    height: 100%;
    padding: 0;
}

.image-and-text-block p {
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
}

/* Avg */
.image-and-text-average {
    position: relative;
    min-width: 110px;
}

.average-value {
    /* margin: 0 0 0 23px; */
    margin-left: 23px;
    color: #000000;
}

.tableCell-icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.value-up-down {
    filter: drop-shadow(0px 0px 5px rgba(255,255,255,1));
}

.live-table .tableCell-icon.battle {
    width: 2.0vw;
    max-width: 40px;
    height: 2.0vw;
    max-height: 40px;
    margin-left: 40px;
}

.tableCell-icon.battle-position {
    width: auto;
    max-width: 100%;
    height: 100%;
    margin-left: 0;
}



.deg-value {
    color: #000000;
}

.tableCell-icon.center {
    /* intenseBattleの時 */
    margin-left: 0;
}

.tableCell-icon.center.small {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 20px;
    height: 20px;
}

.tableCell-icon.warning {
    width: 30px;
    height: 30px;
    margin-left: 6px;
}

/* Groupbattlendexの偶数と奇数 */
.isInEvenBattle .image-and-text-block.battle::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #82ff80;
    content: '';
}

.isInOddBattle .image-and-text-block.battle::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #d9d9d9;
    content: '';
}

.isUsingOts {
    background-color: rgba(255, 231, 16, .46);
}

.isUpdated > td {
    color: #000000;
}

.css-17kwxg4-MuiTableRow-root:last-child.isUpdated > .jqAsmt{
    border: 6px solid #242424;
}

/* 背景色あるテーブルセル　更新時は白色 */
.isUpdated > .driverName-cell-items, .isUpdated > td .name-and-number .table-number, .isUpdated > .bestLap-items .best-lap-display, .isUpdated > .pit-cells, .isUpdated > td .name-and-number .table-name {
    color: #ffffff ;
}


.live-table .isUpdated {
    background-color: yellow;
    transition: background-color .3s ease;
}


/* Switch */
.switch {
    display: inline-block;
    position: relative;
    /* width: 55px;
    height: 20px; */
    width: 48px;
    height: 18px;
    margin: 5px 0;
}

.switch-area {
    display: flex;
    align-items: center;
    margin-right: 40px;
}

.switch-area-text {
    margin-right: 5px;
}

.switch.square {
    width: 40px;
    height: 20px;
    margin-right: 5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #cccccc;
    cursor: pointer;
            transition: .4s;

    -webkit-transition: .4s;
}

.switch .slider:before {
    position: absolute;
    bottom: 1px;
    left: 1px;
    width: 16px;
    /* height: 16px;
    width: 16px;
    left: 8px;
    bottom: 2px; */
    height: 16px;
    background-color: white;
    content: '';
            transition: .4s;

    -webkit-transition: .4s;
}

.slider.square:before {
    position: absolute;
    bottom: 1px;
    left: 1px;
    width: 19px;
    height: 18px;
    background-color: white;
            transition: .4s;

    -webkit-transition: .4s;
}

input:checked + .slider {
    background-color: #56717D;
    opacity: 0.5;
}

input:focus + .slider {
    box-shadow: 0 0 1px #56717D;
}

input:checked + .slider.square {
    background-color: #56717D;
    opacity: 0.5;
}

input:focus + .slider.square {
    box-shadow: 0 0 1px #56717D;
}

input:checked + .slider:before {
    transform: translateX(30px);
    /* -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px); */

    -webkit-transform: translateX(30px);
        -ms-transform: translateX(30px);
}

input:checked + .slider.square:before {
    transform: translateX(20px);
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
}

.slider.round {
    border-radius: 32px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider.square {
    border-radius: 4px;
}

.slider.square:before {
    border-radius: 10%;
}

.dark img{
    height: 90%;
    width: 90%;
    filter: invert(10%) sepia(17%) saturate(5669%) hue-rotate(152deg) brightness(111%) contrast(103%);
}

.light-mode .dark img{
    filter: invert(16%) sepia(11%) saturate(1935%) hue-rotate(176deg) brightness(10%) contrast(86%);
    opacity: 0.3;
}

.light img{
    height: 90%;
    width: 90%;
    opacity: 0.4;
}

.light-mode .light img{
    filter: invert(10%) sepia(17%) saturate(5669%) hue-rotate(152deg) brightness(111%) contrast(103%)
    drop-shadow(0px 0px 2px rgba(0, 0, 0, .2));
    opacity: 1;
}

.rival-header-table .cell-items{
    height: auto;
}



/* ========== ここまでLive ========== */

.rival-table-header{
    border-bottom: 1px dashed #fff;
    background-color: #1E1E1E;
    top: -2px;
}

.follow-header-mark,
.reval-header-mark{
    vertical-align: middle;
}


.rivals-button {
    width: 50px;
    height: 20px;
    margin-left: 10px;
    padding: 0;
    border: 1px solid #fff;
    border-radius: 5px;
    text-align: center;
    color: #4affff;
    font-size: 12px;
}

.rivals-button:hover{
    opacity: .7;
    cursor: pointer;
}


.result_container .mobile-header:first-of-type {
    display: flex;
    position: sticky;
    top: 0;
    width: 70vw;
    height: 4vh;
    margin-top: 8px;
    padding: 4px 48px;
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 0 7px #ffffff;
    background-color: rgba(175,231,255,.4);
    font-weight: bold;
    font-size: 24px;
}

.result_container .mobile-header .select-text {
    position: relative;
}

.result_container .mobile-header .select-box {
    display: flex;
    align-items: center;
    position: relative;
    gap: 32px;
}

.result_container .mobile-header .select-dec {
    display: block;
    position: relative;
    width: 24px;
    height: 2px;
    background-color: #4affff;
}

.result_container .mobile-header .select-dec::before {
    position: absolute;
    top: -2px;
    left: -24px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #4affff;
    content: '';
}

.result_container .mobile-header .select-dec::after {
    position: absolute;
    top: 0;
    left: -22px;
    z-index: 1;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #5c727c;
    content: '';
}

.result_container .mobile-header .select-text {
    position: relative;
    font-size: 20px;
}

.result_container .mobile-header div {
    width: 50vw;
}

.mobile-header th {
    display: inline-block;
    width: 43vw;
    height: 3vh;
    padding: 0;
    box-sizing: border-box;
}

.result_container > div:nth-of-type(2) {
    margin-top: 0;
}

.mobile-header .lap-history-select {
    position: relative;
    width: 10vw;
    padding: 0;
    border: none;
    font-weight: bold;
    font-size: 16px;
    text-align: end;
    appearance: none;
}

.mobile-header .lap-history-select:focus{
    outline: none;
}

.mobile-header .select-contents {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: end;
}

.mobile-header .select-contents::before {
    position: absolute;
    top: 50%;
    right: -24px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 8px 8px 0 8px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';

    pointer-events: none;
}

.mobile-header .lap-history-select:focus {
    border: none;
    box-shadow: none;
}

/* PC時のタブレットモード */

.switchStyle.container_dashboard{
    width: 100vw;
}

.switchStyle.container_dashboard section:first-of-type{
    width: 65%;
}
.switchStyle.container_dashboard section:nth-of-type(2){
    width: 42%;
}

.result_container .switchStyle.mobile-header{
    width: 100%;
}

.result_container > .switchStyle:nth-of-type(2){
    width: 100%;
}

.switchStyle .name-and-number{
    margin:0 auto;
}




/* B5サイズ　 */
@media only screen and (max-width: 1366px) and (orientation: landscape) {
    /* Analysisで使用 */
    .analysis-sort-header {
        display: flex;
        flex-direction: row;
        position: relative;
        gap: 10px;
    }

    .class-select-element {
        position: absolute;
        z-index: 1;
    }

    /* Liveテーブルの高さの調整*/
    .container_dashboard {
        display: flex;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 5px;
        height: 100vh;
        max-height: calc(100vh - 145px);
    }
    
    .container_dashboard::-webkit-scrollbar {
        display: none;
    }

    /* Livetable ヘッダー　修正*/
    .header-inner-div select {
        border: solid 1px #767676;
        background: #ffffff;
        color: #000000;
    }

    /* スイッチ崩れ修正*/
    .switch.square {
        min-width: 40px;
    }

    /* Liveテーブル行の高さの調整*/
    .live-table .MuiTableRow-root {
        height: 3vh;
    }

    /* 選手名の列幅の調整*/
    .table-name {
        width: 5.5vw;
    }

    /* テーブルの文字サイズ変更*/
    .live-table .cell-items, 
    .live-table .bSpcaY {
        font-size: min(1.8vw,20px);
    }

    .ots-limit-text {
        font-size: min(1.5vw,16px);
    }

    /* テーブルの文字サイズ変更ここまで*/

    /* バトルの行調整*/
    .tableCell-icon.battle-position {
        width: auto;
        max-width: 100%;
        height: 100%;
        margin-left: 0;
    }

    .image-and-text-block {
        width: 8vw;
    }

    .tableCell-icon .battle {
        width: 4vw;
        height: 4vw;
        margin-left: 3.5vw;
    }

    /*バトルの行調整　ここまで*/

    /* 各表示切り替え　サイズ調整 */

    /* ダッシュボード */

    /* マップ */
    .result_container > .one-screen-map {
        width: 100vw;
        height: 80vh;
        margin-top: 5%;
        overflow-y: hidden;
    }

    .pit-cells {
        width: 100%;
    }

    .name-and-number {
        padding: 5% 10%;
    }

    .number-of-cars {
        font-size: 1vw;
    }


    /* セクターヘッダーの文字サイズ */

    .secter-items {
        font-size: min(1.5vw,16px);
    }

    footer .container {
        margin-top: 20px;
    }
    
    .rival-header-table .cell-items{
        font-size: 14px;
    }
}



/* B5サイズ　 */
@media only screen and (max-width: 1366px) and (orientation: portrait) {
    /* Liveテーブルの高さの調整*/
    .container_dashboard {
        height: calc(100vh - 198px);
        min-height: auto;
    }

    /* Livetable ヘッダー　修正*/
    .header-inner-div select {
        width: 8vw;
        border: solid 1px #767676;
        background: #ffffff;
        color: #000000;
    }

    /* スイッチ崩れ修正*/
    .switch.square {
        min-width: 40px;
    }

    /* Livetable全体 */
    .live-table .live-table-row {
        height: 5vh;
        overflow-x: hidden;
        box-sizing: border-box;
    }

    /*フォローの列　幅調整 */
    .follow-parent {
        width: 10vw;
        table-layout: fixed;
    }

    /* 選手名の列幅の調整*/
    .table-name {
        width: 7.5vw;
    }

    /* 天気情報の文字サイズ調整（縦画面のみ）*/
    .sidemenu-weather-container {
        font-size: 11px;
    }

    /* テーブルの文字サイズ変更*/
    .cell-items {
        font-size: 2.5vw;
        height: 32px;
    }

    .ots-limit-text {
        font-size: 1.5vw;
    }

    /* テーブルの文字サイズ変更ここまで*/

    /* バトルの行調整*/
    .tableCell-icon.center {
        margin-left: 0;
    }

    .image-and-text-block {
        width: 15vw;
    }

    .tableCell-icon.battle {
        width: 6vw;
        height: 6vw;
        margin-left: 7vw ;
    }

    /*バトルの行調整　ここまで*/

    /* ダッシュボード */
    .result_container > div {
        max-height: none;
    }

    /* チャート */
    .result_container > .timeComparison-chart-container {
        width: 100vw;
        height: 80vh;
    }

    /* チャート　下のスケールberのCSS */
    .stack-slider-flex {
        margin-right: 8%;
    }

    /* マップ */
    .result_container > .one-screen-map {
        width: 105vw;
        height: 80vh;
    }

    .rival-header-table .cell-items{
        font-size: 14px;
    }
}

/*ipad 横向きの場合*/
@media only screen and (max-width: 1366px) and (orientation: landscape)  {
    .result_container .mobile-header:first-of-type {
        display: flex;
        position: sticky;
        top: 0;
        width: 70vw;
        height: 4vh;
        margin-top: 8px;
        padding: 4px 48px;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175,231,255,.4);
        font-weight: bold;
        font-size: 24px;
    }

    .result_container .mobile-header .select-text {
        position: relative;
    }

    .result_container .mobile-header .select-box {
        display: flex;
        align-items: center;
        position: relative;
        gap: 32px;
    }

    .result_container .mobile-header .select-dec {
        display: block;
        position: relative;
        width: 24px;
        height: 2px;
        background-color: #4affff;
    }

    .result_container .mobile-header .select-dec::before {
        position: absolute;
        top: -2px;
        left: -24px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #4affff;
        content: '';
    }

    .result_container .mobile-header .select-dec::after {
        position: absolute;
        top: 0;
        left: -22px;
        z-index: 1;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #5c727c;
        content: '';
    }

    .result_container .mobile-header .select-text {
        position: relative;
        font-size: 2.5vw;
    }

    .result_container .mobile-header div {
        width: 50vw;
    }

    .mobile-header th {
        display: inline-block;
        width: 43vw;
        height: 3vh;
        padding: 0;
        box-sizing: border-box;
    }

    .result_container > div:nth-of-type(2) {
        margin-top: 0;
        width: 70vw;
    }

    .mobile-header .lap-history-select {
        position: relative;
        width: 45vw;
        padding: 0;
        border: none;
        font-weight: bold;
        font-size: 2.5vw;
        text-align: end;
        appearance: none;
    }

    .mobile-header .lap-history-select:focus{
        outline: none;
    }

    .mobile-header .select-contents {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: end;
    }

    .mobile-header .select-contents::before {
        position: absolute;
        top: 50%;
        right: -24px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-width: 8px 8px 0 8px;
        border-style: solid;
        border-color: #4affff transparent transparent transparent;
        content: '';

        pointer-events: none;
    }

    .mobile-header .lap-history-select:focus {
        border: none;
        box-shadow: none;
    }

    .name-and-number{
        width: max(8vw, 160px);
    }

    .class-items{
        min-width: auto;
    }
    .class-display-table{
        width: 8px;
        height: 72%;
        font-size: .1px;
    }

    .rival-header-table .cell-items{
        font-size: 14px;
    }
}


/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    /* ダッシュボード　全体 */
    .container_dashboard {
        display: block;
        
    }

    .result_container.live {
        height: calc(100vh - 197px);
        box-sizing: border-box;
    }

    .live-table .cell-items {
        font-size: 2.5vw;
        padding: 0 8px !important;
    }

    .sidemenu-weather-card{
        height: 80px;
    }

    .toggle-input {
        display: none;
      }
      
      /* Toggle label styles */
      .toggle-label {
        display: inline-block;
        cursor: pointer;
        padding: 10px;
        background-color: #dddddd;
        color: #000000;
        border-radius: 20px;
        width: 20%;
        font-size: 14px;
        margin-top: 40%;
        margin-left: 10%;
      }
      
      /* When the toggle is checked, change to dark mode */
      .toggle-input:checked + .toggle-label {
        background-color: #333333;
        color: #ffffff;
      }

      input:checked + .slider:before {
        transform: translateX(24px);
        /* -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px); */
    
        -webkit-transform: translateX(24px);
            -ms-transform: translateX(24px);
    }
    

    .sidemenu-weather-card{
        height: 80px;
    }

    /* 順位とドライバー名 */

.position-cell{
    min-width: 20px;
}

    /* ダッシュボード横のマップ非表示 */
    .container_dashboard .result_container:nth-of-type(2) {
        display: none;
    }
    /* Class表示 */
    .class-display-table{
        width: 8px;
        height: 72%;
        font-size: .1px;
    }

    .class-items{
        min-width: auto;
        padding: 0;
    }


    .result_container .rival-tavle-container .rival-header-table {
        width: 100vw;
    }

    .result_container .rival-tavle-container .rival-header-table tr {
        max-height: 3vh;
    }

    /* ダッシュボードヘッダー */
    .result_container .mobile-header:first-of-type {
        display: flex;
        position: sticky;
        top: 0;
        width: 100vw;
        height: 4vh;
        margin-top: 8px;
        padding: 4px 48px;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175,231,255,.4);
        font-weight: bold;
        font-size: 24px;
    }


    .result_container .mobile-header .select-text {
        position: relative;
    }

    .result_container .mobile-header .select-box {
        display: flex;
        align-items: center;
        position: relative;
        gap: 32px;
    }

    .result_container .mobile-header .select-dec {
        display: block;
        position: relative;
        width: 24px;
        height: 2px;
        background-color: #4affff;
    }

    .result_container .mobile-header .select-dec::before {
        position: absolute;
        top: -2px;
        left: -24px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #4affff;
        content: '';
    }

    .result_container .mobile-header .select-dec::after {
        position: absolute;
        top: 0;
        left: -22px;
        z-index: 1;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #5c727c;
        content: '';
    }

    .result_container .mobile-header .select-text {
        position: relative;
        font-size: 2.5vw;
    }

    .result_container .mobile-header div {
        width: 50vw;
    }

    .mobile-header th {
        display: inline-block;
        width: 43vw;
        height: 3vh;
        padding: 0;
        box-sizing: border-box;
    }

    .result_container > div:nth-of-type(2) {
        margin-top: 0;
    }

    .mobile-header .lap-history-select {
        position: relative;
        width: 45vw;
        padding: 0;
        border: none;
        font-weight: bold;
        font-size: 2.5vw;
        text-align: end;
        appearance: none;
    }

    .mobile-header .lap-history-select:focus{
        outline: none;
    }

    .mobile-header .select-contents {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: end;
    }

    .mobile-header .select-contents::before {
        position: absolute;
        top: 50%;
        right: -24px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-width: 8px 8px 0 8px;
        border-style: solid;
        border-color: #4affff transparent transparent transparent;
        content: '';

        pointer-events: none;
    }

    .mobile-header .lap-history-select:focus {
        border: none;
        box-shadow: none;
    }


    .container_dashboard {
        width: 100vw;
        height: calc(100vh - 197px);
    }

    /* ヘッダーの文字大きさ修正 */
    /* ヘッダー全体 */
    .live-table thead tr th {
        font-size: min(2.0vw, 20px);
    }

    .live-table .header-inner-div-small-font {
        font-size: min(2.0vw, 20px);
    }

    /* Lapタイム */
    .base-lapTime-font, .base-secter-font {
        font-size: min(2.0vw, 20px);
    }

    .live-table .follow-parent {
        width: 5vw;
    }

    /* セクターセクション選択時 */
    .live-table .tableCell-icon.battle {
        width: 6vw;
        max-width: 64px;
        height: 6vw;
        max-height: 64px;
    }

    /* Pit INS選択時 */

    /* BestTIME LAPの文字調整 */
    .live-table .bestLap-items span {
        font-size: min(2.0vw, 20px);
    }
    /* ピットインアイコンのサイズ */
    .live-table .pit-cells {
        width: auto;
        background-size: 80% auto;
    }

    .live-table .table-name {
        width: 5.5vw;
    }

    .name-and-number{
        width: 16vw;
    }


    /* OTS */
    .ots-limit-bar-container, .ots-left-header, .ots-td {
        width: 10vw;
    }



    /* マップ */
    .result_container > .one-screen-map {
        position: relative;
        width: 90vw;
        height: 80vh;
        margin-top: 2vh;
        margin-bottom: 2vh;
        padding-top: 10%;
    }
    .one-screen-map section:nth-of-type(2){
        width: 66%;
    }
    .one-screen-map section:nth-of-type(2) svg{
        width: 100%;
        height: 23vh;
    }
    .one-screen-map .pit-info-container {
        height: 23vh;
    }

    .one-screen-map .predict-container{
        top: 2.5vh;
        left: 5vw;
    }

    .one-screen-map .map_option_container{
        top: 2.5vh;
        left: 21vw;
    }

    .one-screen-map .map_option_container div{
        align-items: center;
        display: flex;
    }

    .one-screen-map svg{
        height: 23vh;
    }

    .result_container_map {
        height: calc(100vh - 197px);
    }

    .legend-hidden {
        text-decoration: line-through;
    }

    .reval-header-mark{
        max-height: 12px;
        max-width: 30px;
    }

    .map_option_carNumber{
        line-height: 26px;
    }

    .rival-header-table .cell-items{
        font-size: 12px;
    }
}


@media only screen and (max-width: 743px) and (orientation: portrait) {

    /* 全体のフォントサイズ */
    .live-table .cell-items{
        font-size: min(3vw, 14px);
    }
    .live-table thead tr th{
        font-size: min(3vw, 14px);
    }

    .result_container > div{
        width: 100vw;
    }

    .live-table .live-table-header tr th{
        padding: 0 8px;
    }

    .header-div{
        height: 40px;
    }

    /* フォロー */
    /* ライバル*/
    .follow-img{
        width: 16px;
        margin-left: 2px;
    }

    .rival-img{
        width: 16px;
        height: 10px;
    }
    /* ポジションアイコン */
    .tableCell-icon{
        display: none;
        width: 0px;
        height: 0px;
    }

    .table-position{
        left: 0;
    }

    .result_container .mobile-header {
        margin-top: 0;
    }

    .container_dashboard{
        height: 73svh;
        margin: 0;
        padding: 0;
    }
    .result_container.live{
        height: 100%;
    }

    .position-cell,
    .race-name{
        min-width: auto;
    }

    /* 選手名 */
    .name-and-number{
        padding: 0;
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
        background: transparent;
    }

    .live-table .table-name{
        width: 9vw;
    }

    .image-and-position{
        justify-content: center;
        gap: 0;  
    }

    .name-and-number{
        justify-content: center;
        gap: 0;
    }

        /* Lapタイム */
        .live-table .header-inner-div-small-font {
            font-size: min(3vw, 12px);
            font-weight: bold;
        }

        .header-sector-cells .secter-items,
        .header-sector-cells .number-of-cars{
            font-size: min(3vw, 12px);
        }

        .live-table .bestLap-items span{
            font-size: min(3vw, 14px);
        }

        .bestLap-items span{
            max-width: none;
        }

        .image-and-text-block{
            width: 100%;
        }
        .header-inner-div select{
            width: 40px;
        }

        .class-display-table{
            width: 8px;
            height: 72%;
            font-size: .1px;
        }

        .class-items{
            min-width: auto;
            padding: 0;
        }

        .top-view-mode-container .switch .slider{
            right: 21px;
            left: -7px;
        }
    

        input:checked + .slider:before {
            transform: translateX(45px);
            -webkit-transform: translateX(30px); 
            ms-transform: translateX(45px);
        }

        .one-screen-map,
        .map-container{
            background: none;
        }
    
        .one-screen-map svg{
            position: relative;
            top: 0;
            left: 0;
            width: 85%;
            height: 40%;
            transform: translateX(0%);
        }
    
        .one-screen-map div{
            display: flex;
            flex-direction: column;
            width: 100vw;
            height: 100%;
        }

        .map-container .pit-info-container{
            position: relative;
            width: 85%;
            transform: translateX(0%);
            top: 0;
            left: 0;
            margin-top: 32px;
        }

        .toggle-input {
            display: none;
          }
          
          /* Toggle label styles */
          .toggle-label {
            height: 3%;
            width: 20%;
            font-size: 12px;
            display: inline-block;
            cursor: pointer;
            margin-top: 24%;
            margin-left: 10%;
            background-color: #dddddd;
            color: #000000;
            border-radius: 20px;
          }
          
          /* When the toggle is checked, change to dark mode */
          .toggle-input:checked + .toggle-label {
            background-color: #333333;
            color: #ffffff;
          }

          .result_container .rival-tavle-container{
            height: 64%;
            overflow: scroll;
          }

}

/* 横画面のスマホ版 */
@media only screen and (max-width: 932px) and (orientation: landscape) {
.container_dashboard .timeComparison-flex-container img{
    display: none;
}

.result_container > .timeComparison-chart-container{
    margin-top: 16px;
    width: 80vw;
    height: calc(100vh - 190px);
}

.sidemenu-weather-card img{
    width: 40px;
}
    
}








@media only screen and (max-width:1700px) {
    .header-menu-container {
        justify-content: flex-end;
        padding: 0 1.5vw;
    }

    .side-menu-left {
        flex-direction: column;
    }

    .side-menu-button {
        min-width: auto;
    }

    .header-menu-container .side-menu-button button:last-child {
        margin-right: 0;
    }
}

.toggle-input {
    display: none;
  }
  
  /* Toggle label styles */
  .toggle-label {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    background-color: #dddddd;
    color: #000000;
    border-radius: 20px;
  }
  
  /* When the toggle is checked, change to dark mode */
  .toggle-input:checked + .toggle-label {
    background-color: #333333;
    color: #ffffff;
  }



  

.settingcontainer .menu-btn {
    position: fixed;
    top: 16px;
    right: 10px;
    display: flex;
    height: 48px;
    width: 48px;
    justify-content: center;
    align-items: center;
    z-index: 999;
    border-radius: 50%;
    background-color: #4AFFFF;
    text-shadow: 0 0 4px rgba(255, 255, 255, .7);
    cursor: pointer;
}

.settingcontainer .menu-btn span,
.settingcontainer .menu-btn span:before,
.settingcontainer .menu-btn span:after {
    content: '';
    display: block;
    height: 4px;
    width: 24px;
    border-radius: 3px;
    background-color: #545454;
    position: absolute;
}

.settingcontainer .menu-btn span:before {
    bottom: 8px;
}

.settingcontainer .menu-btn span:after {
    top: 8px;
}

.settingcontainer #all-menu-btn-check:checked ~ .menu-btn span {
    background-color: rgba(255, 255, 255, 0);/*メニューオープン時は真ん中の線を透明にする*/
}

.settingcontainer #all-menu-btn-check:checked ~ .menu-btn span::before {
    bottom: 0;
    transform: rotate(45deg);
}
.settingcontainer #all-menu-btn-check:checked ~ .menu-btn span::after {
    top: 0;
    transform: rotate(-45deg);
}

.settingcontainer #all-menu-btn-check {
    display: none;
}

.menu-content {
    width: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -130%;
    z-index: 998;
    background-color: #000;
    transition: all 0.5s;
}

#all-menu-btn-check:checked ~.menu-content {
    right: 0;
}

.settingcontainer .menu-wrap{
    width: 100%;
    height: 100%;
    padding: 0 32px 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: scroll;
}

.settingcontainer .menu-boxs{
    margin: 6vh auto 0;
    padding: 4vh;
}

.settingcontainer .lang-switch-box h3 {
    position: relative;
    margin: 32px 0 48px;
    /* font-size: 24px; */
}

.settingcontainer .lang-switch-box{
    margin-bottom: 80px;
    border-top: 2px solid #fff;
}

/*.settingcontainer .lang-switch-box button::before{
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 41px;
    background-color: #fff;
    bottom: -8px;
    right: 4px;
    transform: rotate(36deg);
}*/

.settingcontainer .switch-area-base {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 20px;
    left: 0;
}

.settingcontainer .switch-area-base li {
    width: 8px;
    height: 8px;
    margin-right: 3px;
    display: inline-block;
    background: #fff;
    transform: skewX(320deg);
}

.settingcontainer .button-box{
    display: flex;
    justify-content: center;
    text-align: center;
}

.settingcontainer .menu-boxs button{
    height: 64px;
    margin: auto 0;
    margin-bottom: 8px;
    font-weight: bold;
    color: #fff;
    line-height: 0;
    font-size: 16px; 
    text-align: center;
    border: none;
    background-image: url(./strategy/image/common-button-setting.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    /*clip-path: polygon(100% 0, 100% 60%, 90% 100%, 0 100%, 0 0);
    background-color: rgba(86, 113, 125, .3);
    border: 2px solid #fff;*/
}

.settingcontainer .menu-boxs button:hover{
    color: #4AFFFF;
    background-image: url(./strategy/image/common-button-setting_hover.svg);
    filter: drop-shadow(0px 0px 4px #4AFFFF);
    /*box-shadow: #4AFFFF  0px 0px 8px;*/
}

.settingcontainer .button-box button{
    font-weight: bold;
    color: #fff;
    border: none;
    background-image: url(./strategy/image/common-button-language.svg);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    height: 68px;
    padding: 0;
    /*clip-path: polygon(100% 0, 100% 60%, 90% 100%, 0 100%, 0 0);
    background-color: rgba(86, 113, 125, .3);
    border: 2px solid #fff;*/
}

/*.settingcontainer button::before{
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 41px;
    background-color: #fff;
    bottom: -6px;
    right: 4px;
    transform: rotate(43deg);
}*/

.settingcontainer .button-box .action{
    color: #4AFFFF;
    background-image: url(./strategy/image/common-button-language_select.svg);
    filter: drop-shadow(0px 0px 4px #4AFFFF);
}
.settingcontainer .button-box .no-action{
    opacity: .5;
}

.settingcontainer .button-box .action::before{
    background-color: #4AFFFF;
}

.settingcontainer button:first-of-type{
    margin-right: 16px;
}

@media only screen and (max-width: 743px) and (orientation: portrait) {
    .settingcontainer .menu-btn{
        width: 40px;
        height: 40px;
        top: 1.7%;
        right: 24px;
    }
}


header, .strategy-header {
    min-height: 100px;
    padding-top: 0;
    padding-bottom: 0;
}

/* モバイル版ヘッダーの非表示 */
.sp-bottom-header,
.sp-bottom-Mapheader {
    display: none;
}

/* イベントページ・FP・QF・Race共通ヘッダー */
.header-custom {
    position: relative;
    min-height: 41px;
    margin: 46px 0 80px;
}

.header-custom::before {
    display: block;
    position: absolute;
    top: 0;
    left: 5vw;
    width: 90vw;
    height: 48px;
    margin: 0 auto;
    background-image: url(../image/header-tit-bk.png);
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
}

.header-custom .logo-container {
    position: relative;
    margin: 0 auto;
    cursor: pointer;
}

/* イベントページ・FP・QF・Race共通ヘッダーここまで */


/* ダッシュボード */
.header-right-area .hamburger-menu {
    display: none;
}

.live-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 997;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 24px 24px 0;
    box-sizing: border-box;
    font-family: 'Titillium Web', sans-serif;
}

.header-right-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 16px;
}

.logo-container {
    width: 278px;
    min-height: 47px;
    background-image: url(../../../public/Logo_GR.png);
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    /* height: 100%;
    width: 15%;
    position: absolute;
    top: 0;
    left: 0; */
}

.header-left-items {
    display: flex;
    align-items: center;
    gap: 24px;
}

.header-left-items:first-child {
    margin-bottom: 24px;
}

.side-menu-time{
    position: relative;
}

.side-menu-time::before, .side-menu-time::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    box-shadow: 0 0 3px #ffffff;
    background-color: #ffffff;
    content: '';
}

.side-menu-time::after {
    bottom: -6px;
}

.time-label {
    font-weight: 500;
    font-size: 18px;
}

.time-value {
    margin-left: 5px;
    font-weight: 600;
    font-size: 1.5rem;
}

.header-back-button {
    /* TODO: fix css */
    position: absolute;
    top: 30px;
    right: 250px;
    z-index: 2;
    cursor: pointer;
}

/* .header-menu-container {
    position: absolute;
    top: 0; 
    left: 16%;
    width: 84%; 
    left: 300px;
    width: calc(100% - 300px);
    width: calc(100% - 540px);
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 15px;
} */

.menu-checkbox {
    display: flex;
    /* min-width: 260px; */
    min-width: 180px;
}

.menu-checkbox-left {
    display: flex;
    margin-right: 32px;

    gap: 24px;
}

.menu-checkbox-right {
    display: block;
    margin-left: 10%;
}

.checkbox-div {
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
}

.checkbox-div.disabled-true {
    opacity: .5;

    pointer-events: none;
}

.menu-buttons {
    display: flex;
    /* ボタンを最低2列確保 */
    /* min-width: 235px; */
    min-width: 340px;
    height: 100%;
    margin-left: 2%;
}

.display-switch-btn {
    display: flex;
    flex-direction: column;
}

.table-function {
    display: flex;
    position: relative;
}

.table-function::before {
    display: inline;
    position: absolute;
    bottom: 2.5%;
    left: -8px;
    width: 2px;
    height: 90%;
    box-shadow: 0 0 7px #ffffff;
    background-color: #ffffff;
    content: '';;
}
.table-function::after {
    display: inline;
    position: absolute;
    bottom: 2.5%;
    left: -16px;
    width: 2px;
    height: 90%;
    background-color: #ffffff;
    content: '';
}

.header-btn-area {
    display: flex;
}

.menu-button-items {
    display: flex;
    flex-direction: row;

    gap: 2px;
}

.menu-buttons .square-btn {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 114px;
    height: 90px;
    padding: 8px;
    border: solid 2px rgba(255, 255, 255, .8);
    box-shadow: 0 0 4px 1px rgba(255, 255, 255, .5);
    background-color: rgba(175, 231, 255, .2);
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
}

.menu-buttons .square-btn span{
    padding-top: 8px;
}

.square-icon {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(58deg) brightness(110%) contrast(101%);
}

.menu-buttons .square-btn:hover {
    border: solid 2px rgba(74, 255, 255, .8);
    box-shadow: 0 0 4px 1px rgba(74, 255, 255, .5); 
    color: #4affff;
    cursor: pointer;
}


.live-header .menu-buttons .square-btn img {
    width: 40%;
}

.square-btn-view-group {
    display: flex;
    flex-direction: row;
}

.setting-btn, .download-btn {
    display: block;
    width: 40px;
    height: 40px;
    background-color: transparent;
}

.menu-display {
    display: flex;
    align-items: center;
    margin-left: 0;
}

.display-div {
    display: flex;
    flex-direction: column;
    position: relative;
    /* ↓天気カードのボトムを揃えるため↓ */
    margin-bottom: 6px;
}

.display-title {
    font-weight: bold;
    font-size: 15px;
    white-space: nowrap;
}

.display-value {
    display: inline-block;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
}

.display-value.double-border::before, .display-value.double-border::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    box-shadow: 0 0 7px #ffffff;
    background-color: #ffffff;
    content: '';
}


.display-value.double-border::after {
    bottom: -6px;
}

.display-value.flag {
    display: flex;
    align-items: center;
    height: 50px;
}

.flag-color {
    /* height: 40%;
    width: 20%;
    min-width: 20%; */
    min-width: 38px;
    height: 24px;
    margin-right: 10px;
    border-radius: 3px;
    background: linear-gradient(to top, #898989 50%, #565656 95%);
}

.flag-text {
    font-weight: normal;
    /* 折り返すと崩れるので */
    /* white-space: nowrap; */
    font-size: 14px;
    text-align: left;
}

.sidemenu-weather-card.loading {
    width: 15%;
    height: 100%;
    font-size: 24px;
}

.flag-color.null {
    background: linear-gradient(to top, #898989 50%, #565656 95%);
}

.flag-color:not(.null) {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}



/* フラグの画像切り替え */
.flag-color.safety-car-in-this-lap {
    background-image: url(../image/flags/safety-car-in-this-lap.png);
}

.flag-color.safety-car {
    background-image: url(../image/flags/safety-car.png);
}

.flag-color.sc {
    background-image: url(../image/flags/sc.png);
}

.flag-color.black-and-white {
    background-image: url(../image/flags/black-and-white.png);
}

.flag-color.black-flag-with-orange-disc {
    background-image: url(../image/flags/black-flag-with-orange-disc.png);
}

.flag-color.black {
    background-image: url(../image/flags/black.png);
}

.flag-color.blue {
    background-image: url(../image/flags/blue.png);
}

.flag-color.checkered {
    background-image: url(../image/flags/checkered.png);
}

.flag-color.code-60 {
    background-image: url(../image/flags/code-60.png);
}

.flag-color.green {
    background-image: url(../image/flags/green.png);
}

.flag-color.national-flag {
    background-image: url(../image/flags/national-flag.png);
}

.flag-color.red-and-yellow-stripes {
    background-image: url(../image/flags/red-and-yellow-stripes.png);
}

.flag-color.red {
    background-image: url(../image/flags/red.png);
}

.flag-color.white {
    background-image: url(../image/flags/white.png);
}

.flag-color.yellow {
    background-image: url(../image/flags/yellow.png);
}

.flag-color.fcy {
    background-image: url(../image/flags/fcy.png);
}

.flag-color.restart {
    background-image: url(../image/flags/restart.png);
}

.flag-color.drive-through-penalty {
    background-image: url(../image/flags/drive-through-penalty.png);
}

/* ここまでフラグの画像切り替え */

.option-menu {
    display: flex;
}

.light-mode-switch{

}

.light-mode .header-right-area .hamburger-menu .menu-btn .menu-line, .header-right-area .hamburger-menu .menu-btn .menu-line:before, .header-right-area .hamburger-menu .menu-btn .menu-line:after {
    display: block;
    position: absolute;
    top: 10px;
    left: 16px;
    width: 25px;
    height: 5px;
    background-color: #222F3F;
    content: '';
}

.test{
    width: 20px;
    height: 20px;
}

/* セッション一覧に戻るボタン　*/
.circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-left: 8px;
    border: solid 2px #ffffff;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: rgba(175, 231, 255, .2);
}

.opciton-menu {
    display: flex;
    margin-right: 48px;
    align-items: center;
}


.ellipse-button {
    width: 100px;
    height: 40px;
    margin: 0 auto;
    margin-left: 16px;
    border: solid 2px #ffffff;
    border-radius: 30px;
    background-color: rgba(175, 231, 255, .2);
    color: #ffffff;
    font-weight: bold;
    font-size: min(1.5vw,19.2px);
    text-align: center;
    line-height: 16px;
    font-family: 'Titillium Web', sans-serif;
}


.circle-button img {
    width: 100%;
    height: auto;
}

.circle-button .back-icon {
    width: 80%;
    height: auto;
}
.circle-button .download-icon {
    width: 80%;
    height: auto;
}

/* 表示非表示ポップアップ */
.top-view-mode-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 11.2rem;
    right: 370px;
    z-index: 6;
    max-width: 800px;
    max-height: 800px;
    /*background-image: linear-gradient(90deg, #242424 1px, transparent 1px),
    linear-gradient(180deg, #242424 1px, transparent 1px);
    background-size: 20px 20px;
     position: absolute; 
    left: 30px;
    top: 6rem; */
    background-color: #242424;
    color: white;
}

.top-view-mode-container-scroll {
    top: 7rem;
}

.view-mode-container-upper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}

.view-mode-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    gap: 16px;
}

.view-mode-div::after {
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #ffffff;
    content: '';
}

.view-mode-div::after {
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: #ffffff;
    content: '';
}

.view-mode-div:last-child::after {
    display: none;
}

.view-mode-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 50px;
    padding: 16px 0;
}

.change-view-close-button {
    width: 106px;
    height: 40px;
    margin-right: 10px;
    margin-left: auto;
    border: solid 3px #ffffff;
    border-radius: 30px;
    background-color: rgb(64, 75, 80) ;
    color: #ffffff;
    font-size: .9rem;
    text-align: center;
    cursor: pointer;
}

.class-display-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 25px;
    border: 1px solid #000000;
    font-weight: 1000;
    font-size: 1vw;
}

.allclass-display-header {
    padding: 0;
    color: #ffffff;
    font-weight: 1000;
}

/* 表示切り替え */
.display-switching-area {
    display: flex;
    align-items: center;
    position: relative;
}

.display-switching-area::before {
    position: absolute;
    left: -16px;
    width: 1px;
    height: 100%;
    background: #ffffff;
    content: '';
}
.display-switching-area  span {
    display: block;
    margin-right: 8px;
}

.display-switching-btns {
    display: flex;
    justify-content: center;
    position: relative;

    gap: 8px;
}

.display-switching-btn {
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    background: transparent;
    color: #4affff;
}

.display-switching-btns .btn-on {
    opacity: 1;
}
.btn-on::after {
    display: block;
    width: 20px;
    height: 2px;
    box-shadow: 0 0 10px #4affff ;
    background-color: #4affff ;
    content: '';
}
.display-switching-btn img {
    width: 20px;
    height: auto;
}



/* Liveページスクロール時の簡略化ヘッダー */

.scroll-headaer {
    padding: 16px 80px 0 24px;
}

.scroll-headaer .header-logo {
    display: none;
}

.scroll-headaer .header-right-area {
    margin: 0;
    gap: 10px;
}

.scroll-headaer .menu-buttons {
    min-width: 0;
    margin-left: 0;
}

.scroll-headaer .square-btn span {
    display: none;
}

.scroll-headaer .menu-checkbox {
    order: 0;
    min-width: auto;
    margin-right: 40px;
    align-items: center;
}

.scroll-headaer .option-menu {
    order: 2;

    gap: 0;
}

.scroll-headaer .header-right-area {
    flex-direction: row;
    align-items: center;
}


.scroll-headaer .square-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    padding: 8px;
    color: #ffffff;
    cursor: pointer;
}

.scroll-headaer .menu-buttons .square-btn img {
    width: 100%;
    padding-bottom: 0px;
}

.scroll-headaer .sub-function-area {
    display: flex;
}

.scroll-headaer .menu-buttons {
    align-items: center;
    order: 1;

    gap: 10px;
}

.scroll-headaer .menu-checkbox-left {
    flex-direction: column;

    gap: 4px;
}

.scroll-headaer .display-switching-area {
    flex-direction: column;
    justify-content: end;
}

.scroll-headaer .display-switching-area span {
    margin: 0;
    margin-bottom: 8px;
}

/* @media only screen and (max-width:1366px){ */
@media only screen and (max-width:1500px) {
    .header-menu-container {
        left: 250px;
        /* width: calc(100% - 250px); */
        width: calc(100% - 493px);
    }
    .menu-buttons button {
        /* margin-left: -3.5%; */
        /* margin-left: -2.5%; */
    }

    /* .menu-display{
        margin-left: 0%;
    } */
    .flag-text {
        /* min-width: 150px; */
        min-width: 110px;
    }
}

@media only screen and (max-width:1280px) {
    .menu-checkbox-right {
        margin-left: 6%;
    }

    .menu-buttons {
        min-width: 230px;
        min-width: 335px;
        margin-left: 0;
    }

    .menu-display {
        /* margin-left: -6%; */
    }

    .flag-text {
        /* min-width: 295px; */
        min-width: 114px;
    }
}


/* Analysisで使用 */
.analysis-sort-header {
    display: flex;
    flex-direction: row;
    position: relative;

    gap: 10px;
}

.class-select-element {
    position: absolute;
    z-index: 1;
}

/* iPadMini */
@media only screen and (max-width: 1366px) and (orientation: landscape) {
    /* タイトルロゴ */
    .logo-container {
        margin-top: 2%;
    }

    /* ヘッダーの下段型崩れ */
    .menu-checkbox {
        min-width: 470px;
    }

    /* バックボタンの文字サイズ */
    .ellipse-button {
        font-size: 1.5vw;
    }

    /* class選択　型崩れ防止 */
    .change-view-container {
        min-width: 80px;
    }
    /* 裏の順位とピットイン予測と表示切り替えスイッチ型崩れ防止 */
    /* .checkbox-div, .display-switching-area span {
        min-width: 110px;
    } */

    /* 裏の順位とピットイン予測と表示切り替えスイッチ型ボタン　余白 */
    .menu-checkbox-left {
        /* gap: 20px;
        margin-right:16px; */
    }

    /* Liveテーブル　文字サイズ変更 */
    .class-display-header, .allclass-display-header {
        font-size: 1.5vw ;
        text-align: center ;
    }
    /* 「class選択」ボタン　All項目配置整理 */
    .allclass-display-header {
        padding: 0;
    }

    /* スクロール時のヘッダー　裏の順位とピットイン予測と表示切り替えスイッチ型ボタン　余白  */
    .scroll-headaer .option-menu {
        margin-left: 16px;
    }
}

@media only screen and (max-width: 1366px) and (orientation: portrait) {
    /* ヘッダーの下段型崩れ */
    .menu-checkbox {
        min-width: 470px;
    }

    /* バックボタンの文字サイズ */
    .ellipse-button {
        font-size: 16px;
    }

    /* class選択　型崩れ防止 */
    .change-view-container {
        min-width: 80px;
    }

    /* class選択　文字サイズ変更 */
    .class-display-header, .allclass-display-header {
        padding: 0;
        font-size: 1.5vw;
        text-align: center;
    }

    /* 裏の順位とピットイン予測と表示切り替えスイッチ型崩れ防止 */
    .checkbox-div, .display-switching-area span {
        min-width: 110px;
    }

    /* 裏の順位とピットイン予測と表示切り替えスイッチ型ボタン　余白 */
    .menu-checkbox-left {
        margin-right: 16px;

        gap: 20px;
    }

    /* スクロール時のヘッダー　裏の順位とピットイン予測と表示切り替えスイッチ型ボタン　余白  */
    .scroll-headaer .option-menu {
        margin-left: 16px;
    }
}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .header-left-area {
        width: 50%;
    }

    .header-custom .logo-container {
        width: 20%;
    }

    .header-right-area .hamburger-menu {
        display: block;
        width: 0;
        height: 0;
    }

    /* ヘッダーロゴの調整 */
    .header-right-area {
        height: 197px;
    }

    /* PCのヘッダーアイテム必要なもの以外非表示 */
    .header-right-area .menu-buttons, .header-right-area .menu-checkbox {
        display: none;
    }

    .option-menu {
        z-index: 999;
    }

    /* ハンバーガーメニュー */
    .header-right-area .hamburger-menu .menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 16px;
        z-index: 999;
        width: 120px;
        height: 40px;
        border: solid 2px #ffffff;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175,231,255,.2);
        font-size: 16px;
    }


    .header-right-area .hamburger-menu .menu-btn .menu-line, .header-right-area .hamburger-menu .menu-btn .menu-line:before, .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        display: block;
        position: absolute;
        top: 10px;
        left: 16px;
        width: 25px;
        height: 5px;
        background-color: #ffffff;
        content: '';
    }

    .header-right-area .hamburger-menu .menu-btn .menu-line:before {
        top: 16px;
        left: 0;
        background-color: #ffffff;
    }
    .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        top: 8px;
        left: 0;
        background-color: #ffffff;
    }

    .header-right-area .hamburger-menu .menu-text {
        position: absolute;
        right: 16px;
    }

    /* ハンバーガーメニューに動きをつける */
    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-btn span {
        background-color: rgba(255, 255, 255, 0);
    }
    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-btn span::before {
        top: 8px;
        transform: rotate(45deg);
    }
    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-btn span::after {
        top: 8px;
        transform: rotate(-45deg);
    }

    .header-right-area .hamburger-menu #menu-btn-check {
        display: none;
    }

    /* メニュー開いた後 */

    .menu-content {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 80;
        width: 320px;
        height: 100%;
        background-color: #000000;
        transition: all .5s;
    }

    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-content {
        right: 0;
    }


    /* 1つ目のハンバーガーメニュー内のボックス */
    .header-right-area .hamburger-menu .hamburger-menu-item:first-of-type {
        width: 80%;
        height: 216px;
        margin: 8% auto 0;
        padding: 16px 24px;
        border: solid 2px #ffffff;
        box-sizing: border-box;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175,231,255,.2);
    }

    .header-right-area .hamburger-display-switching .display-switching-tit {
        font-weight: bold;
        font-size: 24px;
    }

    .header-right-area .hamburger-display-switching .button-area {
        display: flex;
        position: relative;
        margin-top: 16px;
        gap: 16px;
    }

    .header-right-area .hamburger-display-switching .button-area::after {
        position: absolute;
        bottom: -16px;
        width: 90%;
        height: 2px;
        box-shadow: 0 0 7px #ffffff;
        background-color: #ffffff;
        content: '';
    }

    .hamburger-display-switching .button-area .display-switching-btn {
        width: 3vw;
        height: 3vw;
    }

    .hamburger-display-switching .button-area .display-switching-btn img {
        width: 3vw;
        height: 3vw;
    }

    .hamburger-display-switching .checkbox-area {
        margin-top: 32px;
    }


    /* 2つ目のハンバーガーメニュー内のボックス */
    .header-right-area .hamburger-menu-item .hamburger-menu-btn {
        display: block;
        width: 80%;
        margin: 0 auto 16px;
        padding: 0;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175,231,255,.2);
    }

    .menu-content .hamburger-menu-btn{
        height: 64px;
    }

    .menu-content .hamburger-menu-btn span{
        padding-left: 8px;
    }

    .btn-on::after {
        width: 100%;
    }

    .header-right-area .hamburger-menu-item .hamburger-menu-btn:first-of-type {
        margin-top: 64px;
    }

    .header-right-area .hamburger-menu-item .btn-box {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
    }

    .header-right-area .hamburger-menu-item .hamburger-menu-btn img {
        width: 13%;
        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(58deg) brightness(110%) contrast(101%);
    }

    .header-right-area .hamburger-menu-item .hamburger-menu-btn span {
        width: 115px;
        font-weight: bold;
        font-size: 16px;
    }

    .top-view-mode-container {
        top: 90%;
        right: 20%;
        z-index: 999;
        font-size: min(2vw,18px);
    }

    .view-mode-container-upper td {
        font-size: min(2vw,18px);
    }

    .view-mode-container-upper .tablet-box {
        height: 527px;
    }

    .view-mode-container-upper .tablet-box .view-mode-div {
        height: 50%;
    }
}


/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .header-custom {
        margin: 2% 0 4%;
    }

    .scroll-headaer .header-logo {
        display: flex;
    }    /* ヘッダーの初期化 */

    .header-left-items:first-child{
        padding-bottom: 16px;
        margin-bottom: 0px;
    }

    .live-header {
        height: 197px;
        padding: 16px 16px 0;
    }

    .scroll-headaer .header-right-area {
        flex-direction: column;
        gap: 0;
        margin-top: 16px;
    }

}

@media only screen and (max-width: 743px) and (orientation: portrait) {
    /* タイトル */
    .header-custom::before {
        top: 50%;
        left: 0vw;
        width: 100vw;
        background-image: url(../image/header-tit-bk-sp.png);
    }

    .header-custom .logo-container {
        width: 30%;
    }

    .header-custom {
        margin: 4% 0 6%;
    }


    /* Liveヘッダー */

    .scroll-headaer .header-logo {
        margin: 0;
        padding-bottom: 0;
    }

    .logo-container {
        background-position: center;
    }

    .header-right-area .hamburger-menu .menu-text {
        display: none;
    }


    .side-menu-time, .opciton-menu, .header-left-items {
        display: none;
    }

    .live-header {
        position: relative;
        height: 8svh;
        padding: 16px;
        box-sizing: border-box;
    }

    .header-right-area .hamburger-menu .menu-btn {
        top: 2%;
        right: 20%;
        width: 40px;
        height: 32px;
        position: fixed;
    }

    .header-right-area .hamburger-menu .menu-btn .menu-line, .header-right-area .hamburger-menu .menu-btn .menu-line:before, .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        height: 2px;
    }

    .header-right-area .hamburger-menu .menu-btn .menu-line {
        top: 6px;
        left: 8px;
    }

    /* ハンバーガーメニュー */

    .header-right-area .hamburger-menu .hamburger-menu-item:first-of-type {
        margin: 10% auto 0;
    }


    .hamburger-display-switching .button-area .display-switching-btn {
        width: 8vw;
        height: 8vw;
    }

    .hamburger-display-switching .button-area .display-switching-btn img {
        width: 6vw;
        height: 6vw;
    }

    .view-mode-container-upper {
        /* height: 50vh;
        flex-direction: column; */
    }

    .top-view-mode-container .view-mode-div{
        width: 155px;
    }

    .top-view-mode-container .view-mode-div .MuiTableCell-body{
        width: 16px;
    }

    .top-view-mode-container .class-display-header {
        width: 50px;
        font-size: 12px;
    }

    .top-view-mode-container  .css-1j23aq4-MuiTable-root{
        min-width: auto;
    }

    .top-view-mode-container{
        right: 9%;
    }
}

/* 横画面のスマホ版 */
@media only screen and (max-width: 932px) and (orientation: landscape) {


    /* タイトル */
    .scroll-headaer .header-logo{
        display: block;
    }
    .header-custom::before {
        top: 50%;
        left: 0vw;
        width: 100vw;
        background-image: url(../image/header-tit-bk-sp.png);
        background-position: center;
        background-size: 80%;
    }

    .header-custom .logo-container {
        width: 30%;
    }

    .header-custom {
        margin: 4% 0 6%;
    }

    .scroll-headaer .header-logo {
        margin: 0;
    }

    .logo-container {
        width: 205px;
        height: 32px;
        background-position: center;
    }

    .header-right-area .hamburger-menu .menu-text {
        display: none;
    }


    .side-menu-time, .opciton-menu, .header-left-items {
        display: none;
    }

    .header-right-area .menu-buttons, .header-right-area .menu-checkbox{
        display: none;
    }

    .live-header {
        position: relative;
        height: 10svh;
        padding: 16px;
        box-sizing: border-box;
    }

    .header-right-area .hamburger-menu .menu-btn {
        top: 80px;
        right: 116px;
        width: 40px;
        height: 32px;
    }
    .header-right-area .hamburger-menu .menu-btn .menu-line, .header-right-area .hamburger-menu .menu-btn .menu-line:before, .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        height: 2px;
    }

    .header-right-area .hamburger-menu .menu-btn .menu-line {
        top: 6px;
        left: 8px;
    }


    /* ハンバーガーメニュー */
    .header-right-area .hamburger-menu{
        display: block;
        width: 0;
        height: 0;
    }

    .header-right-area .hamburger-menu .hamburger-menu-item:first-of-type {
        margin: 24% auto 0;
    }


    .hamburger-display-switching .button-area .display-switching-btn {
        width: 8vw;
        height: 8vw;
    }

    .hamburger-display-switching .button-area .display-switching-btn img {
        width: 3vw;
        height: 3vw;
    }

    .header-right-area .hamburger-menu-item .hamburger-menu-btn {
        width: 72%;
        height: 16%;
        padding: 1%;
    }

     /* ハンバーガーメニュー */
     .header-right-area .hamburger-menu .menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -2px;
        right: 90px;
        z-index: 999;
        width: 32px;
        height: 32px;
        border: solid 2px #ffffff;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175, 231, 255, .2);
        font-size: 16px;
    }
    .header-right-area .hamburger-menu .menu-btn .menu-line, .header-right-area .hamburger-menu .menu-btn .menu-line:before, .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        display: block;
        position: absolute;
        top: 6px;
        left: 4px;
        width: 25px;
        height: 5px;
        background-color: #ffffff;
        content: '';
    }
    .header-right-area .hamburger-menu .menu-btn .menu-line:before {
        top: 16px;
        left: 0;
    }
    .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        top: 8px;
        left: 0;
    }

    .header-right-area .hamburger-menu .menu-text {
        position: absolute;
        right: 16px;
    }

    /* ハンバーガーメニューに動きをつける */
    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-btn span {
        background-color: rgba(255, 255, 255, 0);
    }
    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-btn span::before {
        top: 8px;
        transform: rotate(45deg);
    }
    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-btn span::after {
        top: 8px;
        transform: rotate(-45deg);
    }

    .header-right-area .hamburger-menu #menu-btn-check {
        display: none;
    }

    /* メニュー開いた後 */

    .menu-content {
        position: fixed;
        top: 0;
        right: -100%;
        z-index: 80;
        width: 320px;
        height: 100%;
        background-color: #000000;
        transition: all .5s;
    }

    .header-right-area .hamburger-menu #menu-btn-check:checked ~ .menu-content {
        right: 0;
    }


    /* 1つ目のハンバーガーメニュー内のボックス */
    .header-right-area .hamburger-menu .hamburger-menu-item:first-of-type {
        width: 90%;
        height: 160px;
        margin: 23% auto 0;
        padding: 8px;
        border: solid 2px #ffffff;
        box-sizing: border-box;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175, 231, 255, .2);
    }

     /* 2つ目のハンバーガーメニュー内のボックス */
     .header-right-area .hamburger-menu-item .hamburger-menu-btn {
        display: block;
        width: 90%;
        margin: 0 auto 16px;
        padding: 0;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(175,231,255,.2);
    }

    .btn-on::after {
        width: 60%;
        margin: 0 auto;
    }

    .header-right-area .hamburger-menu-item .hamburger-menu-btn:first-of-type {
        margin-top: 64px;
    }

    .header-right-area .hamburger-menu-item .btn-box {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #ffffff;
    }

    .header-right-area .hamburger-menu-item .hamburger-menu-btn img {
        width: 13%;

        filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(58deg) brightness(110%) contrast(101%);
    }

    .header-right-area .hamburger-menu-item .hamburger-menu-btn span {
        width: 115px;
        font-weight: bold;
        font-size: 16px;
    }

    .view-mode-container-upper {
        /* height: 50vh;
        flex-direction: column; */
    }

    .top-view-mode-container .view-mode-div{
        width: 155px;
    }

    .top-view-mode-container .view-mode-div .MuiTableCell-body{
        width: 16px;
    }

    .top-view-mode-container .class-display-header {
        width: 50px;
        font-size: 12px;
    }

    .top-view-mode-container  .css-1j23aq4-MuiTable-root{
        min-width: auto;
    }

    .top-view-mode-container{
        right: 9%;
    }

    .ellipse-button{
        width: 80px;
        margin-left: 8px;
    }




}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    outline: none;
}

main,
#root {
    height: 100%;
    width: 100%;
    position: relative;
}

.App {
    height: 100%;
    width: 100%;
    position: absolute;
}

.main-container {
    height: calc(100% - 10vh);
    width: 100%;
    position: absolute;
    top: 10vh;
}

.content-container {
    position: relative;
    height: 100% !important;
    position: absolute;
    top: 0%;
}

/* modal */
.top-dialog.core {
    font-family: 'Noto Sans JP', sans-serif;
    padding: 10px 15px;
}

.dialog-title {
    font-size: 48px;
    font-weight: bold;
    color: #fff;
    margin: 2%;
    width: 35%;
    position: relative;
}

.dialog-title::after {
    content: '';
    width: 240px;
    height: 20px;
    background: url(../image/title-border.png) no-repeat center center / 100% 100%;
    position: absolute;
    bottom: -1vh;
    left: 0;
}

.custom-modal.core {
    font-family:'Noto Sans JP', sans-serif;
    padding: 0 10px !important;
}

.container-border {
    width: 70%;
    margin: auto;
    position: relative;
    padding: 2% 5%;
}

.container-border::before {
    content: '';
    width: 26px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: url(../image/border.png) no-repeat center center / 100% 100%;
    transform: rotate(180deg);
}

.container-border::after {
    content: '';
    width: 26px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: url(../image/border.png) no-repeat center center / 100% 100%;
}

/* buttons & inputs */

.button {
    cursor: pointer;
}

.button-disabled {
    opacity: 0.6;
    pointer-events: none;
}

select {
    outline: none;
}

.action-button-container {
    display: flex;
    height: 5%;
    z-index: 1;
    position: relative;
    margin: 1% 0 0 1%;
}

.action-button {
    position: relative;
    height: 20px;
    width: 40%;
    font-weight: bold;
    padding: 5px 0;
    border: none;
    background-color: #fff;
    color: #000;
    text-align: center;
    line-height: 20px;
    box-shadow: 8px 6px 10px 0px rgba(0, 0, 0, 1);
}

.action-button::before {
    position: absolute;
    top: 0;
    left: -18px;
    content: '';
    width: 8px;
    height: 30px;
    background-color: #fff;
    margin: 0 0 0 3%;
    box-shadow: 5px 0px 0px 0px rgba(0, 0, 0, .4);
}

.action-button:hover {
    background-color: transparent;
    color: #4AFFFF;
    border: 2px solid #4AFFFF;
    /*text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.7);*/
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.7);
}

.action-button:hover::before {
    position: absolute;
    top: 0;
    left: -18px;
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #4AFFFF;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.7);
}
/* ========== Analysis　共通 ========== */

.display-race-container {
    padding: 10px 88px;
}

.event-tit-container {
    width: 451px;
    height: 124px;
    margin: 0 auto;
    background-image: url(../image/evet-tit-bg.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    line-height: 90px;
    padding: 0 2vw;
}

.event-tit-container h1 {
    height: 108px;
    margin: 0;
    line-height: 100px;
    text-align: center;
    vertical-align: middle;
}

.select-container .box-title {
    display: inline-block;
    position: absolute;
    top: -10px;
    left: 5px;
    padding: 0 9px;
    background: #242424;
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    line-height: 1;
}


/* ========== FP Analysis Page ========== */

.fp-race-container .select-container {
    text-align: end;
}

.fp-race-container .select-container {
    position: relative;
    width: 15%;
    margin-bottom: 32px;
    margin-left: auto;
    padding: .5em 1em;
    border: solid 2px #ffffff;
}

.fp-race-container .event-button {
    display: inline-block;
    clip-path: polygon(100% 0, 100% 60%, 90% 100%, 0 100%, 0 0);
    position: absolute;
    right: 0;
    width: 160px;
    padding: 9px 10px;
    border: solid 3px #ffffff;
    background: rgba(86, 113, 125, .3);
    color: #ffffff;
    font-weight: bold;

    outline-offset: -10px;
}

.fp-race-container .event-button::before {
    display: block;
    position: absolute;
    right: 5px;
    bottom: -18px;
    transform: rotate(36deg);
    width: 3px;
    height: 54px;
    background-color: #ffffff;
    content: '';
}

.fp-race-container .select-container .select-box {
    height: 30px;
    margin-right: auto;
    border: none;
    color: #ffffff;
}

.fp-race-container .select-container::before {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';
}

.fp-race-container .select-container select {
    border: none;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.fp-race-container .selecter-container {
    display: flex;
    font-weight: bold;

    gap: 24px;
}

.fp-race-container .selecter-container input {
    width: 20px;
    height: 20px;
}

.fp-race-container .selecter-container label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.fp-race-container .selecter-container label::before, .fp-race-container .selecter-container label::after {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    content: '';
}

.fp-race-container .selecter-container label::before {
    display: inline-block;
    left: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid #4affff;
    border-radius: 50%;
    background-color: #000000;
    content: '';
}

.fp-race-container .selecter-container label::after {
    display: inline-block;
    opacity: 0;
    left: 9px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #4affff;
    content: '';
}

.fp-race-container .selecter-container input:checked + label::after {
    opacity: 1;
}

.fp-race-container .fp-table-container {
    width: 100%;
    background-color: transparent;
    font-weight: bold;
    font-family: 'Noto Sans JP', sans-serif;
    border-collapse: separate;
    border-spacing: 5px;
    font-size: 18px;
}

.fp-race-container .fp-table-container thead tr th {
    padding: 5px 10px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: inset 0 0 5px #4affff;
    background: #222f3f;
}

.fp-race-container .fp-table-container thead .outing-th button {
    color: #ffffff;
}

.fp-race-container .fp-table-container thead tr .outing-th.active button {
    color: #000000;
    font-weight: bold;
}

.fp-race-container .fp-table-container thead tr th:first-of-type {
    width: 80px;
    height: 36px;
    border: solid 2px #ffffff ;
    box-shadow: none;
    background-color: #222f3f;
    color: #ffffff;
    line-height: 36px;
}

.fp-race-container .fp-table-container thead tr .carno-th.active, .fp-race-container .fp-table-container thead tr .carno-th:hover, .fp-race-container .fp-table-container thead tr .outing-th:hover {
    box-shadow: inset 0 0 5px #4affff;
    background: #4affff;
}

.fp-race-container .fp-table-container thead tr .outing-th.active {
    box-shadow: inset 0 0 5px #4affff;
    background: #4affff;
    color: #000000;
}

.fp-race-container .fp-table-container .fp-table-row .fp-table-cell div {
    width: 80px;
    height: 40px;
    background-color: transparent;
    line-height: 40px;
}

/* .fp-race-container .fp-table-container .fp-table-row .fp-table-cell:first-of-type div {
    width: 80px;
    height: 36px;
    border: solid 2px #ffffff;
    background-color: #222f3f !important;
    color: #ffffff !important;
    line-height: 36px;
} */

.live-table-container {
    width: 100vw;
}

.fp-table-button {
    width: 56px;
    height: 24px;
    padding: 0;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    line-height: 24px;
}


.fp-race-container .outing-list-container, .fp-race-container .sector-list-container {
    width: 48%;
    height: 100%;
    margin: 0;
}

.fp-race-container .outing-list, .fp-race-container .sector-list {
    display: flex;
    justify-content: space-between;
}

.outing-list-container .outing-list-table {
    height: 100% ;
    margin: 20px 0 0 0;
    background: transparent;
}

.sector-list-container .secter-time-table {
    height: 100% ;
    margin: 20px 0 0 0;
    background: transparent;
}

.fp-race-container .best-lap-container {
    position: relative;
    width: 1330px;
    height: 587px;
    margin: 0 auto;
    /* background-image: url(../image/best-lap-container.png);
    background-size: 100%;
    background-repeat: no-repeat; */
}

.fp-race-container .best-lap-container-bg {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.fp-race-container .best-lap-container .best-lap-grah {
    position: absolute;
    top: 19%;
    left: 6%;
    width: 88%;
    height: 350px;
    margin: 0 auto;
    vertical-align: middle;
}

.fp-race-container .best-lap-container span {
    position: relative;
    font-weight: bold;
    font-size: 32px;
    text-align: start;
}

.fp-race-container .best-lap-container .lap-grah-base {
    position: absolute;
    top: 25px;
    left: 0;
    margin: 0;
    padding: 0;
}

.fp-race-container .best-lap-container .lap-grah-base li {
    display: inline-block;
    transform: skewX(320deg);
    width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #ffffff;
}


.fp-race-container .best-lap-container span::before {
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 182px;
    height: 16px;
    background-image: url(../image/border-line.png);
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
}

/* ========== QF Analysis Page ========== */

.qf-race-tit-section {
    position: relative;
}

.qf-race-container .event-button {
    display: inline-block;
    clip-path: polygon(100% 0, 100% 60%, 90% 100%, 0 100%, 0 0);
    position: absolute;
    right: 0;
    width: 160px;
    padding: 9px 10px;
    border: solid 3px #ffffff;
    background: rgba(86, 113, 125, .3);
    color: #ffffff;
    font-weight: bold;

    outline-offset: -10px;
}

.qf-race-container .event-button::before {
    display: block;
    position: absolute;
    right: 5px;
    bottom: -18px;
    transform: rotate(36deg);
    width: 3px;
    height: 54px;
    background-color: #ffffff;
    content: '';
}

.qf-race-container .select-container {
    text-align: end;
}

.qf-race-container .select-container {
    position: relative;
    width: 15%;
    margin-bottom: 32px;
    padding: .5em 1em;
    border: solid 2px #ffffff;
    float: right;
}

.selecters{
    padding: 4vh 0;
}

.qf-race-container .select-container p {
    margin: 0;
    padding: 0;
}

.qf-race-container.select-container .select-box {
    height: 30px;
    margin-right: auto;
    border: none;
    color: #ffffff;
}

.qf-race-container .select-container::before {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';
}

.qf-race-container .select-container select {
    border: none;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.qf-race-container .live-table-container {
    margin: 0 auto;
    background: transparent;
}

.qf-race-container .live-table {
    background-color: transparent;
    border-collapse: separate;
    border-spacing: 5px;
}

.qf-race-container .live-table thead tr th {
    background-color: #222f3f;
    font-size: 24px;
}

.qf-race-container .live-table thead tr th, .qf-race-container .live-table td {
    font-size: 24px;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
}

.qf-race-container .live-table thead .follow {
    visibility: hidden;
}

.qf-race-container .live-table td img {
    display: block;
    margin: auto;
}

.qf-race-container .table-carno-boxs {
    display: table-cell;
    width: auto;
    height: auto;
    padding: 8px 0;
    vertical-align: middle;
}

.qf-race-container .active {
    display: table-cell;
    width: auto;
    height: auto;
    padding: 8px 0;
    border: 2px solid red;
}

.qf-race-container .live-table .follow {
    width: 1vw;
    max-width: 150px;
    max-width: 100px;
    height: 1vh;
    line-height: 1vh;
}

.qf-race-container .live-table .table-carno-boxs, .qf-race-container .live-table .lap-no {
    background: rgba(86, 113, 125, .4);
}


.qf-race-container .table-carno-boxs .car-no {
    display: block;
    width: 30%;
    height: 1.5vh;
    margin: 0 auto;
    padding: 8px;
    border: none;
    border-radius: 3px;
    font-size: 24px;
    line-height: 1.5vh;
}

.qf-race-container .lap-no, .qf-race-container .name {
    color: #ffffff;
    font-size: 24px;
}


/* ========== QF Analysis Page ここまで ========== */


/* ========== RACE Analysis Page ========== */

.display-race-chart-container {
    height: 480px;
}

.display-race-chart {
    position: relative;
    width: 85%;
    max-width: 1200px;
    height: 400px;
    margin: 0 auto;
}

.display-race-chart-sort {
    display: flex;
    justify-content: end;
    font-weight: bold;
    font-size: min(1.0vw, 19.2px);
    font-family: 'Saira Condensed', sans-serif;
}


.display-race-chart-sort label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #ffffff;
    vertical-align: middle;
    cursor: pointer;
}

.display-race-chart-sort label:last-child {
    margin-right: 0;
}

.display-race-chart-sort input {
    display: none;
}

.display-race-chart-sort span {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border: 2px solid white;
    border-radius: 50%;
    border-radius: 0;
    background-color: white;
}

.display-race-chart-sort span::before {
    display: block;
    position: absolute;
    top: 52%;
    left: 0;
    width: 88px;
    height: 2px;
    border-radius: 3px;
    background-color: #ffffff;
    content: '';
}


.display-race-chart-sort input:checked + span::before {
    display: none;
    content: '';
}



.display-race-chart-sort .attack {
    background-color: rgba(34, 47, 63, .5);
}


.display-race-chart-sort .defense {
    background-color: rgba(255, 168, 0, .5);
}

.display-black-text {
    color: #ffffff;
    font-weight: 700;
    font-size: 1.4rem;
}

.display-race-none {
    text-align: center;
}

.race-container .header-container {
    margin-bottom: 96px;
}

.race-container .event-tit-container {
    position: relative;
}

.race-container .event-tit-container .tit-decoration {
    position: absolute;
    font-weight: bold;
    font-size: 27px;
    line-height: normal;
    font-family: 'Saira Condensed', sans-serif;
}

.race-container .event-tit-container .tit-decoration:first-of-type {
    top: 0;
    left: -60%;
}

.race-container .event-tit-container .tit-decoration:first-of-type::before {
    display: block;
    position: absolute;
    bottom: -40px;
    left: -20%;
    width: 348px;
    height: 46px;
    background-image: url(../image/race-tit-line-left.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}

.race-container .event-tit-container .tit-decoration:nth-of-type(2) {
    right: -44%;
    bottom: 0;
}

.race-container .css-15wwp11-MuiTableHead-root th{
    font-size: 1rem;
}


.race-container .event-tit-container .tit-decoration:nth-of-type(2)::before {
    display: block;
    position: absolute;
    bottom: -4px;
    left: -90%;
    width: 348px;
    height: 46px;
    background-image: url(../image/race-tit-line-right.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}

.race-container .event-tit-container .small-text {
    font-size: 16px;
}

.race-container .select-container {
    position: relative;
    width: 15%;
    float: right;
    padding: .5em 1em;
    border: solid 2px #ffffff;
}

.race-container .select-container p {
    margin: 0;
    padding: 0;
}

.race-container .select-container .select-box {
    height: 30px;
    margin-right: auto;
    border: none;
    color: #ffffff;
}

.race-container .select-container::before {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';
}

.race-container .select-container select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.race-container .event-button {
    display: inline-block;
    clip-path: polygon(100% 0, 100% 60%, 90% 100%, 0 100%, 0 0);
    position: absolute;
    right: 0;
    width: 160px;
    margin-right: 7vw;
    padding: 9px 10px;
    border: solid 3px #ffffff;
    background: rgba(86, 113, 125, .3);
    color: #ffffff;
    font-weight: bold;

    outline-offset: -10px;
}

.race-container .event-button::before {
    display: block;
    position: absolute;
    right: 5px;
    bottom: -19px;
    transform: rotate(34deg);
    width: 3px;
    height: 55px;
    background-color: #ffffff;
    content: '';
}

.race-container .display-race-info-right {
    display: flex;
    margin-bottom: 8px;

    gap: 16px;
}

.race-container .main-table {
    width: 88vw;
    margin: 0 auto;
}


.race-container .main-table .live-table {
    width: 100%;
    color: #ffffff;
    border-collapse: separate;
    border-spacing: 0 24px;
    font-size: 1.2rem;
}

.race-container .main-table .live-table tr {
    position: relative;
}

.race-container .main-table .live-table tr::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid #ffffff;
    content: '';
}

.race-container .main-table .live-table thead tr::before {
    border-bottom: none;
}


.race-container .main-table .live-table td:first-of-type::before {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.race-container .main-table .live-table td:last-of-type::before {
    display: block;
    position: absolute;
    right: 0;
    bottom: -14px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.race-container .main-table .live-table .race-chart-td:last-of-type::before {
    right: 0;
    bottom: -6px;
    background-color: #ff0000;
}

.race-container .main-table .live-table .race-chart-td:last-of-type::after {
    right: 0;
    bottom: -6px;
    background-color: #ff0000;
}

.race-chart-td .race-chart-line-dec {
    display: block;
    position: absolute;
    right: 0;
    bottom: -5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff0000;
}

.race-chart-td::after {
    right: 0;
    bottom: -14px;
    background-color: #ff0000;
}

.race-container .main-table .live-table td {
    position: relative;
    text-align: center;
}


.race-container .main-table div:nth-of-type(3) {
    background-color: transparent;
}

.race-container .live-table thead {
    height: 64px;
    background-color: rgba(175, 231, 255, .4);
}

.race-container .live-table thead th {
    width: auto;
    padding: 0;
    line-height: 64px;
}

.race-container .live-table thead .name-header {
    text-align: start;
}

.race-container .main-table .live-table .follow-cell {
    text-align: center;
}

.race-container .main-table .live-table .carno-cell {
    border-radius: 3px;
    text-align: center;
}


.race-container .main-table .live-table .carno-cell {
    border-radius: 3px;
    text-align: center;
}

.race-container .main-table .live-table .name-cell {
    width: 300px;
    margin: 0 auto;
    text-align: start;
}

.display-race-chart-container-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: -15%;
    left: -9%;
    height: 100%;
}

.display-race-chart-container-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.race-container .main-table .live-table .race-chart-tr::before {
    content: none;
}

.race-container .main-table .live-table .race-chart-td {
    position: relative;
    height: 700px;
    border-bottom: solid 1px #ff0000;
    /* background: url(../image/grap-analysis.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; */
}
.race-container .main-table .live-table .race-chart-td .race-chart-bg {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 80%;
}



.race-container .main-table .live-table .css-y50uh2-MuiSlider-root {
     width: 15px !important; /*CoreのCSSと競合　Panda-CSSを使用したら解決するため移行までは!important指定 */
    height: 60%;
    padding: 0;
    border: 1px solid #ffffff;
    border-radius: 0;
}

.race-container .main-table .live-table .css-187mznn-MuiSlider-root {
    width: 85%;
    height: 15px;
    margin: 0;
    padding: 0;
    border: 1px solid #ffffff;
    border-radius: 0;
}

.race-container .main-table .live-table .css-ada0pd-MuiSlider-track, .race-container .main-table .live-table .css-1gv0vcd-MuiSlider-track {
    background: repeating-linear-gradient(45deg, #4affff, #4affff 5px, transparent 5px, transparent 10px);
}

.race-container .main-table .live-table .css-y35fuw-MuiSlider-thumb, .race-container .main-table .live-table .css-eg0mwd-MuiSlider-thumb {
    width: 0;
}

.race-container .main-table .live-table .css-y35fuw-MuiSlider-thumb::after {
    position: absolute;
            transform: translate(-50%, -50%);
    width: 24px;
    height: 8px;
    border-radius: 0;
    background-color: #4affff;
    color: transparent;
    content: '';

    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
}

.race-container .main-table .live-table .css-eg0mwd-MuiSlider-thumb::after {
    position: absolute;
            transform: translate(-50%, -50%);
    width: 8px;
    height: 24px;
    border-radius: 0;
    background-color: #4affff;
    color: transparent;
    content: '';

    -webkit-transform: translate(-50%, -50%);
       -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
}


.race-container .main-table .live-table .css-qx5f9e-MuiSlider-rail, .race-container .main-table .live-table .MuiSlider-rail {
    width: 100%;
    color: transparent;
}

.race-container .main-table .live-table .slider-text {
    position: relative;
    color: #ffffff;
    font-weight: bold;
    font-size: 24px;
}

.race-container .main-table .live-table .slider-text::before {
    display: block;
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 120%;
    height: 1px;
    background-color: #ffffff;
    content: '';
}

.race-container .main-table .live-table .slider-text::after {
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    content: url(../image/line-end-icon.png);
}

.race-container .main-table .live-table .slider-text-dec {
    position: absolute;
    top: 10px;
    left: 0;
    margin: 0;
    padding: 0;
}

.race-container .main-table .live-table .slider-text-dec li {
    display: inline-block;
    transform: skewX(320deg);
    width: 5px;
    height: 5px;
    margin-right: 3px;
    background: #ffffff;
}

.race-container .main-table .live-table .updown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 8px;
}

.race-container .main-table .live-table .updown img {
    width: 24px;
}

.race-container .main-table .live-table .updown:first-of-type {
    margin-top: 70px;
    margin-bottom: 8px;
}

.race-container .main-table .live-table .updown:last-of-type {
    margin-top: 8px;
}

.display-race-chart-container-bottom .updown {
    transform: rotate(-90deg);
}

.race-container .main-table .live-table .display-race-chart-container-bottom .updown:first-of-type {
    margin-top: 0;
    margin-bottom: 0;
}

.race-container .main-table .live-table .updown .y-max-down {
    transform: rotate(-180deg);
}

.race-container .main-table .live-table .slider-text-laptime {
    transform: rotate(-90deg);
}

.race-container .main-table .live-table .slider-text-laptime::after {
    right: -27%;
    bottom: 14%;
}

.race-container .main-table .live-table .slider-text-sector {
    display: block;
    position: absolute;
    right: -6.5%;
    bottom: 15%;
    transform: rotate(-90deg);
    width: 100px;
}

.race-container .main-table .live-table .slider-text-sector::after {
    top: 47%;
    right: -27%;
}

.race-container .main-table .live-table .slider-text-sector::after {
    top: 46%;
    right: -27%;
}

.race-container .main-table .live-table .slider-text-lap::after {
    top: 48%;
    right: -25%;
}

.race-container .car-num-selecter-box {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 16px;
}

.race-container .carNo-info {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 24px;
}

.race-container .car-num-selecter {
    position: relative;
    width: 200px;
    padding: .5em 1em;
    border: solid 2px #ffffff;
    border-radius: 0;
    background-color: transparent;
    color: #ffffff;
}

.race-container .car-num-selecter::before {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';
}

.race-container .car-num-selecter svg {
    display: none;
}

.race-container .car-num-selecter > div {
    padding: 0;
}

.race-container .car-num-selecter input {
    height: auto;
    line-height: 0;
}

.race-container .same-team-table {
    display: flex;
    margin: 46px 0;
}

.race-container .time-table, .race-container .speed-table {
    border-collapse: separate;
    border-spacing: 8px;
}

.race-container .lapTime-box, .race-container .Speed-box {
    width: 100%;
}

.race-container .lapTime-box h3, .race-container .Speed-box h3 {
    position: relative;
    width: 100%;
    color: #ffffff;
}

.race-container .lapTime-box h3, .race-container .Speed-box h3 {
    position: relative;
    width: 100%;
    width: 30%;
    margin: 0;
    margin-top: 32px;
    margin-bottom: 32px;
    border-bottom: 2px solid #ffffff;
    color: #ffffff;
    font-size: 24px;
    font-family: 'Saira Condensed', sans-serif;
}

.race-container .lapTime-box h3::after, .race-container .Speed-box h3::after {
    display: block;
    position: absolute;
    right: 0;
    bottom: -6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.race-container .same-team-table .same-team-tit-dec {
    position: absolute;
    top: 30px;
    left: 0;
    margin: 0;
    padding: 0;
}

.race-container .same-team-table .same-team-tit-dec li {
    display: inline-block;
    transform: skewX(320deg);
    width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #ffffff;
}


.race-container .time-table th, .race-container .speed-table th {
    padding: 8px;
    border: solid 2px #ffffff;
    background-color: #222f3f;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
}

.race-container .time-table .lap-time-th, .race-container .speed-table .lap-time-th {
    visibility: hidden;
    width: 64px;
    min-width: auto;;
}

.race-container .first-td div, .race-container .first-td div {
    border: solid 2px #ffffff;
    color: #ffffff ;
}

.race-container .best-td {
    border: solid 2px #4affff;
    background-color: transparent;
    color: #4affff ;
    text-align: center;
}

.race-container .best-td:nth-of-type(2) {
    border: solid 2px #4affff;
    background-color: #4affff ;
    color: #000000 ;
    text-align: center;;
}

.race-container .time-table td div, .race-container .speed-table td div {
    height: 64px;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 20px;
    line-height: 64px;
}

.race-container .lapTime-container {
    position: relative;
    max-width: 95vw;
    max-height: 100vh;
    margin: 0 auto;
    background: url(../image/grap-analysis.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.race-container .lapTime-flex-container {
    max-width: 70%;
    margin: 5% auto 0;
    padding-top: 6%;
}

.race-container .lapTime-flex-container .updown {
    flex-direction: column;
}

.race-container .lapTime-flex-container .chart-slider {
    width: 16px;
    padding: 0;
    border: solid 1px #ffffff;
    border-radius: 0;
    box-sizing: border-box;
}

.race-container .lapTime-flex-container .css-qx5f9e-MuiSlider-rail {
    background: transparent;
}

.race-container .lapTime-flex-container .chart-slider .css-ada0pd-MuiSlider-track {
    width: 100%;
    border: none;
    background: repeating-linear-gradient(45deg, #4affff, #4affff 5px, transparent 5px, transparent 10px );
}
.race-container .lapTime-flex-container .chart-slider .css-y35fuw-MuiSlider-thumb {
    width: 24px;
    height: 8px;
    border-radius: 0;
    background-color: #4affff;
}

.lapTime-container .stack-slider-flex {
    justify-content: center;
}

.race-container .lapTime-container .stack-slider-flex {
    width: 100%;
    position: relative;
}

.race-container .lapTime-container .chart-slider-bottm {
    height: 16px;
    padding: 0;
    border: solid 1px #ffffff;
    border-radius: 0;
    box-sizing: border-box;
}

.race-container .lapTime-container .chart-slider-bottm .css-14pt78w-MuiSlider-rail {
    background: transparent;
}

.race-container .lapTime-container .chart-slider-bottm .css-1gv0vcd-MuiSlider-track {
    border: none;
    background: repeating-linear-gradient(45deg, #4affff, #4affff 5px, transparent 5px, transparent 10px );
}

.race-container .lapTime-container .chart-slider-bottm .css-eg0mwd-MuiSlider-thumb {
    width: 8px;
    height: 24px;
    border-radius: 0;
    background-color: #4affff;
}
/* ========== RACE Analysis Page ここまで ========== */



.qf-race-container .rival-table-display .live-table thead tr th{
    font-size: 16px;
    vertical-align: middle;
}

.qf-race-container .rival-table-display .live-table tbody td {
    font-weight: bold;
    font-size: 20px;
}


/* FPとQFのライバルページ */
.rival-table-container .contribution-tit {
    width: 90vw;
    height: 64px;
    background-color: rgba(86, 113 ,125 ,.4);
    font-size: 24px;
    line-height: 64px;
    text-align: center;
    /*text-shadow: rgba(255,255,255,.4) 0 1px 5px;*/
}

.rival-table-main-tit {
    margin: 0;
    font-size: 24px;
    /*text-shadow: 0 0 2.5px #ffffff;*/
    padding-top: 4vh;
}

.rival-table-display .table-sab-tit {
    font-size: 16px;
    /*text-shadow: 0 0 2.5px #ffffff;*/
}

.rival-table-container .css-qvfyf4-MuiPaper-root-MuiTableContainer-root, .rival-table-container .css-1ku6taj-MuiPaper-root-MuiTableContainer-root {
    width: 45%;
    box-shadow: none;
    background-color: transparent;
    height: 100%;
}

.rival-table-container .live-table {
    background-color: transparent;
    color: #ffffff;
    border-collapse: separate;
    border-spacing: 5px;
}

.rival-table-container .css-m2hzx9-MuiTableCell-root {
    color: #ffffff !important;
}

.table-boxs {
    display: flex;
    justify-content: center;
    width: 45vw;
}

.rival-table-container .live-table th {
    padding: 5px 0;
    border: solid 2px #ffffff;
    background: #222f3f;
    text-align: center;
}

.rival-table-container .lap-time-table tr {
    background-color: transparent;
}

.rival-table-container .lap-time-table thead tr:first-of-type, 
.rival-table-container .lap-table thead tr:first-of-type {
    border: none ;
}

.rival-table-container .lap-time-table th:first-of-type, .rival-table-container .lap-table th:first-of-type {
    border: none ;
    background-color: transparent;
}

.lap-time-table tr td:first-of-type, .lap-table tr td:first-of-type {
    border: none;
    background-color: rgba(175, 231, 255, .2);
}

.lap-time-table tr td:nth-of-type(2n) {
    border: solid 1px #ffffff;
    box-sizing: border-box;
    background-color: transparent;
}

.secter-time-table tr {
    background-color: transparent;
}

.secter-time-table tr td:nth-of-type(2n) {
    border: solid 1px #ffffff;
    background-color: transparent;
}

.secter-time-table tr td:nth-of-type(2n-1) {
    border: none;
    background-color: rgba(175, 231, 255, .2);
}



.rival-table-header .rival-table-select {
    position: relative;
    width: 35%;
    height: 48px;
    padding: .5em 1em;
    border: solid 2px #ffffff;
    background-color: transparent;
    color: #ffffff;
}

.rival-table-header .rival-table-select {
    appearance: none;
}

.rival-table-header .select-box {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.rival-table-header .select-box::before {
    position: absolute;
    top: 50%;
    right: 35%;
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';
}

.rival-chart-container {
    display: flex;
    width: 100%;
    padding-top: 10px;

    gap: 10px;
}

.rival-chart-inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.rival-chart-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    /* width: 60%; */
}

.chart-shadow {
    position: absolute;
    top: 21.5%;
    left: 13.5%;
    z-index: -1;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    box-shadow: 0 0 30px 1px rgba(255, 255, 255, 80%);
    background-color: transparent;
}

.rival-table-container .sec-table .rival-chart-inner-container .chart-shadow {
    top: 17%;
    box-shadow: 0 0 40px 1px rgba(255, 255, 255, 80%);
}


.rival-chart-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.rival-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.rival-table-header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: transparent;
}

.rival-table-header-container .rival-img {
    position: absolute;
    position: absolute;
    top: 70%;
    left: 50%;
            transform: translate(-50%, -50%);

    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
}


.rival-table-container  .rival-table-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border-bottom: none;
}

/* Coreと競合　解決後　消す　12月19日 */
.rival-table-select {
    width: 300px;
    padding: 5px;
    border: 1px solid #767676;
    background-color: #ffffff;
    color: #000000;
}


.rival-table-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.rival-table-display {
    display: flex;
    justify-content: space-around;
    align-items: normal;
    width: 100%;
}

.rival-table-divider {
    width: 50px;
}

/*B5 横画面のタブレット */
@media only screen and (max-width: 1366px) {
    .display-race-container {
        position: relative;
        padding: 0;
    }

    /* ========== FP Analysis Page ========== */

    .fp-race-container {
        padding: 0 24px;
    }


    .rival-table-display {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 100vw;
    }

    .fp-race-container .best-lap-container {
        width: 90vw;
    }

    /* ========== QF Analysis Page ========== */
    .qf-race-container .live-table-container {
        width: 95vw;
        background-color: transparent;
    }
}

/* 縦画面のタブレット */
/* @media only screen and (max-width: 1366px) and (orientation: portrait) {

    .live-table-container {
        width: 100vw! important;
    }

    .display-race-container {
        position: relative;
        padding: 0;
    }

    .rival-table-main {
        width: 100vw;
    }

    .rival-table-display {
        display: flex;
        flex-direction: column;
    }


    .display-race-info-right {
        top: 8%;
        right: 0;
    }

    .pit-cells {
        width: 100%;
    }

    .number-of-cars {
        font-size: 1.5vw;
    }

    .bestLap-items span {
        font-size: 1.7vw;
    }

    .secter-items {
        font-size: 1.7vw;
    }
    footer .container {
        margin-top: 20px;
    }
} */


/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .event-tit-container {
        width: 50%;
    }

    /* ========== FP Analysis Page ========== */
    .fp-race-container {
        width: 90vw;
        margin: 0 auto;
    }


    .fp-race-container .select-container {
        width: 24%;
    }

    .fp-race-container .fp-selecter-boxs {
        flex-direction: column;
    }

    .fp-race-container .best-lap-container {
        width: 90vw;
        height: 30vh;
    }

    .fp-race-container .best-lap-container .best-lap-grah {
        top: 41%;
        height: 56%;
    }

    .fp-race-container .selecter-container, .fp-race-container .fp-table-container, .fp-race-container .outing-list, .fp-race-container .sector-list {
        font-size: 14px;
    }

    .fp-race-container .best-lap-container span {
        font-size: 18px;
    }

    .fp-race-container .best-lap-container .best-lap-grah {
        top: 15%;
        height: 56%;
    }

    /* ========== QF Analysis Page ========== */
    .qf-race-container {
        width: 90vw;
        margin: 0 auto;
    }
    .qf-race-container .select-container {
        width: 20%;
    }

    .qf-race-container .event-button::before {
        right: 5px;
        bottom: -18px;
        transform: rotate(36deg);
    }

    .qf-race-container .live-table {
        width: 84vw;
        margin: 0 auto;
    }

    /* ========== RACE Analysis Page ========== */

    .race-container .event-tit-container {
        width: 240px;
    }

    .race-container .event-tit-container h1{
        line-height:114px;
    }

    .race-container .event-tit-container .tit-decoration:first-of-type::before {
        bottom: -100% ;
        left: -40%;
    }

    .race-container .event-tit-container .tit-decoration:first-of-type {
        top: -30%;
        left: -79%;
    }

    .race-container .event-tit-container .tit-decoration:first-of-type::before {
        bottom: -100% ;
        left: -17%;
    }

    .race-container .event-tit-container .tit-decoration:nth-of-type(2) {
        right: -40%;
        bottom: 0;
    }

    .display-race-chart-sort {
        font-size: min(2.5vw, 19.2px);
    }

    .race-container .main-table .live-table .race-chart-td {
        height: 55vh;
    }

    .display-race-chart-container {
        height: 60%;
    }

    .display-race-chart {
        height: 86%;
    }

    .race-container .main-table .live-table .slider-text::before {
        bottom: 6%;
    }

    .race-container .main-table .live-table .slider-text-laptime::after {
        right: -26%;
        bottom: 3%;
    }

    .race-container .main-table .live-table .slider-text-lap::after {
        top: 57%;
        right: -34%;
    }

    .race-container .main-table .live-table .slider-text-sector::after {
        top: 55%;
        right: -27%;
    }

    .race-container .main-table {
        width: 95vw;
    }

    .race-container .live-table thead th {
        line-height: 20px;
        text-align: left;
        vertical-align: middle;
    }

    .race-container .main-table .live-table td:first-of-type::before, .race-container .main-table .live-table td:last-of-type::before {
        content: none;
    }

    .race-container .main-table .live-table .css-187mznn-MuiSlider-root {
        width: 58% !important  /*CoreのCSSと競合　Panda-CSSを使用したら解決するため移行までは!important指定 */;
    }

    .display-race-chart-container-bottom {
        justify-content: space-around;
    }
    .race-container .main-table .live-table .updown img {
        width: 15px;
    }

    .race-container .main-table .live-table .slider-text-lap {
        width: 40px;
    }

    .race-container .lapTime-flex-container{
        max-width: 88%;
        height: 32vh;
    }


    .race-container .time-table td div, 
    .race-container .speed-table td div{
        font-size: 14px;
    }

    .race-container .time-table th, 
    .race-container .speed-table th {
        font-size: 14px;
    }

    .race-container .event-tit-container .tit-decoration:nth-of-type(2)::before{
        width: 233px;
    }

    /* FPとQFのライバルページ */

    .rival-chart-inner-container h3 {
        font-size: 16px;
    }

    .rival-table-container  .factor-sec-table .rival-chart-inner-container {
        width: 30%;
    }

    .rival-chart-container:first-of-type {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 80vw;
        padding: 0 4vw;
    }

    .rival-table-container .sec-table {
        flex-wrap: wrap ;
    }

    .rival-table-container .sec-table .rival-chart-inner-container {
        flex: 1;
    }

    .rival-table-container .sec-table .rival-chart-inner-container:first-of-type {
        flex: 1 0 auto;
        width: 85vw;
    }


    .rival-table-container .sec-table .rival-chart-inner-container canvas {
        width: 40vw;
        height: 40vw;
    }
    .rival-table-header .rival-table-select {
        width: 55%;
    }

    .rival-table-header .select-box::before {
        right: 30%;
    }

    .rival-table-container .table-boxs {
        width: 85vw;
    }

    .rival-table-container .css-1ku6taj-MuiPaper-root-MuiTableContainer-root {
        width: 50%;
    }

    .rival-table-container .live-table {
        width: 100%;
    }

    .race-container .lapTime-container .stack-slider-flex {
        bottom:-5px;
    }


}


@media only screen and (max-width: 743px) and (orientation: portrait) {
    /* 共通ページ */
    .analysis-page .event-tit-container {
        width: 85vw;
        margin-top: 64px;
    }

    .fp-race-container .event-button, .qf-race-container .event-button, .race-container .event-button {
        top: -40px;
        width: 160px;
        height: 42px;
        font-size: 12px;
    }

    /* FPページ */

    .fp-race-container .fp-selecter-boxs {
        flex-direction: column;
    }

    .fp-race-container .select-container {
        flex-direction: column;
        width: 40%;
        margin-bottom: 16px;
    }

    .fp-race-container .selecter-container {
        flex-direction: column;
        font-size: 12px;

        gap: 16px;
    }

    .fp-race-container .selecter-container p {
        margin: 0;
    }

    .fp-race-container .fp-selecter-boxs button {
        width: 104px;
        height: 32px;
        margin-top: 16px;
        font-size: 12px;
    }

    .fp-race-container .event-button::before {
        display: block;
        position: absolute;
        right: 5px;
        bottom: -21px;
        transform: rotate(44deg);
        width: 3px;
        height: 54px;
        background-color: #ffffff;
        content: '';
    }

    .fp-race-container .fp-table-container {
        margin-bottom: 16px;
    }

    .fp-race-container .draiver-detail-table {
        flex-direction: column;
    }

    .fp-race-container .outing-list, .fp-race-container .sector-list {
        font-weight: bold;
        font-size: 12px;
    }

    .fp-race-container .outing-list-container, .fp-race-container .sector-list-container {
        width: 100%;
    }

    .outing-list-container .outing-list-table, .sector-list-container .secter-time-table {
        margin: 0;
    }


    /* QFページ */
    .qf-race-container .event-button {
        width: 160px;
        height: 42px;
        font-size: 12px;
    }

    .qf-race-container .event-button::before {
        display: block;
        position: absolute;
        right: 5px;
        bottom: -21px;
        transform: rotate(44deg);
        width: 3px;
        height: 54px;
        background-color: #ffffff;
        content: '';
    }

    .qf-race-container .select-container {
        width: 40%;
    }

    .qf-race-container .live-table {
        width: 90vw;
        margin: 0;
    }

    .qf-race-container .live-table thead tr th, .qf-race-container .live-table td {
        padding: 0;
        font-size: min(3.2vw, 14px);
    }

    /* .qf-race-container .table-carno-boxs .car-no{
       
    } */

    .qf-race-container .live-table td img {
        width: 56%;
    }

    .qf-race-container .table-carno-boxs .car-no {
        width: 4vw;
        height: 1.5vh;
        padding: 4px 8px;
        line-height: 1.5vh;
        font-size: min(3.2vw, 14px);
    }

    /* Raceページ */
    .race-container .event-tit-container {
        width: 85vw;
    }

    .race-container .header-container {
        margin: 0;
    }

    .race-container .tit-decoration {
        display: none;
    }

    .race-container .select-container {
        width: 40%;
        margin-bottom: 16px;
    }

    .race-container .main-table .live-table tr {
        font-size: 16px;
    }

    .race-container .race-chart-tr {
        transform: rotate(270deg);
    }

    .race-container .main-table .live-table .updown:first-of-type {
        margin-top: 24px;
    }

    .display-race-chart-container-left {
        left: -4%;
    }

    .race-container .main-table .live-table .updown:last-of-type {
        margin-top: 0;
    }

    .race-container .main-table .live-table .slider-text {
        display: none;
    }
}


/* 横画面のスマホ版 */
@media only screen and (max-width: 932px) and (orientation: landscape) {
    .qf-race-container .event-button::before {
        bottom: -18px;
        transform: rotate(37deg);
    }


    .race-container .tit-decoration {
        display: none;
    }

    .race-container .header-container {
        margin-bottom: 0;
    }
}
.track-container {
    width: 100%;
    height: 28%;
    margin: 2% 0 0;
    border: 1px solid;
    background: rgba(86, 113, 125, 0.4);
}

.track-content {
    height: 100%;
    width: 100%;
    margin: 1.5% auto;
    background: transparent;
    text-align: center;
}

.track-content > img {
    height: 90%;
    width: 90%;
    object-fit: contain;
}



/* 横画面のタブレット */
@media only screen and (max-width: 1366px) and (orientation: landscape) {}

/* 縦画面のタブレット */

@media only screen and (max-width: 1366px) and (orientation: portrait) {}
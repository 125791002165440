/* チャート */
.timeComparison-chart-container {
    position: relative;
    width: 86vw;
    height: calc(100vh - 296px);
    margin-top: 80px;
}

/* チャートの背景 */
.timeComparison-chart-container .map-background {
    position: absolute;
    top: -5vw;
    left: -20vw;
    z-index: -1;
    width: calc(100% + 32vw);
    height: calc(100% + 9vw);
}

/* チャートの本体*/
.timeComparison-flex-container

/* .lapTime-flex-container */
    {
    display: flex;
    align-items: center;
    position: relative;
    width: 64vw;
    height: 80%;
    margin-left: auto;
    justify-content: space-between;
}

/* フォローボックス */
.timeComparison-chart-container div .css-1jmwgce-MuiFormControl-root {
    margin: 0;
    margin-left: 6vw;
    padding: 0;
}

/* 横スケールber 全体 */
.timeComparison-flex-container .css-4kwz88-MuiStack-root {
    justify-content: center;
    align-items: center;
    height: 96%;
    margin-top: auto;
}

/* 横スケールber 全体 Gapテキスト */
.timeComparison-flex-container .stack-text {
    display: block;
    position: relative;
    transform: rotate(-90deg);
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 24px;
}

.timeComparison-flex-container .stack-text .stack-text-dec {
    position: absolute;
    top: 16px;
    left: 0;
    width: 200%;
    margin: 0;
    padding: 0;
}

.timeComparison-flex-container .stack-text .stack-text-dec::before {
    display: block;
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    content: '';
}

.timeComparison-flex-container .stack-text .stack-text-dec::after {
    display: block;
    position: absolute;
    top: 13px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.timeComparison-flex-container .stack-text .stack-text-dec li {
    display: inline-block;
    transform: skewX(320deg);
    width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #ffffff;
}

/* 横スケールber 全体 Gapテキスト　ここまで */

/* 横スケールber 全体 矢印 */
.timeComparison-flex-container .flex {
    display: flex;
    flex-direction: column;
    text-align: center;
}

/* 横スケールber 　ボックス */
.timeComparison-flex-container .css-qx5f9e-MuiSlider-rail {
    width: 16px;
    border: solid 1px #ffffff;
    border-radius: 0;
    background: transparent;
}


/* 横スケールber 　有効エリア */
.timeComparison-flex-container .css-ada0pd-MuiSlider-track {
    width: 16px;
    border-radius: 0;
    background: repeating-linear-gradient(45deg, #4affff, #4affff 5px, transparent 5px, transparent 10px);
    border: none;
}

.timeComparison-flex-container .css-y35fuw-MuiSlider-thumb,
.timeComparison-flex-container .css-y35fuw-MuiSlider-thumb {
    width: 24px;
    height: 8px;
    border-radius: 0;
    background-color: #4affff;
    color: transparent;
}

/* 横スケールber 全体ここまで */


/* 下のスケールber */
.stack-slider-flex {
    display: flex;
    align-items: center;
    flex-direction: row !important;
    position: absolute;
    width: 100%;
    margin-right: 0;
    right: 0;
    bottom: -30px;

    -webkit-flex-direction: row !important;
}

/* 全体のラップ表のみ下のスケールber */
.lapTime-container .stack-slider-flex {
    justify-content: end;
}

/* 矢印 */
.stack-slider-flex .landscape {
    margin-top: 0;
}

.timeComparison-chart-container .flex.updown.landscape,
.lapTime-container .flex.updown.landscape {
    flex-direction: row-reverse;
    column-gap: 10px;
}

.timeComparison-chart-container .flex.updown img,
.lapTime-container .flex.updown img {
    width: 17px;
    height: 17px;
    margin-bottom: 5px;
}

.timeComparison-chart-container .flex.updown.landscape img,
.lapTime-container .flex.updown.landscape img {
    margin-bottom: 0;
    padding-top: 5px;
}

.timeComparison-chart-container .flex.updown img:hover,
.lapTime-container .flex.updown img:hover,
.timeComparison-chart-container .flex.updown.landscape img:hover,
.lapTime-container .flex.updown.landscape img:hover {
    cursor: pointer;
}

/* 矢印ここまで */

/* 下のプログレスバー */
.stack-slider-flex .css-187mznn-MuiSlider-root {
    margin: 0;
    padding: 0;
}

.stack-slider-flex .css-14pt78w-MuiSlider-rail {
    height: 16px;
    margin: 0;
    border: solid 1px #ffffff;
    border-radius: 0;
    background: transparent;

}

.stack-slider-flex .css-1gv0vcd-MuiSlider-track {
    height: 16px;
    border-radius: 0;
    background: repeating-linear-gradient(45deg, #4affff, #4affff 5px, transparent 5px, transparent 10px);
    border: none;
}

.stack-slider-flex .css-eg0mwd-MuiSlider-thumb {
    width: 8px;
    height: 24px;
    border-radius: 0;
    background-color: #4affff;
    color: transparent;
}

/* 「Lap」の文字 */
.stack-slider-flex .stack-text {
    display: block;
    position: relative;
    margin-right: 72px;
    font-weight: bold;
    font-size: 24px;
    transform: rotate(0deg);
}

.stack-slider-flex .stack-text .stack-text-dec {
    position: absolute;
    top: 16px;
    left: 0;
    width: 200%;
    margin: 0;
    padding: 0;
}

.stack-slider-flex .stack-text .stack-text-dec::before {
    display: block;
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    content: '';
}

.stack-slider-flex .stack-text .stack-text-dec::after {
    display: block;
    position: absolute;
    top: 13px;
    right: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.stack-slider-flex .stack-text .stack-text-dec li {
    display: inline-block;
    transform: skewX(320deg);
    width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #ffffff;
}

/* 「Lap」の文字　　ここまで */

/*スクロールバー非表示（Chrome・Safari）*/
.timeComparison-flex-container .legend-container::-webkit-scrollbar {
    display: none;
}

/* チャート横の選手名表 */
.timeComparison-flex-container .legend-container {
    position: absolute;
    top: 1vw;
    left: -16vw;
    width: 16vw;
    height: 88%;
    overflow-y: scroll;
    border-top: solid 2px #ffffff;
    border-bottom: solid 2px #ffffff;
    background: rgba(175, 231, 255, .2);
}

/* チャート非表示時に横線を引く */
.legend-hidden {
    text-decoration: line-through;
}

.timeComparison-flex-container .legend-container::-webkit-scrollbar {
    display: none;
}

.timeComparison-flex-container .legend-container .player-list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.timeComparison-flex-container .legend-container .player-boxs {
    width: 100%;
    height: 100%;
}


.timeComparison-flex-container .legend-container .player-list li {
    display: flex;
    padding: 4% 0;
    margin-left: 16px;
    white-space: nowrap;
}


.timeComparison-flex-container .legend-container .driver-color {
    padding: 0 8px;
    border-radius: 3px;
    font-weight: bold;
    margin-right: 8px;
}

.timeComparison-flex-container .css-4kwz88-MuiStack-root {
    height: 100%;
}

.timeComparison-legend-circles {
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    background-color: #000;
    margin-right: 5px;
}

/* チャート横の選手名表 */


@media only screen and (max-width: 1366px) and (orientation: landscape) {

    /* チャート */
    .result_container>.timeComparison-chart-container {
        width: 100vw;
        height: 80vh;
    }

    /* チャート　下のスケールberのCSS */
    .stack-slider-flex {
        margin-right: 8%;
    }
}


/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {

    /* チャート */
    .timeComparison-chart-container {
        margin-top: 32px;
        margin-bottom: 32px;
    }

    /* チャートの背景 */
    .timeComparison-chart-container .map-background {
        left: -29vw;
        height: calc(100% + 12vw);
    }

   /* チャート横の選手名表 */
    .timeComparison-flex-container .legend-container {
        top: 1vw;
        left: -28vw;
        width: 22vw;
    }


    .timeComparison-chart-container div .css-1jmwgce-MuiFormControl-root {
        margin-left: 2vw;
    }

    .timeComparison-chart-container .flex.updown.landscape {
        margin-left: 32px;
    }

    /* チャート */

    .result_container>.timeComparison-chart-container {
        height: 100vh;
    }

    .timeComparison-flex-container {
        position: relative;
        width: 64vw;
        height: 32vh;
        margin-left: 24vw;
    }

    .timeComparison-flex-container .legend-container {
        position: absolute;
        top: 1vw;
        left: -34%;
        width: 24vw;
        height: 32vh;
        overflow-y: scroll;
        border-top: solid 2px #ffffff;
        border-bottom: solid 2px #ffffff;
    }

    .timeComparison-flex-container .legend-container .player-list {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 14px;
    }

    .timeComparison-flex-container .legend-container .player-list li {
        display: flex;
        padding-bottom: 8%;
        margin-right: 0;
    }

    .timeComparison-flex-container .legend-container .driver-color {
        padding: 0 8px;
        border-radius: 3px;
        font-weight: bold;
    }

    .timeComparison-chart-container .stack-slider-flex {
        justify-content: end;
        width: 90%;
        margin-left: auto;
    }

    .timeComparison-flex-container .css-4kwz88-MuiStack-root {
        height: 100%;
    }

    .timeComparison-flex-container .stack-text .stack-text-dec {
        top: 8px;
    }

    .timeComparison-chart-container .map-background {
        display: none;
    }

    .timeComparison-flex-container .stack-text,
    .stack-slider-flex .stack-text {
        margin-bottom: 8px;
        font-size: 16px;
    }

    .timeComparison-flex-container .stack-text .stack-text-dec li,
    .stack-slider-flex .stack-text .stack-text-dec li {
        width: 4px;
        height: 4px;
    }

    .stack-slider-flex .stack-text .stack-text-dec::before {
        top: 10px;
    }

    .stack-slider-flex .stack-text .stack-text-dec::after {
        top: 7px;
    }

    .timeComparison-flex-container .stack-text .stack-text-dec::before {
        top: 12px;
    }

    .timeComparison-flex-container .stack-text .stack-text-dec::after {
        top: 9px;
    }

}

@media only screen and (max-width: 932px) and (orientation: landscape) {

    .timeComparison-chart-container div .css-1jmwgce-MuiFormControl-root {
        margin-left: 0;
    }

    .stack-slider-flex {
        justify-content: end;
    }

    .timeComparison-flex-container .stack-text {
        transform: scale(.8) rotate(-90deg);
    }


    .stack-slider-flex .stack-text {
        transform: scale(.8) rotate(0deg);
        margin-right: 40px;

    }

}
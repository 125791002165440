.inputTB40px {
    width: 50px;
}

.inputTB60px {
    width: 60px;
}

.inputTB70px {
    width: 70px;
}

.textareaWidth {
    width: 200px;
}

.dottedBottom {
    border-bottom: 2px dotted;
}

.dottedRight {
    border-right: 2px dotted;
}

.trBottom, tr.trBottom td {
    border-bottom: 15px solid rgba(0, 0, 0, 0);
}

.createButton {
    width: 60px;
}
@charset "UTF-8";

/* トップページの上下装飾 */
.top-page-dec-top, .top-page-dec-bottom {
    display: block;
    width: 90vw;
    max-width: 1760px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
}
.top-page-dec-top {
    margin: 32px auto 0;
    background-image: url(./strategy/image/login-form-dec-top.png);
}

.top-page-dec-bottom {
    margin: 0 auto 32px;
    background-image: url(./strategy/image/login-form-dec-bottom.png);
}
/* トップページの上下装飾 ここまで*/

/* トップページ */
.top-page-container {
    display: flex;
    align-items: center;
    height: calc(100vh - 136px);
    margin: 0 auto;
    font-family: "Saira Condensed", sans-serif;
    vertical-align: middle;
}

.top-page-container .buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    position: relative;
    width: 80vw;
    max-width: 1760px;
    height: 72vh;
    max-height: 776px;
    margin: 0 auto;
    border: solid 2px #ffffff;

    background-color: transparent;
    background-image: radial-gradient(rgba(255, 255, 255, .2) 4%, transparent 10%);
    background-size: 11px 10px;

    gap: 80px;
}

/* ロゴのサイズ */
.top-page-container .buttons-container img {
    width: 64px;
}

/* ボタンエリアの装飾 */
.top-page-container .dli-plus:first-of-type {
    top: -7px;
    left: -16px;
}

.top-page-container .dli-plus:nth-of-type(2) {
    top: -7px;
    right: -16px;
}

.top-page-container .dli-plus:nth-of-type(3) {
    bottom: -7px;
    left: -16px;
}

.top-page-container .dli-plus:nth-of-type(4) {
    right: -16px;
    bottom: -7px;
}
/* ボタンエリアの装飾 ここまで*/

/* ボタンボックス */
.top-page-container .buttons-container .button-boxs {
    display: flex;
    width: auto;
    height: auto;
    margin: 0;

    gap: 80px;
}

/* ボタン */
.top-page-container .buttons-container .button-boxs .button {
    position: relative;
    width: 297px;
    height: 56px;
    margin: 0;
    border: 2px solid #ffffff;
    background: #ffffff;
    color: #000000;
    font-weight: bold;
    font-size: 24px;
    line-height: 58px;
    letter-spacing: 8px;
    text-align: center;
}

.top-page-container .buttons-container .button-boxs .button:hover {
    border: 2px solid #4affff ;
    box-shadow: 0 0 4px rgba(255, 255, 255, .7) ;
    background-color: transparent ;
    color: #4affff;
    /*text-shadow: 0 0 4px rgba(255, 255, 255, .7) ;*/
    cursor: pointer ;
}


/* ボタンの装飾*/
.button-boxs .button span {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: solid 2px #ffffff;
    border-left: solid 2px #ffffff;
    content: "";
}

.top-page-container .buttons-container .button-boxs .button:hover span {
    border-top: solid 2px #4affff;
    border-left: solid 2px #4affff;
}

.top-page-container .button .top-page-dec-top-left {
    top: -8px;
    left: -8px;
}

.top-page-container .button .top-page-dec-top-right {
    top: -8px;
    right: -8px;
    transform: rotate(90deg);
}

.top-page-container .button .top-page-dec-bottom-left {
    bottom: -8px;
    left: -8px;
    transform: rotate(-90deg);
}


.top-page-container .button .top-page-dec-bottom-right {
    right: -8px;
    bottom: -8px;
    transform: rotate(180deg);
}

/* ボタンの装飾　ここまで*/


/* B5サイズ ipad横　 */
@media only screen and (max-width: 1366px) {
    .top-page-container {
        width: 90vw;
    }
    .top-page-container .buttons-container {
        width: 88vw;
        max-width: none;
        height: 48vw;

        gap: 8%;
    }

    .top-page-container .buttons-container .button-boxs {
        gap: 8%;
    }

    .top-page-container .buttons-container .button-boxs .button {
        width: 24vw;
        letter-spacing: 0;
    }
}

/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .top-page-container {
        width: 90vw;
    }
    .top-page-container .buttons-container {
        width: 88vw;
        max-width: none;
        height: 72vw;
        background-color: transparent;
        background-image:none;
        border: none;

        gap: 8%;
    }

    .dli-plus{
        display: none;
    }

    .top-page-container .buttons-container .button-boxs {
        gap: 8%;
    }

    .top-page-container .buttons-container .button-boxs .button {
        width: 24vw;
        letter-spacing: 0;
    }
}

@media only screen and (max-width: 743px) and (orientation: portrait) {
    .top-page-container .buttons-container {
        width: 80vw;
        height: 64vh;
        padding: 16px 0;
        border: none;

        gap: 0;
    }

    .top-page-container .buttons-container .button-boxs {
        display: block;
        margin: 0;
    }
    .top-page-container  img {
        width: 64px;
        margin-bottom: 6vh;
    }

    .top-page-container .button-boxs:first-of-type {
        margin-bottom: 6vh;
    }

    .top-page-container .buttons-container .button-boxs .button {
        width: 64vw;
        height: 48px;
        margin-bottom: 6vh;
        line-height: 48px;
    }

    .top-page-container .buttons-container .button-boxs .button:last-of-type {
        margin-bottom: 0;
    }
    .top-page-dec-top {
        display: none;
    }

    .top-page-dec-bottom {
        display: none;
    }
}

/* 横画面のスマホ版 */
@media only screen and (max-width: 932px) and (orientation: landscape) {
    /* トップページの上下装飾 */

    .top-page-dec-top {
        margin: 4% auto 0;
    }

    .top-page-dec-bottom {
        margin: 0 auto 4%;
    }

    /* トップページの上下装飾 ここまで*/

    .top-page-container {
        height: calc(100vh - 8%);
    }

    /* トップページ */
    .top-page-container .buttons-container {
        height: 32vw;
    }
    /* ボタン */
    .top-page-container .buttons-container .button-boxs .button {
        height: 40px;
        line-height: 40px;
    }

    .top-page-container .dli-plus:first-of-type, .top-page-container .dli-plus:nth-of-type(2), .top-page-container .dli-plus:nth-of-type(3), .top-page-container .dli-plus:nth-of-type(4) {
        display: block;
    }
}
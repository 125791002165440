/* Admin form */
.main-wrap {
    max-width: 1360px;
    margin: 0 auto;
    padding: 16px;
}

.admin-form-small h2 {
    width: 439px;
    height: 98px;
    margin: 0 0 64px 0;
    background-image: url(../image/admin-tit-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    line-height: 98px;
    text-align: center;
}

.admin-form-small input {
    color: #ffffff;
}

.admin-form-small .form-text {
    position: absolute;
    top: -30%;
    left: 5%;
    z-index: 2;
    background-color: #242424;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
}


.admin-form-small .form-text span {
    color: #4affff;
}

.admin-form-small input:focus {
    border: solid 2px #4affff ;
    outline: none;
}

/* 自動補完された際のスタイル */
.admin-form-small input:-webkit-autofill {
    box-shadow: 0 0 0 100px rgba(36, 36, 36, 1) inset;
    -webkit-text-fill-color: white;
}

.admin-form-small select:focus {
    border: solid 2px #4affff ;
    outline: none;
}

.admin-form .form-boxs select {
    position: relative;
    width: 300px;
    height: 56px;
    padding: 5px;
    border: solid 2px #ffffff;
    box-sizing: border-box;
    background: transparent;
    color: #ffffff;

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.admin-form .from-select::before {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';

    pointer-events: none;
}

.admin-form-small a {
    text-align: end;
}

.dash-link {
    position: relative;
    width: 168px;
    height: 48px;
    background: #ffffff;
    color: #000000;
    font-weight: bold;
}

.dash-link span, #scraping-start span, #scraping-stop span, .edits-area .race-edits button span {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: solid 2px #ffffff;
    border-left: solid 2px #ffffff;
    content: '';
}

.dash-link .top-page-dec-top-left, #scraping-start .top-page-dec-top-left, #scraping-stop .top-page-dec-top-left, .edits-area .race-edits button .top-page-dec-top-left {
    top: -8px;
    left: -8px;
}

.dash-link .top-page-dec-top-right, #scraping-start .top-page-dec-top-right, #scraping-stop .top-page-dec-top-right, .edits-area .race-edits button .top-page-dec-top-right {
    top: -8px;
    right: -8px;
    transform: rotate(90deg);
}

.dash-link .top-page-dec-bottom-left, #scraping-start .top-page-dec-bottom-left, #scraping-stop .top-page-dec-bottom-left, .edits-area .race-edits button .top-page-dec-bottom-left {
    bottom: -8px;
    left: -8px;
    transform: rotate(-90deg);
}


.dash-link .top-page-dec-bottom-right, #scraping-start .top-page-dec-bottom-right, #scraping-stop .top-page-dec-bottom-right, .edits-area .race-edits button .top-page-dec-bottom-right {
    right: -8px;
    bottom: -8px;
    transform: rotate(180deg);
}

.admin-title {
    margin: 0 0 30px;
    margin: 0;
}
.admin-form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: relative;

    gap: 20px;
}

.admin-form a {
    width: fit-content;
}

.admin-form-big {
    display: flex;
    justify-content: center;

    gap: 100px;
}

.admin-form-small {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 3rem;

    gap: 10px;
}

input[type='date'] {
    padding: 0 5px;
    color: #ffffff;
    font-family: FontAwesome;
    content: '\f017';
}

.scrapping-now {
    color: #adff2f;
}

.form-boxs {
    display: flex;
    justify-content: space-between;
}

.form-boxs select {
    border: 1px solid #767676;
    background: #ffffff;
    color: #000000;
}

.form-input {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: relative;
    margin-bottom: 30px;
}

textarea {
    width: 300px;
    padding: 5px;
    box-sizing: border-box;
    resize: none;
}

.btn-area {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
}

.btn-area .action-area {
    display: flex;
    color: #ffffff;

    gap: 80px;
}

#scraping-start {
    position: relative;
    width: 232px;
    height: 48px;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
}

#scraping-stop {
    position: relative;
    width: 232px;
    height: 48px;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
}

#scraping-edit {
    border: 2px;
    color: #000000;
}

.edits-area {
    display: flex;

    gap: 56px;
}

.edits-area .race-edits {
    display: flex;

    gap: 30px;
}

.edits-area .race-edits button {
    position: relative;
    width: 240px;
    height: 40px;
    padding: 0;
    border-radius: 0;
    background-color: #ffffff;
    color: #000000;
}

/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .admin-form-small h2{
        width: auto;
        margin: 4%;
    }

    .form-boxs{
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
    }

    #scraping-start{
        width: 40%;
    }

    .admin-form .form-boxs select{
        width: 100%;
    }

    .edits-area{
        flex-direction: column;
    }
}
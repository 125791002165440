.race-mana-vehicle-info-top-container {
    display: flex;
    flex-direction: column;
    min-width: 1600px;
}

.race-mana-vehicle-info-outer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.race-mana-vehicle-info-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.race-mana-vehicle-information {
    width: 300px;
}

.race-mana-notes {
    min-height: 400px;
    margin-bottom: 50px;
}
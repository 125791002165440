.time-series-header {
    display: flex;
}

.time-series-file-header {
    display: flex;
}

.time-series-chart {
    height: 100%;
    width: 90%;
    margin: auto;
}

.dropdown-content .dropdown-option,
.lap-checkbox-label {
    padding: 6px;
    text-decoration: none;
    text-align: center;
    display: block;
    /* color: #fff; */
    font-size: 0.9rem;
    /* -webkit-text-stroke-width: 0.1px;
    -webkit-text-stroke-color: rgba(92, 237, 228, 0.5);
    text-shadow: rgba(255,255,255,0.4) 0 1px 5px; */
    cursor: pointer;
}

.lap-checkbox-label {
    text-align: left;
    padding: 0;
    display: inline-block;
}
.lap-checkbox-label.dropdown-option-best {
    color: #BC1414;
    font-weight: bold;
    border-bottom: 2px solid #BC1414;
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: transparent;
}

/* NEW */
.time-series-side-menu {
    height: 100%;
    width: 290px;
    position: relative;
}

.time-series-button-container {
    display: flex;
    height: 4%;
    width: 50%;
    z-index: 1;
    position: relative;
    margin: 1% 0 0 1%;
}

.action-button-container.view-buttons {
    width: 15%;
    position: absolute;
    right: 1%;
    top: 3%;
    justify-content: space-around;
    margin: 0;
}

.time-series-file-container {
    height: 100% ;
    width: 290px;
    background: transparent;
    color: #000;
    font-family: 'Noto Sans JP', sans-serif;
}

.time-series-file-container .button-container {
    display: flex;
    width: 100%;
    height: 4%;
    cursor: pointer;
    justify-content: flex-start;
    margin-bottom: 5%;
    gap: 8px;
}

.time-series-file-button,
.time-series-chart-button {
    height: 100%;
    width: 32%;
    font-family:'Noto Sans JP', sans-serif;
    font-size: 11px;
    text-align: center;
    line-height: 31px;
    background: url(../image/button-container.png) no-repeat center center / 100% 100%;
    cursor: pointer;
    font-weight: 400;
    color: #FFFFFF;
}

.time-series-file-tab-continer{
    display: flex;
    position: relative;
}

.time-series-file-tab{
    height: 32px;
    width: 136px;
    position: relative;
    font-size: 11px;
    line-height: 32px;
    clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
    background-color: #83939A;
    color: #fff;
    border: solid 1px #fff;
    cursor: pointer;
    z-index: 2;
}
.time-series-file-tab:last-of-type{
    position: absolute;
    right: 28px;
    z-index: 1;
}

.time-series-file-tab::after{
    content: '';
    display: block;
    width: 1px;
    height: 129px;
    position: absolute;
    top: -21px;
    right: -10px;
    background: #fff;
    transform: rotate(-40deg);
}

.active-tab{
    background-color: #222F3F;
}


.data-file-container,
.data-channel-container {
    height: 53%;
    width: 100%;
    overflow: auto;
    margin-bottom: 2%;
    background: rgba(86, 113, 125, 0.4);
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
}

.data-file-channel-header-container {
    background: #222F3F;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    padding-left: 20px;
    position: sticky;
    top: 0;
    z-index: 1;
}

.data-file-channel-header-container > div {
    font-size: 20px;
}

.data-file-channel-header-container > input {
    width: 80%;
    border: 1px solid;
    border-radius: 50px;
    outline: none;
    color: #FFFFFF;
    background: rgba(175, 231, 255, 0.2);
    margin: 3% 0;
    padding: 5px 0 5px 10px;
    font-size: 10px;
    position: relative;
}

.data-file-channel-header-container > input::placeholder {
    color: #FFFFFF;
    opacity: 0.9;
}

.data-file-content {
    font-size: 1.1rem;
    cursor: pointer;
}

.data-file-content:nth-child(even),
.time-series-channel-container:nth-child(even) {
    background: rgba(175, 231, 255, 0.2);
}

.data-file-content:nth-child(odd),
.time-series-channel-container:nth-child(odd) {
    background: rgba(86, 113, 125, 0.4);
    margin: 4px 0;
}

.selected-time-series {
    background: rgba(74, 255, 255, 0.5);
    border-bottom: 1px solid #4AFFFF;
    box-shadow: 0px 1px 4px #4AFFFF;
    color: #fff;
}

.data-file-checkbox-div {
    padding: 3px 0 0 6px;
}

.data-file-checkbox-div input[type='checkbox'] {
    background-color: transparent;
    border: 1px solid #4AFFFF;
    width: 16px;
    height: 16px;
}

.data-file-checkbox-div input[type='checkbox']:checked::after {
    border: 1px solid #4AFFFF;
    border-width: 0px 0px 3px 3px;
    width: 7px;
    top: 40%;
}

.time-series-channel-container {
    display: flex;
    cursor: pointer;
}

.time-series-count-checker {
    margin: 0 5% 0 5%;
}

.file-info-container,
.channel-info-container {
    height: 34%;
    width: 100%;
    color: #000;
    font-size: 0.8rem;
    overflow: auto;
    background: rgba(86, 113, 125, 0.4);
    border: 1px solid #FFFFFF;
}

.data-file-container::-webkit-scrollbar,
.data-channel-container::-webkit-scrollbar,
.file-info-container::-webkit-scrollbar-track,
.channel-info-container::-webkit-scrollbar-track {
    border: 1px solid #FFFFFF;
    width: 8px;
}

.data-file-container::-webkit-scrollbar-thumb,
.data-channel-container::-webkit-scrollbar-thumb,
.file-info-container::-webkit-scrollbar-thumb,
.channel-info-container::-webkit-scrollbar-thumb {
    background: rgba(74, 255, 255, 1);
    border: 2px solid rgba(86, 113, 125, 0.4);
    border-radius: 10px;
}

.data-info {
    padding: 5px 0 3px 15px;
}

.data-info-title {
    font-size: 16px;
    color: #4AFFFF;
    margin: 0 5px 0 0;
}

.data-info-content {
    margin-left: 5%;
}

.channel-info-div {
    margin: 0 0 5px 5px;
    margin: 5px 15px;
    color: #4AFFFF;
}

.channel-info-title {
    color: #4AFFFF;
    cursor: pointer;
    margin: 5px 15px;
    font-size: 16px;
}

.channel-info-div .channel-info-title {
    color: #FFFFFF;
}

.channel-info-div select {
    color: #000000;
}

/* Chart container */
.time-series-result-container {
    width: 84%;
    height: calc(100% - 8px);
    background: rgba(86, 113, 125, 0.4);
    border: 1px solid;
}

/* .time-series-chart-container {
    height: 93%;
    margin-top: 1%;
} */

.react-grid-layout {
    height: 100% !important;
}

.time-series-chart-button {
    width: 16%;
    margin: 0 0.5% 0 0;
    font-size: 13px;
}

.time-series-chart-container button {
    width: unset;
    padding: unset;
}

.chart-container {
    border: 1px solid #FFFFFF;
    box-shadow: 0 0 4px #FFF, 0 0 4px #FFF inset;
    width: 100%;
    height: 100%;
    background: rgba(86, 113, 125, 0.4);
}

.chart-container .canvas-container {
    width: 95%;
    height: 78%;
    margin: auto;
    position: relative;
}

.chart-container.selected-graph {
    border: 1px solid #AFE7FF;
    box-shadow: 0 0 4px rgba(74, 255, 255, 0.6), 0 0 4px rgba(74, 255, 255, 0.6) inset;
}

.chart-container .display-flex {
    height: 80%;
    justify-content: flex-start;
}

.time-checker-div {
    z-index: 2;
    position: relative;
    cursor: pointer;
    width: 30%;
    margin: 2% 0 0 2%;
}

.time-checker-div input,
.time-checker-div label {
    cursor: pointer;
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 16px;
}

.time-checker-div input[type='radio'] {
    appearance: none;
    border: 1px solid #4AFFFF;
    border-radius: 50px;
    width: 16px;
    height: 16px;
    margin: 3px 5px 0 15px;
    position: relative;
}

.time-checker-div input[type='radio']::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid transparent;
    opacity: 0;
    display: block;
    position: absolute;
    top: 1.3px;
    left: 1.3px;
    background: #4AFFFF;
}

.time-checker-div input[type='radio']:checked::before {
    opacity: 1;
}

.remove-graph-button {
    height: 24px;
    width: 24px;
    float: right;
    margin: 2% 2% 0 0;
    cursor: pointer;
    z-index: 1;
    position: relative;
    background: url(../image/close-button.png) no-repeat center center / 100% 100%;
}

.chart-container canvas {
    position: absolute;
    top: 0;
}

.react-grid-placeholder {
    background: transparent !important;
}

.MuiSlider-root {
    width: 80% !important;
    margin: 0 10%;
}

.display-flex > .MuiSlider-root {
    width: 2% !important;
    margin: unset;
    padding: 0 2px 0 13px;
}

/* Add data modal */
.input-content {
    display: flex;
    margin-bottom: 2%;
    font-size: 20px;
}

.input-title-div {
    width: 20%;
    margin: 0 10%;
}

.input-div {
    width: 60%;
}

.input-div input {
    width: 80%;
    height: 40px;
    background: transparent;
    border: 1px solid #FFFFFF;
    outline: none;
    color: #FFFFFF;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 15px;
}

.settings-input select {
    width: 80%;
    height: 40px;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
}

.settings-input input[type='file'] {
    display: none;
}

.file-path-input-label {
    width: 81%;
    display: flex;
}

.file-path-input-label .file-path-button {
    width: 40%;
    background: rgba(86, 113, 125, 0.3);
    border: 1px solid #FFFFFF;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.file-path-input-label .file-path-text {
    width: 65%;
    border-bottom: 1px solid #FFFFFF;
    margin-left: 2%;
    text-align: center;
    pointer-events: none;
}

.settings-input select:focus {
    box-shadow: unset;
    outline: none;
}

.modal-button-container {
    height: 50px;
    width: 70%;
    display: flex;
    justify-content: flex-end;
    margin: 3% auto;
}

.modal-button {
    width: 20%;
    height: 70%;
    line-height: 33px;
    padding: 3px;
    text-align: center;
    cursor: pointer;
    color: #fff;
    margin: 0 0 0 2%;
    background: url(../image/button-container.png) no-repeat center center / 100% 100%;
}

.dialog-title.long {
    font-size: 34px;
}

.dialog-title.long::after {
    width: 300px;
}

.time-series-container-modal {
    color: #FFFFFF;
    width: 50%;
    margin: 1% 0 2% 39%;
}

/* .select-container.color-black select {
    color: #000;
} */
.row {
    display: flex;
    margin-right: -5px;
    margin-left: -5px;
}

.column {
    flex: 50%;
    padding: 5px;
}

.label {
    width: 160px;
    text-align: center;
}

.unit {
    width: 110px;
    text-align: center;
}

.textAlign {
    text-align: left;
}

.textAlignCenter {
    text-align: center;
}

.bottomBold {
    border-bottom: 2px solid;
}

.colorBlack {
    opacity: 0;
}

/* SimulationCircuitInformation */
.infoColumn1 {
    width: 180px;
}

/* SimulationCircuitTable */
.emptyCol {
    width: 40px;
}

.selectOptionWidth {
    width: 210px;
}

.inputText {
    width: 80px;
}

.inputNumber {
    width: 60px;
}

.options-container {
    display: flex;
}

.graph-container {
    display: flex;
    align-items: center;
    position: relative;
}

.border-line {
    border: 2px solid white;
}
.toggle-input {
    display: none;
  }
  
  /* Toggle label styles */
  .toggle-label {
    display: inline-block;
    cursor: pointer;
    padding: 10px;
    background-color: #dddddd;
    color: #000000;
    border-radius: 20px;
  }
  
  /* When the toggle is checked, change to dark mode */
  .toggle-input:checked + .toggle-label {
    background-color: #333333;
    color: #ffffff;
  }

/* ========== Live ========== */

body.light-mode{
    background-color: #fff;
}

.light-mode .live-table-header {
    background-color: rgba(76, 98, 126, 1);
}

.light-mode .side-menu-time::before, 
.light-mode .side-menu-time::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    box-shadow: 0 0 #222F3F;
    background-color: #222F3F;
    content: '';
}

.light-mode .display-value.double-border::before, 
.light-mode .display-value.double-border::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    box-shadow: 0 0 #222F3F;
    background-color: #222F3F;
    content: '';
}

.light-mode .side-menu-time::after,
.light-mode .display-value.double-border::after {
    bottom: -6px;
}


.light-mode .live-table .live-table-header tr th {
    color: white;
}

/* .light-mode .container_dashboard .result_container.live tbody td {
    color: #222F3F;
} */

.light-mode .live-table .name-and-number {
    color: #fff;
}

.light-mode .live-table .best-lap-display {
    color: #fff;
}

.light-mode .css-qqpl6e-MuiTableRow-root{
    background-color: #fff;
}

/* ジョミルさん対応dashborad.css 1398行〜移行出来ていない。(移行SP版後ほど) */


.light-mode .separator-line li {
    background: #222F3F
}


/* dashboard.css から */

.light-mode .sidemenu-weather-card{
    background-image: linear-gradient(rgba(34, 47, 63, 1) 0%, rgba(87, 107, 115, .5) 4%, rgba(87, 107, 115, .5) 97%, rgba(34, 47, 63, 1) 100%);
}

.light-mode .map-container {
    position: relative;
    width: 550px;
    width: 470px;
    height: 88vh;
    margin: 20px 3% 0;
    background: center center / 100% 100%  no-repeat url('../image/map-container_live_light.png');
}

.light-mode .map-container{
    color: #222f3f;
}

.light-mode .map-container-medium {
    width: 550px;
}

.light-mode .map-container.map-container-medium p {
    left: 107px;
}

.light-mode .map-container.map-container-medium .predict-container {
    top: 550px;
}

.light-mode .map-container.map-container-medium .pit-info-container {
    top: 650px;
}

.light-mode .map-container.map-container-medium .map_option_container::after {
    bottom: -2px;
}

.light-mode .map-container.map-container-medium .map_option_container {
    top: 580px;
}


.light-mode .map-container-large {
    width: 610px;
    height: 850px;
}

.light-mode .map-container.map-container-large p {
    left: 120px;
}

.light-mode .map-container.map-container-large .pit-info-container {
    top: 685px;
}

.light-mode .map-container.map-container-large .map_option_container::after {
    bottom: 3px;
}

.light-mode .map-container.map-container-large .map_option_container {
    top: 627px;
}

.light-mode .one-screen-map {
    width: 90vw;
    height: 100vh;
    overflow-y: scroll;
    background: center center / 100% 100%  no-repeat url('../image/map-container.png');
    position: absolute;
    left: calc(100vw - 95%);
    max-height: calc(100vh - 296px);
}

.light-mode .ots-limit-bar-container{
    border: 1px solid #222F3F;
    background-color: rgba(211, 242, 255, .6);
}

.light-mode .ots-limit-bar-container.ots-max-used .ots-limit-text {
    color: #222F3F
}

.light-mode .slider.square:before {
    border-radius: 10%;
    background-color: #222F3F;
}

.light-mode .dark img{
    filter: invert(16%) sepia(11%) saturate(1935%) hue-rotate(176deg) brightness(10%) contrast(86%);
    opacity: 0.3;
}

.light img{
    height: 90%;
    width: 90%;
    opacity: 0.4;
}

.light-mode .light img{
    filter: invert(10%) sepia(17%) saturate(5669%) hue-rotate(152deg) brightness(111%) contrast(103%)
    drop-shadow(0px 0px 2px rgba(0, 0, 0, .2));
    opacity: 1;
}

@media only screen and (max-width: 1024px) and (orientation: portrait){

    .light-mode .result_container .mobile-header:first-of-type {
        display: flex;
        position: sticky;
        top: 0;
        width: 100vw;
        height: 4vh;
        margin-top: 32px;
        padding: 4px 48px;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 0 0 7px #ffffff;
        background-color: rgba(146, 168, 195, 1);
        font-weight: bold;
        font-size: 24px;
    }
}


@media only screen and (max-width: 743px) and (orientation: portrait) {

    .light-mode .live-table .table-name{
        color: #000;
    }
}

/* Modal.css から */

.light-mode .custom-modal {
    background-color: #ffffff;
    color: #222F3F;
}

.light-mode .top-dialog{
    color: #222F3F;
}

.light-mode .top-dialog{
    background-color: #ffffff;
}

.light-mode .top-dialog-background {
    position: relative;
    width: 100%;
    max-width: 1423px;
    height: 80px;
    margin: 0 auto 40px;
    background-image: url(../image/modal-header_light.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.light-mode .pace-catcher-pit {
    color: #222F3F;
}

.light-mode .pace-catcher-area .pit-example-box{
    border-bottom: #222F3F 1px solid;
    background-color: rgba(175,231,255,.4);
}

.light-mode .pace-catcher-table th{
    background-color: #ffffff;
    color: #222F3F;
}

.light-mode .pace-catcher-table th::before {
    border-bottom: 1px solid #222F3F;
}

.light-mode .pace-catcher-table td:first-child {
    color: #222F3F;
}

.light-mode .pace-catcher-area .switch-area-text{
    color: #222F3F;
}

.light-mode .stint-history-tit::before {
    display: block;
    width: 440px;
    height: 70px;
    background: url(../image/Stint-History-info_light.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}

.light-mode .stint-history-table {
    color: #222F3F;
}

.light-mode .stint-history-table::before {
    border-right: solid #222F3F .5px;
    border-left: solid #222F3F .5px;
}

.light-mode .stint-history-table::after {
    border-right: solid #222F3F .5px;
    border-left: solid #222F3F .5px;
}

.light-mode .tr-before-content {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #222F3F;
    content: '';
}

.light-mode .stint-history-table tr::after{
    background-color: #222F3F;
}

.light-mode .stint-history-table th::before {
    border-bottom: 1px solid #222F3F;
}

.light-mode .stint-history-table th{
    background-color: #ffffff;
    color: #222F3F;
}

.light-mode .stint-history-alltable td::before{
    border-bottom: 1px solid #222F3F;
}

.light-mode .all-laps-options label::before{
    background-color: #ffffff;
}

.light-mode .lap-history-select {
    border: 2px solid #222F3F;
    color: #222F3F;
}

.light-mode .car-driver-top-info .driver-info .driver-name-info h4{
    margin-bottom: 4px;
}

.light-mode .car-driver-top-info .driver-name-info {
    position: relative;
    width: 463px;
    max-width: 100%;
    height: 66px;
    background: url(../image/driver-name-info_light.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.light-mode .car-driver-top-info .driver-CarNo-info {
    background: url(../image/driver-carNo-info_light.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: end;
}

.light-mode .pit-stop-info .pit-stop-times-tit-bg {
    background-image: url(../image/pit-stop-times-tit_light.png);
    background-position: right 0 bottom 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

.light-mode .car-driver-top-info .pit-stop-times::before {
    background: #222F3F;
}

.light-mode .currentLap-info {
    background-image: url(../image/CurrentLap_light.png);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
}

.light-mode .pit-table td::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 68px;
    background-image: url(../image/lap-taimes_light.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
    content: '';
}

.light-mode .pit-table td::after{
    background-color: #222F3F;
}

/* timeComparisonChart.css から */

.light-mode .timeComparison-flex-container .stack-text {
    color: #222F3F;
}

.light-mode .timeComparison-flex-container .stack-text .stack-text-dec::before {
    background-color: #222F3F;
}

.light-mode .timeComparison-flex-container .stack-text .stack-text-dec::after {
    background-color: #222F3F;
}

.light-mode .timeComparison-flex-container .stack-text .stack-text-dec li {
    display: inline-block;
    transform: skewX(320deg);
    width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #222F3F;
}

.light-mode .timeComparison-flex-container .css-qx5f9e-MuiSlider-rail {
    border: solid 1px #222F3F;
}

.light-mode .timeComparison-flex-container .legend-container {
    border-top: solid 2px #222F3F;
    border-bottom: solid 2px #222F3F;
    background: rgba(34, 47, 63, .2);
}

.light-mode .timeComparison-flex-container .legend-container .player-list li {
    color: #222F3F;
}

/* DriverData */

.light-mode .driver-information .driver-name-base {
    background: url(../image/driver-name-info_light.png);
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}

.light-mode .driver-information .driver-name-info{
    color: #000000;
}

.light-mode .driver-information .driver-carNo-base {
    background: url(../image/driver-carNo-info_light.png);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    content: '';
}

.light-mode .driver-information .driver-carNo-info{
    color: #000000;
}

.light-mode .race-data .heatmap-example-table th, 
.light-mode .race-data .heatmap-example-table td, 
.light-mode .race-data .best-race-data-table th, 
.light-mode .race-data .best-race-data-table td {
    color: #000000;
}

.light-mode .totalLap-driver-table-cover {
    position: relative;
    background-image: url(../image/totalLap-driver-table-bg_light.png);
    background-size: auto;
    background-repeat: no-repeat;
}

.light-mode .totalLap-driver-table thead {
    position: sticky;
    top: 0;
    background-color: #B9C4C9;
    z-index: 1;
}


/* headerMenu.css から */

.light-mode .header-right-area{
    color: #242424;
}

.light-mode .header-left-area{
    color: #242424;
}

.light-mode .menu-buttons .square-btn {
    border: solid 2px rgba(34, 47, 63, 1);
    background-color:rgba(175, 231, 255, .2);
    color: #222F3F;
    box-shadow: none;
}

.menu-buttons .square-btn:hover .square-icon,
.light-mode .menu-buttons .square-btn:hover .square-icon {
    filter: invert(91%) sepia(17%) saturate(5669%) hue-rotate(152deg) brightness(111%) contrast(103%);
}

.light-mode .live-header .menu-buttons .square-btn img{
    filter: invert(16%) sepia(11%) saturate(1935%) hue-rotate(176deg) brightness(10%) contrast(86%);
}

/* lightModeの切り替えボタン */
.light-mode-switch-Box{
    display: flex;
    gap: 8px;
    margin-right: 8px;
}

.light-mode .circle-button {
    border: solid 2px #222F3F;
    background-color: rgba(175, 231, 255, .2);
    color: #222F3F;
    box-shadow: 0 2px 6px rgba(34, 47, 63, .6);
}

.light-mode .ellipse-button {
    border: solid 2px #222F3F;
    background-color: rgba(175, 231, 255, .2);
    color: #222F3F;
    box-shadow: 0 2px 6px rgba(34, 47, 63, .6);
}

.light-mode .circle-button img{
    filter: invert(16%) sepia(11%) saturate(1935%) hue-rotate(176deg) brightness(10%) contrast(86%);
}

.light-mode .css-1iaf5qg-MuiPaper-root-MuiTableContainer-root{
    background-color: #CDD5E0;
}

.light-mode .top-view-mode-container{
    background-color: #CDD5E0;
    color: #222F3F;
}

.light-mode .view-mode-div{
    background-color: #CDD5E0;
}

.light-mode .view-mode-div table tr{
    background-color: #CDD5E0;
}

.light-mode .view-mode-container-upper .css-vilpn0-MuiPaper-root-MuiTableContainer-root{
    background-color: #CDD5E0;
}

.light-mode .view-mode-div::after {
    background-color: #222F3F;
}

.light-mode .display-switching-btn img{
    filter: invert(16%) sepia(11%) saturate(1935%) hue-rotate(176deg) brightness(10%) contrast(86%);
}

@media only screen and (max-width: 1024px) and (orientation: portrait){

    .light-mode .header-right-area .hamburger-menu .menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 16px;
        z-index: 999;
        width: 120px;
        height: 40px;
        border: solid 2px #222F3F;
        background-color: rgba(175,231,255,.2);
        font-size: 16px;
    }

    .light-mode .header-right-area .hamburger-menu .menu-btn .menu-line:before {
        top: 16px;
        left: 0;
        background-color: #222F3F;
    }
    .light-mode .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        top: 8px;
        left: 0;
        background-color: #222F3F;
    }

    .light-mode  .header-right-area .hamburger-menu .hamburger-menu-item:first-of-type {
        width: 80%;
        height: 216px;
        margin: 8% auto 0;
        padding: 16px 24px;
        border: solid 2px #222F3F;
        box-sizing: border-box;
        box-shadow: none;
        background-color: rgba(175,231,255,.2);
    }

    .light-mode .header-right-area .hamburger-display-switching .button-area::after {
        box-shadow: none;
        background-color: #222F3F;
        content: '';
    }

    .light-mode .hamburger-display-switching .checkbox-area {
        margin-top: 32px;
        color: #222F3F;
    }

    .light-mode .header-right-area .hamburger-menu-item .hamburger-menu-btn {
        box-shadow: none;
        background-color: rgba(175,231,255,.2);
        border-color: #222F3F;
    }

    .light-mode .header-right-area .hamburger-menu-item .btn-box {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #222F3F;
    }

    .light-mode .header-right-area .hamburger-menu-item .hamburger-menu-btn img {
        filter: invert(16%) sepia(11%) saturate(1935%) hue-rotate(176deg) brightness(10%) contrast(86%);
    }

}

@media only screen and (max-width: 743px) and (orientation: portrait){

    .light-mode .header-right-area .hamburger-menu .menu-btn {
        top: 80px;
        right: 116px;
        width: 40px;
        height: 32px;
    }

    .light-mode .header-right-area .hamburger-menu .menu-btn .menu-line, .header-right-area .hamburger-menu .menu-btn .menu-line:before, .header-right-area .hamburger-menu .menu-btn .menu-line:after {
        height: 2px;
    }

    .light-mode  .header-right-area .hamburger-menu .menu-btn .menu-line {
        top: 6px;
        left: 8px;
    }
}

/* headerSpMenu.css から */

.light-mode .sp-bottom-header .first-header-content, .sp-bottom-header .second-header-content{
    color: #222F3F;
}

.light-mode .sp-bottom-header .second-header-bg {
    background: #CDD5E0;
}

/* setting.css から */

.light-mode .menu-content {
    width: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -130%;
    z-index: 998;
    background-color: #CDD5E0;
    transition: all 0.5s;
}

/* Eventページ */

.event-choice-container .event-selected-top-text .light-mode-switch-Box{
    margin-bottom: 16px;
    margin-left: 88px;
}

.light-mode .header-custom::before {
    display: block;
    position: absolute;
    top: 0;
    left: 5vw;
    width: 90vw;
    height: 48px;
    margin: 0 auto;
    background-image: url(../image/header-tit-bk_light.png);
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
}

.light-mode .event-choice-container .eventPage-btn-container button {
    position: relative;
    width: 305px;
    height: 46px;
    padding: 12px 10px;
    border: none;
    box-sizing: border-box;
    background-image: url(../image/common-button_light.svg);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    color: #222F3F;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.light-mode .event-choice-container .event-tit-container {
    width: 451px;
    height: 124px;
    margin: 0;
    background-image: url(../image/evet-tit-bg_light.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.light-mode .event-race-button {
    position: relative;
    height: 36px;
    padding: 5px;
    border: none;
    border: solid 2px #222F3F;
    background-color: #222F3F;
    color: #ffffff;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.light-mode .event-race-button::before {
    position: absolute;
    top: -2px;
    left: -16px;
    width: 8px;
    height: 36px;
    background-color: #222F3F;
    content: '';
}

.light-mode .event-choice-container .event-tit-container h1{
    color: #222F3F;
}

.light-mode .event-race .race-date, .event-race .race-name {
    font-weight: bold;
    font-size: min(1.3vw, 24px);
    color: #222F3F;
}

.light-mode .event-selected-top-text .event-list-button {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 200px;
    height: 56px;
    background-color: #222F3F;
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.light-mode .event-selected-top-text .event-list-button .border-line {
    display: block;
    display: block;
    width: 80%;
    height: 2px;
    background-color: #ffffff;
}

.light-mode .border-line {
    border: 2px solid #222F3F;
}

.light-mode .event-choice-container .event-list-button::after {
    position: absolute;
    top: -199%;
    right: -55%;
    transform: scale(.11) rotate(180deg);
    content: url(../image/allow-right_light.png);
}

.light-mode .event-race::before {
    display: inline-block;
    position: absolute;
    bottom: -30px;
    left: 3vw;
    width: 50vw;
    height: 60px;
    background-image: url(../image/event-race-tit_light.png);
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}

.light-mode .event-race-buttons::after {
    display: block;
    position: absolute;
    top: -15px;
    right: -11vw;
    width: 10vw;
    height: 64px;
    background-image: url(../image/event-race-tit-after_light.png);
    background-position: right;
    background-size: 100%;
    background-repeat: no-repeat;
    content: '';
}

@media only screen and (max-width: 1366px) {
    /* イベント各詳細ページ */
    /* レースタイプ　文字サイズ */
    .light-mode .event-race .race-date, .event-race .race-name {
        font-weight: bold;
        font-size: min(1.5vw, 24px);
    }

    .light-mode .event-choice-container .eventPage-btn-container button:first-child {
        margin-right: 0;
        margin-bottom: 16px;
    }

}

@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .light-mode .event-choice-container .event-tit-container {
        width: 43%;
    }

    .light-mode .event-choice-container .eventPage-btn-container button {
        width: 52%;
        font-size: min(1.5vw, 16px);
    }

    .light-mode .event-race::before {
        left: 0;
        width: 45vw;
    }

    .event-choice-container .event-selected-top-text .light-mode-switch-Box {
        margin-bottom: 16px;
        margin-left: 260px;
    }
}

@media only screen and (max-width: 743px) and (orientation: portrait) {
    .sp-bottom-header {
        display: block;
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin: 0;
        padding: 0;

        gap: 0;
    }

    .sp-bottom-header .first-header-content, .sp-bottom-header .second-header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        height: 10svh;
        margin: 0 auto;
        box-sizing: border-box;
        gap: 2%;
    }

    .sp-bottom-header .second-header-content {
        align-items: center;
        height: 8svh;
    }

    .sp-bottom-header .second-header-bg {
        width: 100vw;
        border-top: solid 2px #ffffff;
        background: rgba(175, 231, 255,.4);
    }

    .sp-bottom-header .first-header-content .display-div {
        font-size: 12px;
    }

    .display-title {
        font-size: 10px;
    }

    .sp-bottom-header .first-header-content .display-div:first-of-type {
        width: 10%;
        margin: 0;
        font-size: 12px;
        text-align: center;
    }

    .sp-bottom-header .first-header-content .display-div:nth-of-type(2) {
        width: 20%;
        margin: 0;
        font-size: 12px;
        text-align: center;
    }
    .sp-bottom-header .menu-display {
        width: 40%;
        margin: 0;
        font-size: 12px;
        text-align: center;
        margin-left: 4px;
        height: 60%;
    }

.sp-bottom-header .menu-display .display-div .display-value-flag{
    padding-top: 8px;
}

.sp-bottom-header .menu-display{
    padding-top: 12px;
}

    .sp-bottom-header .sp-weather-card {
        display: flex;
        align-items: center;
        width: 30%;
        margin: 0;
        padding: 8px 0;
        border-top: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        background-color: rgba(0, 0, 0, .4);
        font-size: 12px;
    }

    .sp-bottom-header .first-header-content .display-value {
        font-size: 18px;
    }


    .sp-bottom-header .sp-weather-card {
        display: flex;
        font-weight: bold;
        font-size: 8px;
    }

    .sp-bottom-header .sp-weather-card img {
        width: 30%;
        height: 70%;
    }

    .sp-bottom-header .latest-update-time, .sp-bottom-header .opciton-menu {
        position: relative;
        width: 100%;
    }

    .sp-bottom-header .opciton-menu {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-right: 0;
    }

    .sp-bottom-header .time-label, .sp-bottom-header .time-value {
        font-size: 16px;
    }

    .sp-bottom-header  .circle-button {
        width: 32px;
        height: 32px;
    }

    .ellipse-button {
        width: 80px;
        margin: 0 0 0 8px;
        font-size: 16px;
        height: 32px;
    }

    .latest-update-time::before, .latest-update-time::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        box-shadow: 0 0 7px #ffffff;
        background-color: #ffffff;
        content: '';
    }

    .latest-update-time::after {
        bottom: -6px;
    }
}

@media only screen and (max-width: 932px) and (orientation: landscape) {
.sp-bottom-Mapheader{
    width: 100vw;
    height: 96px;
    display: block;
    margin: 0;
    background-color: rgba(175, 231, 255,.4);
    border-top: solid 2px #ffffff;
    display: flex;
    justify-content: space-between;
}

.sp-bottom-Mapheader .header-left-area,
.sp-bottom-Mapheader .header-right-area{
    display: flex;
    align-items: center;
}

.sp-bottom-Mapheader .first-header-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 96px;
    padding: 0 16px;
}

.sp-bottom-Mapheader .display-div{
    margin-bottom: 0;
    margin-right:8px;
}
.sp-bottom-Mapheader .display-title,
.sp-bottom-Mapheader .display-value{
    font-size: 16px;
}

.sp-bottom-Mapheader .display-value.double-border::before, 
.sp-bottom-Mapheader .display-value.double-border::after {
    position: absolute;
    bottom: -4px;
    left: 26%;
    width: 52%;
    height: 2px;
    box-shadow: 0 0 7px #ffffff;
    background-color: #ffffff;
    content: '';
}

.sp-bottom-Mapheader .display-value.double-border::after {
    bottom: -8px;
}

.sp-bottom-Mapheader .sidemenu-weather-card{
    width: 180px;
    height: 88px;
    box-sizing: border-box;
}

.sp-bottom-Mapheader .second-header-content{
    display: flex;
    align-items: center;
}

.sp-bottom-Mapheader .opciton-menu{
    display: flex;
    margin-right: 0;
}

.sp-bottom-Mapheader .latest-update-time span{
    font-size: 12px;
}

}



.setup-file-container {
    height: 70%;
    width: 100%;
    margin: 0 auto;
    overflow: auto;
    color: #fff;
    position: relative;
    margin: 0;
}

.setup-file-container::-webkit-scrollbar {
    width: 0;
    height: 0.6rem;
}

.setup-file-container::-webkit-scrollbar-thumb:horizontal {
    border-radius: 10px;
    background-color: rgb(132, 130, 130);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.setup-file-btn-area {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    position: sticky;
    left: 0;
}

.buttons-container {
    display: flex;
    height: 13%;
    margin: 0 0;
    justify-content: center;
}

.buttons-container div {
    height: 45%;
    width: 13%;
    margin: 3vh 2vw;
}

.buttons-container .cancel {
    background: center right / 100% 100% no-repeat url('../image/material/options_btn_cancel.png');
}

.buttons-container .start {
    background: center left / 100% 100% no-repeat url('../image/material/options_btn_start.png');
}

.tab-buttons {
    height: 4%;
    width: 350px;
    margin-right: auto;
    display: flex;
    position: sticky;
    left: 0;
}

.tab-button {
    width: 23%;
    padding: 10px;
    position: relative;
    line-height: 1.1vh;
    cursor: pointer;
    font-size: 0.7rem;
    background: center right / 100% 100% no-repeat url('../image/tab-button.svg');
    z-index: 1;
}

/* .tab-button:first-child {
    margin-left: 2%;
} */

.tab-button:nth-of-type(2),
.tab-button:nth-of-type(3),
.tab-button:last-child {
    position: absolute;
}

.tab-button:nth-of-type(2){
    left: 27%;
}

.tab-button:nth-of-type(3){
    left: 53%;
}

/* .tab-button:last-child {
    left: 80%;
} */



.tab-button.active {
    background: center right / 100% 100% no-repeat url('../image/tab-button-active.svg');
    z-index: 2;
}

.tab-containers {
    width: 100%;
    height: 94%;
}

.setup-tab-container {
    display: none;
}

.setup-tab-container.active {
    display: block;
}

.setup-table .table-header,
.tab-table th {
    background-color: #222F3F;
    text-align: left;
    font-weight: bold;
    /* padding-left: 2%; */
}

.th-base-header,
.td-base {
    word-break: break-all;
    width: 150px;
}

.setup-table .th-recommend-header,
.tab-table .th-recommend-header {
    background-color: #FFA800;
    color: #FFFFFF;
    word-break: break-all;
    width: 150px;
}

.th-header-df212c {
    background-color: #222F3F !important;
}

.th-header-ee7948 {
    background-color: #ee7948 !important;
}

.th-header-ff774d {
    background-color: #ff774d !important;
}

.th-header-f8c19b {
    background-color: #f8c19b !important;
}

.th-header-ea5548 {
    background-color: #ea5548 !important;
}

.th-header-ec6d5e {
    background-color: #ec6d5e !important;
}

.th-header-f29b8f {
    background-color: #f29b8f !important;
}

.th-header-f0b5ae {
    background-color: #f0b5ae !important;
}

.th-header-fae0e0 {
    background-color: #fae0e0 !important;
}

.th-header-fdf5f4 {
    background-color: #fdf5f4 !important;
}

.setup-table tbody tr td {
    border: 1px solid #fff;
}

.setup-table thead tr:first-of-type td {
    border: 1px solid #fff;
}

.setup-table .td-fixed-column-0 {
    /* min-width: 144px;
    padding: 0; */
    min-width: 95px;
    padding-left: 5px;
}

table {
    width: 100%;
}

.setup-file-container table{
    background-color: #56717D;
}

.setup-file-container table,
.setup-file-container th,
.setup-file-container td {
    border-collapse: collapse;
    cursor: default;
    font-size: .9rem;
}

.setup-file-container table,
.setup-file-container th {
    border: 1px solid #FFFFFF;
}

.setup-file-container td {
    border-right: 1px solid #FFFFFF;
}

.setup-file-container tr:nth-child(even) {
    background: rgba(175, 231, 255, 0.2);
}

.setup-file-container tr:nth-child(odd) {
    background: rgba(86, 113, 125, 0.4);
    margin: 4px 0;
}

.setup-file-container .env-row:nth-child(even) {
    background-color: #644C66;
}

.setup-file-container .env-row:nth-child(odd) {
    background-color: #93728D;
}

.setup-file-container th,
.setup-file-container td {
    word-break: break-all;
    min-width: 150px;
    width: 150px;
    max-width: 150px;
    font-weight: bold;
    padding-left: 2%;
}

.setup-file-container th:nth-of-type(2),
.setup-file-container tr td:nth-of-type(2) {
    word-break: break-all;
    min-width: 80px;
    width: 80px;
    max-width: 80px;
    font-weight: bold;
    padding-left: 2%;
}

.setup-file-container td {
    padding: 2px 0 2px 1%;
    /* padding-bottom: 1em; */
    box-shadow: 0 1px 10px #000000;
    position: relative;
}

.setup-table table,
.setup-table th,
.setup-table td {
    border-collapse: separate;
    border-spacing: 4px 0px;
    cursor: default;
    font-size: 12px;
}

.setup-table th,
.setup-table td {
    word-break: break-all;
    min-width: 80px;
    padding: 5px;
}

td[contenteditable=true] {
    cursor: text;
}

.td-green {
    display: block;
    background: rgba(34, 74, 210, .3);
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.td-red {
    position: relative;
    display: block;
    background: rgba(255, 82, 61, .3);
    text-align: center;
    font-size: 12px;
    font-weight: bold;
}

.td-red::before {
    position: absolute;
    display: block;
    content: '';
    border-right: 8px solid transparent;
    border-bottom: 11.6025px solid #FF0000;
    border-left: 8px solid transparent;
    left: 11px;
    top: 5px;
    filter: drop-shadow(0px 0px 3px rgba(255, 255, 255, 1));
    transform: rotate(180deg);
}

select {
    width: 100%;
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
}

.setup-file-container table select{
    width: 90%;
    font-family: 'Saira Condensed', sans
    -serif;
    font-weight: bold;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}


.setup-file-container table, selectt::-ms-expand {
    display: none;
}

.setup-file-container .selecter-td::before,
.setup-file-container .selecter-td::after {
    position: absolute;
    display: block;
    z-index: 2;
    right: 11px;
    top: 30%;
    content: '';
    width: 8px;
    height: 8px;
    border: 0.1rem solid currentColor;
    border-left: 0;
    border-bottom: 0;
    box-sizing: border-box;
    transform: translateY(-25%) rotate(135deg);
    color: #20FFAF;
}

.setup-file-container .selecter-td::before{
    width: 10px;
    height: 10px;
    color: #fff;
    border: 0.3rem solid currentColor;
    border-left: 0;
    border-bottom: 0;
    top: 30%;
    right: 10px;

}





option {
    color: #000;
}

.tab-table .ui-selecting {
    /* background: #00cdd426; */
    background: transparent;
    border: 1px dotted #03dbd0;
}

.tab-table .ui-selected {
    background: #00cdd452;
    color: white;
}

.copy-paste-buttons {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 4%;
    position: sticky;
    left: 0;
    margin-top: 0px;
    gap: 6px;
}

.copy-paste-button {
    width: 70px;
    cursor: pointer;
    text-align: center;
    line-height: 2.4vh;
    font-size: 0.9rem;
    padding: 4px;
    margin-bottom: 3px;
    background: url(../image/button-container.png) no-repeat center center / 100% 100%;
}

.add-remove-column-button {
    height: 22px;
    width: 22px;
    text-align: center;
    cursor: pointer;
    vertical-align: middle;
    background-color: rgba(175, 231, 255, 0.2);
    border: 1px solid #fff;
    border-radius: 50%;
    /* background: url(../image/add-button.png) no-repeat center center / 100% 100%; */
}

.copy-paste-button:hover,
.add-remove-column-button:hover {
    opacity: .7;
}

td .slider {
    position: unset;
}

td .slider:before {
    content: none;
}

.setup-file-container .th-recommend-header,
.setup-file-container .td-recommend {
    position: sticky;
    right: 0;
    z-index: 3;
}

.setup-file-container tr:nth-child(even) .td-recommend {
    background: #698898;
}

.setup-file-container tr:nth-child(odd) .td-recommend {
    background: #57717d;
    margin: 4px 0;
}


.delete-button {
    cursor: pointer;
}

/* 横画面のタブレット */

@media only screen and (max-width: 1366px) and (orientation: landscape) {

    .setup-file-container {
        height: 65%;
    }

    .copy-paste-button {
        padding: 1%;
    }

    .setup-file-container .tab-buttons {
        height: 6%;
    }

    .setup-file-container .tab-button {
        line-height: 1vh;
    }

    .setup-table {
        width: 100%;
        max-width: 80%;
    }

    .setup-table-laptime table,
    .setup-table-handling table {
        width: 100%;
        table-layout: fixed;
        word-break: break-all;
        word-wrap: break-all;
    }

    .setup-table-laptime{
        max-width: 80%;
        overflow-x: scroll;
    }



}

/* 縦画面のタブレット */

@media only screen and (max-width: 1366px) and (orientation: portrait) {

    .setup-file-container {
        height: 65%;
    }

    .copy-paste-button {
        padding: 1%;
    }

    .setup-file-container .tab-buttons {
        height: 6%;
    }

    .setup-file-container .tab-button {
        line-height: 1vh;
    }

    .setup-table-laptime table,
    .setup-table-handling table {
        width: 100%;
        table-layout: fixed;
        word-break: break-all;
        word-wrap: break-all;
    }


}
.setup-history-main {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;

    font-size: large;
}

.setup-history-table {
    margin-top: 20px;
    width: 90%;
    border-collapse: collapse;
}

.setup-history-table tr {
    height: 20px;
}
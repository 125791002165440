/* 表示切り替え */
.display-block {
    display: block;
}

.display-none {
    display: none;
}

/* 表示切り替えここまで */

/* メイン画面 */
.main-work-container {
    height: calc(100vh - 116px); /*コンテンツいっぱいに　ヘッダー分引く */
    margin: 0;
    position: relative;
    font-family: 'Noto Sans JP', sans-serif;
}

.current-page-title {
    height: 90px;
    width: 23%;
    position: absolute;
    top: 2%;
    left: 24px;
    /* background: url(../image/current-page-title.png) no-repeat center center / 100% 100%; */
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 90px;
}

/* 最適化ボタンのみ */
.action-button-container.optimize-buttons {
    display: flex;
    width: 12%;
    height: 5%;
    position: absolute;
    right: 0.5%;
    top: 6%;
}

.action-button.button-optimize {
    position: relative;
    height: 20px;
    width: 50%;
    font-weight: bold;
    padding: 10px;
    border: none;
    background-color: #fff;
    color: #000;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
}

.action-button.button-optimize::before {
    height: 40px;
}

/* .button.button-download {
    height: 40px;
    width: 40px;
    position: absolute;
    right: 0;
    top: 8%;
    border: none;
     background: url(../image/download-button.png) no-repeat center center / 100% 100%; 
}   */


/* flex指定 */
.display-flex {
    display: flex;
    height: 100%;
    width: 100%;
}

/* メイン画面　左 */
.display-flex .left {
    height: 100%;
    width: 30%;
}

.center.tabs {
    height: 98%;
    width: 2%;
    margin-left: 0.5%;
}

/* メイン画面　右 */
.display-flex .right {
    height: 100%;
    width: calc(70% - 8px);
    background: rgba(86, 113, 125, 0.4);
    border: 1px solid #FFFFFF;
    overflow: auto;
    margin-left: 8px;
    padding: 24px 32px;
    box-sizing: border-box;
    position: relative;
}

.display-flex .right::-webkit-scrollbar {
    display: none;
}

/* 最適化後　ボタンエリア */
.display-flex.button-containers {
    height: auto;
    margin-bottom: 20px;
    justify-content: space-between;
    position: relative;
}

/* 右側　メインボタン */
.result-containers-switch-btn{
    display: flex;

}

.result-containers-switch-btn .button-result{
    height: 48px;
    width: 174px;
    line-height: 48px;
    font-size: 16px;
    border: 2px solid #fff;
    margin: 0;
    position: relative;
    text-align: center;
}

.result-containers-switch-btn .button-result:first-of-type{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%, 0 70%);
}

.result-containers-switch-btn .button-result:last-of-type{
    clip-path: polygon(85% 0, 100% 30%, 100% 100%, 0 100%, 0 0);
}

.result-containers-switch-btn .button-result::before{
    content: '';
    display: block;
    width: 2px;
    height: 65px;
    background-color: #FFFFFF;
    position: absolute;
    bottom: -19px;
    transform: rotate(121deg);
}

.result-containers-switch-btn .button-result:last-of-type::before{
    bottom: 3px;
    right: 0px;
    transform: rotate(120deg);
}

.result-containers .table-maximize {
    margin-right: -23%;
    margin-top: 23%;
}

.result-action-btn{
    height: 52px;
    display: flex;
    align-items: center;
}

.result-action-btn .action-button{
    width: 133px;
    height: 33px;
    line-height: 24px;
    margin-right: 16px;
    text-align: center;
    box-sizing: border-box;
}

.result-action-btn .action-button::before{
    height: 33px;
}

.result-action-btn .button-optimize{
    position: relative;
    width: 133px;
    height: 33px;
    line-height: 33px;
    margin: 0;
    border: none;
    background-color: #FFFFFF;
    box-shadow: 8px 6px 10px 0px rgba(0, 0, 0, 1);
    color: #000;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 16px;
    box-sizing: border-box;
    line-height: 16px;
}

.result-action-btn .button-optimize::before{
    position: absolute;
    content: '';
    display: block;
    width: 7px;
    height: 33px;
    background-color: #FFFFFF;
    box-shadow: 5px 0px 0px 0px rgba(0, 0, 0, .4);
    left: -12px;
    
}


.button.button-download {
    height: 40px;
    width: 40px;
    background-color: rgba(175, 231, 255, .2);
    border-radius: 50%;
    border: 2px solid #fff;
    line-height: 37px;
    text-align: center;
}

.button.button-download img {
    width: 64%;
    height: 64%;
    vertical-align: middle;
}

/* ボタン選択時の背景色 */
.button-result.active  {
    border:2px solid #4AFFFF;
    color: #4AFFFF;
    /*text-shadow: #4AFFFF 1px 0 10px;*/
}

.button-result.active::before {
    background-color: #4AFFFF;

}

.button-result.active

/* 右側　メインボタン　ホバー時 */
.right .button:hover {
    opacity: 0.7;
}

/* 横画面のタブレット */
@media only screen and (max-width: 1366px) and (orientation: landscape) {

    /* メイン画面　左 */
    .display-flex .left {
        width: 40%;
        height: 100%;
        max-width: 450px;
    }

    /* メイン画面　右　*/
    .display-flex .right {
        width: 55%;
        height: 100%;
        margin-left: 5%;
    }

    /* 右側　メインボタン */
    .right .button {
        width: 17%;
        height: 3vh;
        line-height: 3vh;
        font-size: .9rem;
    }
}

/* 縦画面のタブレット */
@media only screen and (max-width: 1366px) and (orientation: portrait) {

    /* メイン画面　左　*/
    .display-flex .left {
        width: 48%;
        height: 100%;
    }

    /* メイン画面　右　*/
    .display-flex .right {
        width: 60%;
        height: 100%;
        margin-left: 4%;
    }

    /* 右側　メインボタン */
    .right .button {
        width: 17%;
        height: 3vh;
        line-height: 3vh;
        font-size: .9rem;
    }

}
/* 共通 */

.resize-container{
    height: 32%;
    margin-bottom:1%;
}

.graph-title button span {
    display: block;
    content: '';
    width: 3px;
    height: 3px;
    border-left: solid 2px #fff;
    border-top: solid 2px #fff;
    position: absolute;

}

.graph-title button .Resize-mark-top-left {
    top: -.5px;
    left: -1px;
}

.graph-title button .Resize-mark-top-right {
    top: -.5px;
    right: -1px;
    transform: rotate(90deg);
}

.graph-title button .Resize-mark-bottom-left {
    bottom: -.5px;
    left: -1px;
    transform: rotate(-90deg);
}


.graph-title button .Resize-mark-bottom-right {
    bottom: -.5px;
    right: -1px;
    transform: rotate(180deg);
}


.graph-title button .reduction-mark-top-left {
    top: -.5px;
    left: -1px;
    transform: rotate(180deg);
}

.graph-title button .reduction-mark-top-right {
    top: -.5px;
    right: -1px;
    transform: rotate(270deg);
}

.graph-title button .reduction-mark-bottom-left {
    bottom: -.5px;
    left: -1px;
    transform: rotate(90deg);
}


.graph-title button .reduction-mark-bottom-right {
    bottom: -.5px;
    right: -1px;
    transform: rotate(0deg);
}



/* 最適化　エリア　*/
.result-containers {
    width: 100%;
    height: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    margin: auto;
}

/* 最適化　エリアここまで　*/

/* セレクター */
/* 最適化後　エリア　セレクターエリア選択 */
.result-containers .select-container {
    width: 150px;
    height: 70px;
    font-size: 12px;
    padding-right: 7px;
}

/* 最適化後　エリア　セレクターエリア　コンテンツ */
.select-child {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
}

/* セレクターボックス　 */
.result-containers .select-child select,
.result-containers .select-child input {
    border: 1px solid #FFFFFF;
    background: #222F3F;
    color: #FFFFFF;
}

.result-container .select-child {
    display: flex;
}

.result-containers .select-child select {
    width: 80%;
    font-family: 'Saira Condensed', sans-serif;
}

.result-containers option {
    color: #FFFFFF;
    font-family: 'Saira Condensed', sans-serif;
}

.result-container .select-child {
    display: flex;
}

.result-containers .select-child select {
    width: 100%;
    font-family: 'Saira Condensed', sans-serif;
    padding: 0;
}

.compare-time-chart-container .select-child select{
    width: 80%;
}

.result-graph-container > .display-flex .select-child select{
    width: 50%;
}

.result-containers option {
    color: #FFFFFF;
    font-family: 'Saira Condensed', sans-serif;
}

/* セレクタータイトル */
.select-title {
    display: block;
}

/* セレクター　ここまで */

.result-place {
    height: 23%;
    margin-bottom: 1%;
}

.result-place:last-child {
    margin-bottom: 0;
}

.result-place.flex {
    height: 27%;
    display: flex;
    gap: 1%;
}

.result-graph-container {
    width: 100%;
    height: 100%;
    max-height: 100%;
    background: rgba(175, 231, 255, 0.2);
    border: 1px solid #FFFFFF;
}

.result-place:last-child {
    margin-bottom: 0;
}

.result-place.flex {
    height: 25%;
    display: flex;
    gap: 16px;
}

.result-graph-container {
    width: 100%;
    height: 100%;
    /* max-height: 100%; */
    background: rgba(175, 231, 255, 0.2);
    border: 1px solid #FFFFFF;
    margin-bottom: 1%;
}

.result-graph-container .scat-container {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 16px;
}

.result-graph-container .chart-container canvas {
  position: relative !important;
}

/* セクションタイトル */
.graph-title {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0 1%;
    display: flex;
    justify-content: start;
    align-items: center;
    background: #222F3F;
    border-bottom: 1px solid #FFFFFF;
    position: relative;
}

.graph-title p {
    margin: 0 0 0 2%;
}
/* セクションタイトル　ここまで */


/* セクション表示切り替えボタン */
.graph-title button {
    width: 3%;
    height: 100%;
    position: absolute;
    right: 2%;
    top: -8%;
    padding: 0;
    font-size: 20px;
    font-weight: bold;
    border: none;
    background: transparent;

    z-index: 999;
}
/* セクション表示切り替えボタン　ここまで */

.graph-result-container {
    width: 100%;
    margin: auto;
    padding: 0 24px;
    box-sizing: border-box;
}


/* キャンパス指定しているコンテンツ　デフォルト値 */
.result-containers .canvas-container {
    width: 30%;
    height: 100%;
}

.result-containers .display-rsize .canvas-container {
    height: auto;
}

/* キャンパス指定しているコンテンツ　ここまで */
/*Result_性能比較*/

/* 全体の各セクション */
.result-containers .compare-time-chart-container,
.result-containers .setup-table-container,
.result-containers .corner-line-container,
.result-containers .corner-chart-container,
.corner-chart-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 77%;
    overflow-y: scroll;
    /* padding-bottom: 8px;
    border-bottom: solid 1px #fff; */
    flex-grow: 1;
}

.result-containers .compare-time-chart-container::-webkit-scrollbar,
.result-containers .setup-table-container::-webkit-scrollbar,
.result-containers .corner-line-container::-webkit-scrollbar,
.result-containers .corner-chart-container::-webkit-scrollbar,
.corner-chart-container::-webkit-scrollbar{
    display: none;
}

.result-containers .setup-table-container {
    padding: 0 3px;
}

/* 全体の各セクション　ここまで */

/* 各セクション　テーブル */
.setup-table-container .table-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1%;
}

/* テーブルの値　 デフォルト値 */
.setup-table-container table {
    width: 100%;
    height: 88%;
}

/* 各セクション　テーブル　ここまで */



/* Time, StaticKPI */
.setup-table.setup-table-laptime {
    width: 100%;
    overflow-y: scroll;
}


/* Base to recommend time */
.result-graph-container.w-50{
    width: 50%;
}

.result-graph-container.w-100{
    display: flex;
    flex-direction: column;

    min-width: 500px;
    width: 100%;
    height: 100%;
}

.result-graph-container.w-100 .display-flex{
  flex-direction: column;
  gap: 10px;
}

.result-graph-container.w-100 .table-container{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.result-graph-container.w-100 .plotly-container{
  transform: none !important;
}

.result-graph-container.w-100 .graph-result-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 100%;
    min-width: 400px;
    width: 100%;
}

.result-graph-container.w-100 .select-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.result-graph-container.w-100 .select-title{
  width: 100%;
  text-align: left;
}

.result-graph-container.w-100 .setup-table table {
  width: 800px;
}

.result-graph-container.w-100 .compare-time-chart-container .chart-container{
  width: 100%;
}

.result-graph-container.w-100 .compare-time-chart-content canvas{
  width: 750px !important;
  height: 350px !important;
}

.result-graph-container.w-100 .setup-table.setup-table-laptime {
  overflow: hidden;
}

.result-graph-container.w-100 .corner-chart-content{
  width: 100%;
}

.result-graph-container.w-100 .corner-chart-container canvas{
  width: 900px !important;
  height: 350px !important;
}

.result-graph-container.w-100 .scat-container{
  flex-direction: column;
}

.result-graph-container.w-100 .scat-container canvas{
  width: 800px;
  height: 300px;
}

.result-graph-container.w-100 .graph-container-ver {
  flex-direction: column;
  align-items: center;
}

.result-graph-container.w-100 .graph-container-ver .graph-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 1300px;
}

.result-graph-container.w-100 .graph-container-ver canvas{
  width: 400px !important;
  height: 300px !important;
}

.result-graph-container.w-100 .graph-line-container canvas{
  width: 400px;
  height: 300px;
}

.result-graph-container.w-100 .select-container-flex-boxs{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.compare-time-chart-content {
    width: 753px;
    height: 90%;
}

.compare-time-chart-content .chart-container {
    width: 753px;
    /* height: 93%; */
    border: none;
    box-shadow: none;
    background: none;
    margin-bottom: 1%;
}

.compare-time-chart-content .select-child {
    width: 100%;
}

.result-containers.compare-time-chart-container {
    flex-grow: 2;
}

.setup-table .td-fixed-column-1{
    min-width: 80px;
}

.setup-table .td-fixed-column-2{
    min-width: 80px;
}

/* Base to recommend time　　ここまで*/



/* Handling Mean　Aero Balance Mean　Mech Balance Mean */

.setup-table  tr:nth-child(even){
    background: rgba(175, 231, 255, 0.2);
}

.setup-table  tr:nth-child(odd){
    background: rgba(86, 113, 125, 0.4);
}

/* グラフ３つの全体幅 */
.result-containers .corner-line-container {
    flex-grow: 2;
}

.corner-line-container .graph-container {
    display: flex;
    width: 753px;
    position: relative;
}


/* グラフ１つ１つの幅 */
.graph-container .graph {
    width: 25%;
}

.graph-item {
    height: 80%;
}

.graph-item canvas {
    height: 100%;
}

.corner-line-container .select-container {
    height: 14vh;
}

.corner-line-container .select-child {
    width: 100%;
    margin-left: 5%;

}

.result-graph-container > .display-flex {
    width: 100%;
    margin: auto;
    justify-content: space-between;
}

.result-containers .result-graph-container.w-50 .handling-balance-action-btn{
}

.result-containers .result-graph-container.w-50 .select-container{
    height: auto;
    width: 11%;
    text-align: end;
}

.result-containers .result-graph-container.w-50 .input-checkbox-container{
    margin: 0;
}

.result-containers .result-graph-container.w-50 .handling-balance-action-btn input[type='checkbox']{
    appearance:auto;
}

/* Corner KPI */
.result-containers .corner-chart-container{
    height: 85%;
    width: 100%;
    border-bottom: none;
}

.corner-chart-container .select-child {
    width: 100%;
    margin: 1%;
    /* display: flex; */
}

.corner-chart-content {
    /* height: 95%; */
    /* height: 100px; */
    width: 753px;
}

.corner-line-container {
    display: flex;
    justify-content: space-between;
}

.corner-line-container .graph-container {
    border: none;
}

/* Corner KPI ここまで */

/*Result_傾向確認*/
.result-containers.trend .result-place {
    height: 30%;
}

.graph-result-container.trend.display-flex {
    height: 78%;
    justify-content: space-between;
}

.graph-result-container.trend.display-flex .canvas-container {
    height: 100%;
    width: 50%;
}

.graph-result-container.trend.display-flex .canvas-container .graph-item {
    height: 100%;
    width: 100%;
}

.graph-result-container.trend.display-flex {
    height: 78%;
    justify-content: space-between;
}

.graph-result-container.trend.display-flex .canvas-container {
    height: 100%;
    width: 50%;
}

.graph-result-container.trend.display-flex .canvas-container .graph-item {
    height: 100%;
    width: 100%;
}

/* 全体の各セクション */
/* .kpi-container,
.graph-container-ver,
.plotly-container {
    flex-grow: 1;
    height: 30%;
} */

/* 全体の各セクション ここまで */

/* カラーチャート */
.select-color {
    width: 100%;
    height: 7%;
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
}

.select-color .select-container {
    width: 25%;
    height: 100%;
}

.select-color .select-container .select-child {
    display: flex;
    gap: 4%;
    align-items: center;
}

.select-color .select-container .select-child select {
    width: 70%;
}

.select-color .select-container {
    width: 100%;
    height: 100%;
}

.select-color .select-container .select-child {
    display: flex;
    gap: 4%;
    align-items: center;
    position: absolute;
    left: 38%;
    top: 42px;
}

.select-color .select-container .select-child select {
    width: 50%;
}


/* カラーチャート ここまで*/

/* セレクター */
.kpi-container .select-container {
    display: block;
    width: 100%;
    height: 100%;
    margin-left: auto;
}

.kpi-container .select-child {
    width: 140px;
    margin: 1% 0 3%;
    display: flex;
    align-items: center;
}

.kpi-container .select-title {
    width: 20%;
    margin-right: 5%;
    display: unset;
}

/* セレクター ここまで*/

/* １つ目　２つ目のセクション　ボータライン */
.border-bottom {
    border-bottom: solid 1px #fff;
    padding-bottom: 8px;
}

/*１つ目 セクション (.kpi-container) */

.kpi-container {
    display: flex;
    gap: 16px;
    margin-bottom:1%;
}

.kpi-container .scat-container {
    width: 67%;
    height: 80%;
    display: flex;
    align-items: flex-start;
}

.kpi-container .graph {
    width: 49%;
}

.kpi-container .graph .title-flex {
    display: flex;
    align-items: center;

}

/*１つ目 セクション　ここまで */

/* 2つ目 セクション(.graph-container-ver) */

.graph-container-ver {
    width: 100%;
    height: 100%;
    margin-bottom:1%;
    display: flex;
}

.graph-container-ver .graph-group {
    width: 80%;
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
}

.graph-container-ver .display-flex {
    height: auto;
    width: 20%;
}

.graph-container-ver .title-flex {
    display: flex;
    align-items: center;
}

.graph-container-ver .graph {
    width: 30%;
}

.display-none .graph-container-ver .graph {
    height: 0;
}

.graph-container-ver .canvas-container {
    width: 100%;
    height: 70%;
}


/*2つ目 セクション　ここまで */

/* ３つ目 セクション */

.dimension-chart-container{
    height: 100%;
}

.graph-line-container{
    width: 80%;
    margin: 0;
    padding: 0;
    padding-left: 24px;
    justify-content: space-around;
}

.plotly-container {
    width: 100%;
    height: 81%;
    transform: translate(10%, -37%) scale(1, 0.4);
}

.result-graph-container .graph-title-boxs{
    width: 80%;
    display: flex;
    justify-content: space-between;
}

.result-graph-container .graph-title-boxs p{
    width: 30%;
    margin: 0;
    text-align: center;
}


/* .user-select-none.svg-container{
    width: 100% !important;
    height: 100% !important;
}

.js-plotly-plot .plotly .main-svg{
    width: 100% !important;
    height: 100% !important;
}

.gl-canvas.gl-canvas-context{
    width: 100% !important;
    height: 100% !important;
}

.gl-canvas .gl-canvas-pick{
    width: 100% !important;
    height: 100% !important; 
} */

/* Multi dimension chart */


.input-checkbox-container {
    display: flex;
    justify-content: end;
    margin-left: 8px;
    font-size: 12px;
}


/* 横画面のタブレット */
@media only screen and (max-width: 1366px) and (orientation: landscape) {

    /* カラーチャート */

    .select-color {
        width: 50%;
        height: 10%;
    }

    /* カラーチャート　ここまで */


    /* 最適化　エリア　*/

    .result-containers {
        width: 95%;
    }

    /* 最適化後　エリア　セレクターエリア選択 */
    .result-containers .select-container {
        width: 100%;
        display: flex;
    }

    /* 最適化後　エリア　セレクターエリア　コンテンツ */
    .select-child {
        width: 30%;
    }

    /* 最適化　エリア　ここまで　*/



    /* セクションタイトル */

    .graph-title {
        font-size: .9rem;
    }

    .graph-title button {
        width: 10%;
    }

    /* セクションタイトル　ここまで */

    /* 各セクション　テーブル */

    .setup-table-container .table-container {
        width: 100%;
        flex-direction: column-reverse;
        justify-content: start;
    }

    /* 各セクション　テーブル　ここまで */

    /*Result_性能比較*/

    .result-containers .compare-time-chart-container .chart-container,
    .result-containers .setup-table-container .setup-table-laptimeg,
    .result-containers .corner-line-container .setup-table-handling,
    .result-containers.corner-chart-container .graph-container,
    .corner-chart-container .corner-chart-content {
        width: 100%;
    }

    .result-containers .compare-time-chart-container,
    .result-containers .setup-table-container,
    .result-containers .corner-line-container,
    .result-containers.corner-chart-container,
    .corner-chart-container {
        flex-direction: column-reverse;
        justify-content: start;
    }

    /* Time, StaticKPI */

    .time-staticKPI-container .select-container {
        height: auto;
    }

    /* Time, StaticKPI ここまで */

    /* Base to recommend time */

    .result-containers.compare-time-chart-container {
        flex-grow: 2;
    }

    .compare-time-chart-content {
        width: 100%;
        height: 100%;
    }

    .compare-time-chart-content .chart-container {
        width: 100%;
        height: 85%;
    }

    .compare-time-chart-container .select-container{
        position: absolute;
        right: 0;
    }

    /* Base to recommend time　ここまで */


    /* Handling Mean　Aero Balance Mean　Mech Balance Meanのエリア */

    .corner-line-container .graph-container {
        width: 100%;
        flex-grow: 1;
    }

    .result-containers .scat-container {
        width: 100%;
        height: 50%;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .display-switch-btns {
        width: 16%;
        right: -30px;
        top: 10px;
    }


    /* Handling Mean　Aero Balance Mean　Mech Balance Meanのエリア　ここまで */


    /* Corner KPI */

    .corner-chart-content {
        width: 80%;
        height: 100%;
    }

    .corner-line-container .select-container {
        gap: 8px;
    }

    .corner-line-container .select-child {
        width: 30%;
    }

    .corner-line-container .select-child {
        margin-left: 0;
    }

    .corner-chart-container .select-child select {
        width: 30%;
    }

    /* Corner KPI */


    /*Result_傾向確認*/


    /* kpi-containerのCSS */
    .kpi-container .select-container {
        width: 100%;
        height: 50%;
    }

    .js-plotly-plot {
        width: 100%;
    }

    .kpi-container .select-title {
        width: 55%;
    }


    /* １つ目のセクション */

    .kpi-container .scat-container {
        height: 70%;
    }

    .kpi-container .select-container {
        height: 30%;
    }

    .kpi-container .select-title {
        width: 20%;
    }


    /* ２つ目のセクション */

    .graph-container-ver {
        height: 100%;
        flex-direction: column-reverse;
    }

    .graph-container-ver .select-container {
        gap: 4%;
    }

    .graph-container-ver .select-child {
        width: 20%;
    }

    .graph-container-ver .graph {
        width: 33%;
    }


    /* ３つ目 セクション */


    .plotly-container {
        width: 100%;
        height: 100%;
        /* transform: translate(-19%, -30%) scale(0.7, 0.45); */
    }

}

/* 縦画面のタブレット */

@media only screen and (max-width: 1366px) and (orientation: portrait) {}
/*　ーーーーー　全体　ーーーーー　*/

.custom-modal {
    width: 900px;
    padding: 0 64px !important;
    background-color: #000000;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    position: relative;
}

.custom-modal::-webkit-scrollbar {
    display: none;
}

.top-dialog {
    display: block;
    padding: 0;
    padding: 40px 64px 0;
    border: none;
    background-color: #000000;
    color: #ffffff;
}

.top-dialog-background {
    position: relative;
    width: 100%;
    max-width: 1423px;
    height: 80px;
    margin: 0 auto 40px;
    background-image: url(../image/modal-header.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.top-dialog-background h2 {
    position: absolute;
    top: 50%;
    left: 50%;
            transform: translate(-50%, -50%);
    padding: 0;
    font-weight: bold;
    font-size: 16px;

    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
}

.modal-exit {
    position: absolute;
    top: -24px;
    right: -40px;
    width: 25px;
    height: 25px;
    margin: 0;
    margin-left: auto;
    border: 3px solid #ffffff;
    border-radius: 50%;
    font-size: 15px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    z-index: 999;
}

.side-contens {
    display: inline-block;
    position: absolute;
    transform: rotate(90deg);
    color: #ffffff;
    font-weight: bold;
    font-size: .55rem;;
}

.contens-left {
    top: 70px;
    left: -81px;
}

.contens-right {
    right: -81px;
    bottom: 34px;
}


/*　ーーーーー　ラップ表のヘッダー部分　ーーーーー　*/

.all-laps-options {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 5px;
}

.all-laps-all-options {
    display: flex;
    justify-content: right;
    align-items: center;
    margin-right: 50px;

    gap: 20px;
}

.all-laps-options input {
    width: 20px;
    height: 20px;
}

.visually-hidden {
    clip-path: inset(50%);
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    white-space: nowrap;

    clip: rect(0 0 0 0);
}

.all-laps-options label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.all-laps-options label::before, .all-laps-options label::after {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    content: '';
}

.all-laps-options label::before {
    left: 5px;
    width: 20px;
    height: 20px;
    border: 2px solid #4affff;
    border-radius: 50%;
    background-color: #000000;
}

.all-laps-options label::after {
    opacity: 0;
    left: 9px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #4affff;;
}

.all-laps-options input:checked + label::after {
    opacity: 1;
}

/*　ーーーーー　今後の予測　ーーーーー　*/
.pace-catcher-pit, .pace-catcher-follow {
    display: block;
    color: #ffffff;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
    text-align: right;
}

.pace-catcher-area .example-box {
    display: inline-block;
    width: 25px;
    height: 10px;
    border: 2px solid #bc1414;
    box-shadow: 0 0 4px 1px red;
    background: transparent;
}

.pace-catcher-area .pit-example-box {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border-bottom: #ffffff 1px solid;
    background-color: rgba(175,231,255,.2);
}

.pace-catcher-table {
    position: relative;
    width: 90%;
    margin: 40px auto 0;
    color: #ffffff;
    font-family: 'Noto Sans JP', sans-serif;
    border-collapse: separate;
    border-spacing: 8px 16px;
}

.pace-catcher-table::before {
    display: block;
    position: absolute;
    top: 1%;
    left: -55px;
    width: 5px;
    height: 99%;
    border-right: solid #ffffff .5px;
    border-left: solid #ffffff .5px;
    box-shadow: 1px #ffffff;
    content: '';
}

.pace-catcher-table::after {
    display: block;
    position: absolute;
    top: 1%;
    right: -55px;
    width: 5px;
    height: 99%;
    border-right: solid #ffffff .5px;
    border-left: solid #ffffff .5px;
    box-shadow: 1px #ffffff;
    content: '';
}

.pace-catcher-table thead {
    position: relative;
}



.pace-catcher-table .pace-catcher-tr {
    position: relative;
    border-bottom: solid 2px #ffffff;
}

/*.pace-catcher-table .pace-catcher-tr::after {
    display: block;
    position: absolute;
    bottom: -14px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.light-mode .pace-catcher-table .pace-catcher-tr::after{
    background-color: #222F3F;
}*/

.pace-catcher-table th, .pace-catcher-table td {
    padding: 10px;
    border: none;
    font-weight: 700;
    text-align: center;
}

.pace-catcher-table th {
    min-width: 90px;
    background-color: #000000;
    font-size: 24px;
}

.pace-catcher-table th::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid #ffffff;
    content: '';
}

.pace-catcher-table td:first-child {
    position: relative;
    font-weight: bold;
    font-size: 24px;
}

.pace-catcher-table .follow-mark, .pace-catcher-table .rival-mark {
    position: absolute;
    left: 5px;
    color: #ffa800;
}

.pace-catcher-area .switch-area {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 300px;
    margin: 0;
}

.pace-catcher-area .remarks-area {
    display: flex;
    justify-content: space-between;
}

.pace-catcher-area .switch-upper {
    position: relative;
    height: 40px;
}

.pace-catcher-area .switch-upper::before {
    display: block;
    position: absolute;
    bottom: 0;
    width: 250px;
    height: 1px;
    background: #ffffff;
    content: '';
}

.pace-catcher-area .switch-upper::after {
    display: block;
    position: absolute;
    bottom: -4px;
    left: 250px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ffffff;
    content: '';
}

.pace-catcher-area .switch-upper .switch {
    margin: 0;
    vertical-align: middle;
}

.pace-catcher-area .switch-area-base {
    position: absolute;
    top: 30px;
    left: 0;
    margin: 0;
    padding: 0;
}

.pace-catcher-area .switch-area-base li {
    display: inline-block;
    transform: skewX(320deg);
    width: 8px;
    height: 8px;
    margin-right: 3px;
    background: #ffffff;
}

.pace-catcher-area .switch-area-text {
    margin: 0;
    margin-right: 14px;
    color: #ffffff;
    vertical-align: middle;
}

.pace-catcher-area .pit-info {
    padding: .5% 15%;
    border-bottom: 1px solid #000000;
    background-color: rgba(175,231,255,.2) ;
}

/*　ーーーーー　スティント履歴　ーーーーー　*/

.stint-history-tit {
    display: flex;
    align-items: center;
    position: relative;
    width: 440px;
}

.stint-history-tit::before {
    display: block;
    width: 440px;
    height: 70px;
    background: url(../image/Stint-History-info.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
}


.stint-history-tit .mark-tit {
    position: absolute;
    bottom: 30px;
    left: 220px;
    margin: 0;
}

.stint-history-tit .all-tit {
    position: absolute;
    bottom: 30px;
    left: 300px;
    margin: 0;
}

.stint-history-table {
    position: relative;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 140px;
    color: #ffffff;
    font-family: 'Noto Sans JP', sans-serif;
    border-collapse: separate;
    border-spacing: 10px 20px;
}

.stint-history-table::before {
    display: block;
    position: absolute;
    top: 1%;
    left: -55px;
    width: 5px;
    height: 99%;
    border-right: solid #ffffff .5px;
    border-left: solid #ffffff .5px;
    box-shadow: 1px #ffffff;
    content: '';
}

.stint-history-table::after {
    display: block;
    position: absolute;
    top: 1%;
    right: -55px;
    width: 5px;
    height: 99%;
    border-right: solid #ffffff .5px;
    border-left: solid #ffffff .5px;
    box-shadow: 1px #ffffff;
    content: '';
}


.stint-history-table thead {
    position: relative;
}

.tr-before-content {
    display: block;
    position: absolute;
    bottom: -14px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}

.stint-history-table tr::after {
    display: block;
    position: absolute;
    right: 0;
    bottom: -14px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
}


.stint-history-table  th, .stint-history-table  td {
    padding: 10px;
    border: none;
    text-align: center;
}

.stint-history-table th::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid #ffffff;
    content: '';
}

.stint-history-table .rival-tr::after {
    display: block;
    position: absolute;
    bottom: -14px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffa800;
    content: '';
}

.stint-history-table .rival-tr td::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid #ffa800;
    content: '';
}

.stint-history-table tr {
    position: relative;
    font-size: 24px;
}

.stint-history-table .follow-tr::after {
    display: block;
    position: absolute;
    bottom: -14px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    content: '';
}

.stint-history-table .detailed-time-td {
    /*width: 35%;
    max-width: 80px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;*/
}

.stint-history-table .follow-column .first-td, .stint-history-table .follow-column .rivel-td {
    display: flex;
    justify-content: space-between;
    width: 35%;
    max-width: 64px;
    margin: 0 auto;
}

.stint-history-table .follow-tr td::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid red;
    content: '';
}

.stint-history-table  th {
    background-color: #000000;
    font-weight: bold;
}

.stint-history-table  td:first-child {
    font-weight: bold;
}

.stint-history-table .follow-column td .rivval, .stint-history-table .follow-column td .follow img {
    display: inline-block;
    width: 1.2vw;
    height: 1.2vw;
    margin-right: 8px;
}

.stint-history-table .rivval {
    color: #ffa800;
}

.stint-history-table .follow {
    vertical-align: middle;
}

.stint-history-table .follow-tr .stintdata-lasps {
    display: inline-block;
    margin-right: 8px;
    padding: 0 3%;
    border-bottom: 1px solid #ffffff;
    background-color: rgba(255, 99, 71,.4);
}

.stint-history-table .rival-tr .stintdata-lasps {
    display: inline-block;
    margin-right: 8px;
    padding: 0 3%;
    border-bottom: 1px solid #ffffff;
    background-color: rgba(255, 165, 0,.4);
}

.stint-history-alltable td::before {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid #ffffff;
    content: '';
}

.stint-history-alltable .stintdata-lasps {
    display: inline-block;
    padding: 0 3%;
    border-bottom: 1px solid #ffffff;
    background-color: rgba(175,231,255,.2);
}

/* History */
.race-data {
    display: flex;
    flex-direction: column;
    min-height: 800px;
    padding: 10px;
}

.top-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 900px;
    color: black;
}

.top-table .top-table-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 20px;
}

/*　ーーーーー　全体のラップの表　ーーーーー　*/

.alldriverlaps-main-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 16px;
}

.alldriverlaps-main-container-right {
    display: flex;
    flex-direction: column;
}

.all-driver-header-container {
    display: flex;
    margin-bottom: 8px;
}

.container-switching-btns {
    display: flex;
    justify-content: flex-end;
}

.container-switching-btns p {
    margin: 0 16px 0 0;
}

.container-switching-btns .table-icon-area label, .container-switching-btns .table-icon-area .icon-underbar {
    margin-right: 8px;
}

.icon-underbar {
    border-bottom: 2px solid #4affff;
}

.icon-underbar img{
    filter: invert(10%) sepia(17%) saturate(5669%) hue-rotate(152deg) brightness(111%) contrast(103%);
}

.all-driver-header-container button:first-child {
    margin-right: 8px;
}

.all-driver-header-button {
    width: 160px;
    border: 2px solid #ffffff;
    background-color: #000000;
    color: #ffffff;
}

.all-personal-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;

    gap: 20px;
}

.all-personal-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;

    gap: 20px;
}

.lap-history-upper {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
}

.lap-history-container {
    display: flex;
    margin-top: 16px;
}

.lap-history-select-boxs {
    position: relative;
    width: 100%;
    max-width: 200px;
    margin: 0 auto 0;
}

.lap-history-select-boxs::before {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: #4affff transparent transparent transparent;
    content: '';

    pointer-events: none;
}


.lap-history-select {
    width: 160px;
    height: 40px;
    border: 2px solid #ffffff;
}

.lap-history-change-view-container {
    position: absolute;
    top: 4rem;
    right: 8rem;
    z-index: 6;
    width: 250px;
    padding-top: 20px;
    background-image: linear-gradient(90deg, #343434 1px, transparent 1px),
    linear-gradient(180deg, #343434 1px, transparent 1px);
    background-size: 20px 20px;
    background-color: #242424;
    color: white;
}

.lap-history-change-view-bottom {
    height: 45px;
}

.lap-history-change-view-close-button {
    width: 41%;
    height: 50%;
    margin: 4% auto;
    border: 1px solid #000000;
    border-radius: 15px;
    background-color: #ffffff;
    color: #000000;
    font-size: .9rem;
    text-align: center;
    cursor: pointer;
}

.driver-table {
    display: flex;
    flex-direction: column;
}

.lap-data {
    display: flex;
    flex-direction: row;
    max-width: 1200px;
}

.lap-data h3 {
    min-width: 120px;
}

.lap-data .lap-number {
    width: 70px;
}

.lap-data .ots-remainingtime-time {
    width: 250px;
}

.lap-data {
    position: relative;
    padding-top: 10px;
    color: black;
}

.divider-line {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
}

.divider-line-special {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #cccccc;
}

.bestPR {
    background-color: limegreen;
}

.heatmap1 {
    background-color: #fa6a6a;
}

.heatmap2 {
    background-color: #ffdada;
}

.heatmap3 {
    background-color: white;
}

.heatmap4 {
    background-color: #c3e4f7;
}

.heatmap5 {
    background-color: #a2c2e6;
}

.heatmap-example {
    width: 150px;
}

.race-data .best-race-data-table tr.noBeforeAfter::before, .race-data .best-race-data-table tr.noBeforeAfter::after {
    content: none !important;
}

/* 全体 */
.lap-times-table-container {
    height: calc(100vh - 174px);
    overflow: auto;
}

.lap-times-table-container::-webkit-scrollbar {
    display: none;
    padding: 0;
}

.lap-times-table {
    width: 100%;
    margin-bottom: 20px;
    color: #000000;
    border-collapse: collapse;
}

.lap-times-table::-webkit-scrollbar {
    display: none;
    padding: 0;
}
.lap-times-table tbody .lap-times-Table-tr {
    color: #000000;
}

.lap-times-table tbody .lap-times-Table-tr td:nth-child(1) {
    background-color: #ffffff;
    color: #000000;
}

.lap-times-table tbody .lap-times-Table-tr:nth-child(even) {
    background-color: #ffffff;
}

.lap-times-table tbody .lap-times-Table-tr:nth-child(odd) {
    background-color: #ffffff;
}

.lap-times-table th, .lap-times-table td {
    padding: 2px 6px;
    border: 1px solid #cccccc;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
}

.lap-times-table thead {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
}

/* ラップ表スクロール時の上の隙間を埋める */
.lap-times-table thead th::before {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 100%;
    border-top: 1px solid #cccccc;
    content: '';
}

.lap-times-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.lap-times-table tbody tr:nth-child(even) {
    background-color: #ffffff;
}

.lap-times-table tbody td {
    border-right: 1px solid #cccccc;
}

.lap-times-table tbody tr:last-child td {
    border-bottom: 1px solid #cccccc;
}

.lap-times-table tbody td:first-child {
    border-left: 1px solid #cccccc;
}

/* Pit Data */

.car-driver-top-info {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 300px;
}

.car-driver-top-info .driver-name-info {
    position: relative;
    width: 463px;
    max-width: 100%;
    height: 66px;
    background: url(../image/driver-name-info.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.car-driver-top-info .driver-name-info h4 {
    position: absolute;
    top: 0;
    left: 120px;
    width: 65%;
    margin: 0;
    font-size: 32px;
    text-align: center;
}

.car-driver-top-info .driver-CarNo-info {
    position: relative;
    width: 262px;
    height: 66px;
    background: url(../image/driver-carNo-info.png);
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: end;
}

.car-driver-top-info .driver-CarNo-info h4 {
    position: absolute;
    top: 8px;
    right: 0;
    width: 17%;
    margin: 0;
    font-size: 32px;
    text-align: center;
}

.car-driver-top-info div, .car-driver-top-info h4 {
    width: 33%;
    text-align: center;
}

.car-driver-top-info .driver-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.pit-stop-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}


.pit-stop-info span {
    text-align: start;
}

.pit-stop-info .pit-stop-times-tit-bg {
    position: relative;
    width: 100%;
    height: 66px ;
    background-image: url(../image/pit-stop-times-tit.png);
    background-position: right 0 bottom 10px;
    background-size: contain;
    background-repeat: no-repeat;
}

.pit-stop-info .pit-stop-times-tit {
    position: absolute;
    top: 16px;
    left: 10px;
    width: 100%;
    margin: 0;
    font-size: 24px;
    text-align: left;
}

.pit-stop-info .pit-stop-times-tit span {
    position: absolute;
    top: -20px;
    right: 60px;
    font-size: 32px;
}


.pit-select-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.pit-select-area .pit-select-btn-area {
    display: flex;
    align-items: center;
}



.pit-select-area p {
    margin: 0;
    margin-left: 8px;
    font-size: 32px;
    white-space: nowrap;
}

.pit-select-area p span {
    font-size: 18px;
}

.pit-select-area .pit-action-area {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto;
    margin-left: 16px;
    white-space: nowrap;
}

.pit-select-area .pit-action-area span {
    font-weight: normal;
    font-size: 16px;
}

.car-driver-top-info .pit-stop-times {
    display: flex;
    flex-direction: column;
    position: relative;
    width: auto;
}

.car-driver-top-info .pit-stop-times::before {
    display: block;
    position: absolute;
    top: 12px;
    left: -70px;
    transform: rotate(55deg);
    width: 60px;
    height: 2px;
    border-radius: 50px;
    background: #ffffff;
    content: '';
}

.car-driver-top-info .pit-stop-times::after {
    display: block;
    position: absolute;
    top: 36px;
    left: -25px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ffffff;
    content: '';
}

.triangle {
    width: 30px;
    height: 30px;
    padding: 0;
    border: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    background-color: transparent;
}
.add-btn {
    margin-bottom: 16px;
    border-bottom: calc(tan(60deg) * 8px) solid #fb8b8b;
}

.remove-btn {
    transform: rotate(0deg);
    border-top: calc(tan(60deg) * 8px) solid #04e3e3;;
}

.car-driver-top-info .pit-select-area {
    width: auto;
}

.currentLap-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    height: 100%;
    background-image: url(../image/CurrentLap.png);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
}

.currentLap-info h4 {
    position: absolute;
    top: 50%;
    top: 50%;
    left: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 1.5rem;
}

.pit-times-info > p {
    width: 100%;
    height: 10vh;
    margin: 0;
    background-image: url(../image/pit-times-info.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    font-size: 1.5rem;
    text-align: end;
}


/* .pit-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 900px;
} */

.pit-table-wrap {
    width: 100%;
    overflow-x: scroll;
}

.tenlap-before-average .pit-table-wrap, .tenlap-after-average .pit-table-wrap {
    margin-right: 3%;
    margin-left: 3%;
}


.pit-table {
    min-width: 300px;
    max-width: 845px;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: left;
}

.car-driver-middle-info {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh;
    margin-bottom: 24px;

    gap: 20px;
}

.after-line-graph, .pit-bar-graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 480px;
    min-width: 20vw;
    margin-bottom: 10px;
    gap: 10px;
}

.after-line-graph {
    flex-direction: row;
}

.after-line-graph .timeComparison-flex-container {
    margin-left: 0;
}
.after-line-graph .updown {
    width: 16px;
}

.after-line-graph .timeComparison-flex-container {
    width: 3vw;
    height: calc(65vh - 125px);
}

.after-line-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 20vw;
}

.tenlap-before-average .allow-left, .tenlap-after-average .allow-left, .tenlap-before-average .allow-right, .tenlap-after-average .allow-right {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    box-sizing: border-box;
}

.tenlap-before-average .allow-left, .tenlap-after-average .allow-left {
    display: inline-block;
    top: 50%;
    left: 0;
    border-left: 0;
    border-width: .375em .64952em;
    border-style: solid;
    border-color: transparent;
    border-right-color: currentColor;
    background-color: transparent;
    color: #ffffff;
    line-height: 1;
    vertical-align: middle;
}

.tenlap-before-average .allow-right, .tenlap-after-average .allow-right {
    display: inline-block;
    top: 50%;
    right: 22%;
    width: 0;
    height: 0;
    border-right: 0;
    border-width: .375em .64952em;
    border-style: solid;
    border-color: transparent;
    border-left-color: currentColor;
    background-color: transparent;
    color: #ffffff;
    line-height: 1;
    vertical-align: middle;
}



.tenlap-before-average .pit-table, .tenlap-after-average .pit-table {
    position: relative;
    width: 100%;
    min-width: 1000px;
    max-width: none;
}

.scroll-boxs {
    width: 100%;
    overflow-x: scroll;
}

.tenlap-before-average .move-right, .tenlap-after-average .move-right {
    transform: translateX(-100px);
}

.tenlap-before-average .pit-table::before, .tenlap-after-average .pit-table::before {
    /* content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent;
  border: none;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin: 0;
  border-bottom: calc(tan(60deg) * 15px) solid #fff; */
}

.pit-table th {
    text-align: center;
}

.pit-table td {
    position: relative;
    height: 60px;
}

.scroll-content td span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
            transform: translate(-50%, -50%);
    font-size: 24px;

    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
}

.pit-table td::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 64px;
    background-image: url(../image/lap-taimes.png);
    background-position: center;
    background-size: auto min(80%, 200px);
    background-repeat: no-repeat;
    content: '';
}

.tenlap-before-average .pit-table td::before, .tenlap-after-average .pit-table td::before {
    content: none;
}

.pit-table td::after {
    position: absolute;
    top: 50%;
    left: 88%;
    width: 25%;
    height: 2px;
    background-color: #ffffff;
    content: '';
}

.tenlap-before-average .pit-table td::after, .tenlap-after-average .pit-table td::after {
    content: none;
}

.pit-table td:last-child::after {
    content: none;
}

.fivelap-before-average, .fivelap-after-average, .tenlap-before-average, .tenlap-after-average {
    display: flex;
    align-items: flex-end;
    position: relative;
}

.fivelap-before-average, .tenlap-before-average {
    width: 100%;
    margin-bottom: 16px;
}

.fivelap-after-average, .tenlap-after-average {
    width: 100%;
    margin-bottom: 32px;
}

.fivelap-before-average .average-time, .fivelap-after-average .average-time, .tenlap-before-average .average-time, .tenlap-after-average .average-time {
    font-size: 32px;
    font-family: 'Noto Sans JP', sans-serif;
}

.pit-faster {
    position: relative;
    width: 28%;
    margin: 0;
    margin-left: 2%;
    color:#fb8b8b; 
    font-size: 12px;
}

.pit-slower {
    position: relative;
    width: 28%;
    margin: 0;
    margin-left: 2%;
    color: #04e3e3;
    font-size: 12px;
}

.separator-line, .separator-line {
    position: absolute;
    top: 68%;
    right: 17.5%;
            transform: translate(-50%, -50%) rotate(90deg);
    margin: 0;
    padding: 0;

    -webkit-transform: translate(-50%, -50%) rotate(90deg);
        -ms-transform: translate(-50%, -50%) rotate(90deg);
}

.separator-line li, .separator-line li {
    display: inline-block;
    transform: skewX(320deg);
    width: 4px;
    height: 4px;
    margin-right: 3px;
    background: #ffffff;
}

.white-font {
    color: #ffffff;
}

.event-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 20px;
}

.event-form-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 10px;
}

.event-label {
    width: 100px;
}

/* Event editior */

.top-dialog .edit-event-tit {
    position: relative;
    width: 405px;
    height: 98px;
    margin: 0;
    margin-right: auto;
    margin-bottom: 80px;
    border: solid 1px #ffffff;
    background-image: none;
}



.edit-event-tit h2 {
    font-size: 24px;
}

.top-dialog .edit-event-tit .modal-exit{
    top: -24px;
    right: -520px;
}


.edit-event-tit .dli-plus:first-of-type {
    top: -.5%;
    left: -4%;
}

.edit-event-tit .dli-plus:nth-of-type(2) {
    top: -.5%;
    right: -4%;
}

.edit-event-tit .dli-plus:nth-of-type(3) {
    bottom: -.5%;
    left: -4%;
}

.edit-event-tit .dli-plus:nth-of-type(4) {
    right: -4%;
    bottom: -.5%;
}

.event-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 900px;
    padding: 24px;
    border-bottom: solid 1px #ffffff;

    gap: 20px;
}

.event-edit .dli-plus {
    width: 22px;
    height: 5px;
}

.event-edit .dli-plus:first-of-type {
    top: 97.5%;
    left: -1.5%;
}

.event-edit .dli-plus:nth-of-type(2) {
    top: 97.5%;
    right: -1.5%;
}

.event-edit-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 20px;
}

.event-edit-buttons button {
    position: relative;
    width: 133px;
    height: 39px;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
}


.event-edit-buttons button::before {
    display: block;
    position: absolute;
    top: -2px;
    left: -13px;
    width: 7px;
    height: 39px;
    background-color: #ffffff;
    color: #000000;
    content: '';
}

.event-edit-single {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 900px;
    min-height: 500px;
    padding: 10px;

    gap: 20px;
}

.event-edit-container {
    position: relative;
    width: 100%;
    padding: 48px;
    margin-top: 80px;
    border: solid 1px #ffffff;
    box-sizing: border-box;
}

.event-edit-container .dli-plus:first-of-type {
    left: -1.9%;
}
.event-edit-container .dli-plus:nth-of-type(2) {
    right: -1.9%;
}
.event-edit-container .dli-plus:nth-of-type(3) {
    left: -1.9%;
}
.event-edit-container .dli-plus:nth-of-type(4) {
    right: -1.9%;
}


.event-edit-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    margin-bottom: 16px;
    font-size: 24px;
    font-family: 'Noto Sans JP', sans-serif;

    gap: 10px;
}

.event-edit-input {
    display: flex;
    width: 600px;

    gap: 10px;
}

.event-edit-inner-name {
    width: 680px;
    height: 56px;
    font-size: 16px;
}

.event-edit-input input[type='text'] {
    width: 400px;
    background-color: rgba(255,255,255,.4);
    color: #ffffff;
}

.event-edit-input select {
    width: 400px;
    background-color: rgba(255,255,255,.4);
    color: #ffffff;
}


.event-edit-inner-button {
    width: 190px;
    color: #ffffff;
}

.event-edit-seperator {
    display: none;
    width: 100%;
    height: 1px;
    background-color: #cccccc;
}

.event-edit-race-menu-tit {
    display: flex;
    justify-content: space-between;
    width: 900px;
}

.event-edit-race-menu-tit div {
    width: 25%;
}

.event-edit-race-menu-tit div:first-of-type {
    width: 40%;
}

.event-edit-race-menu-tit div:nth-of-type(3) {
    width: 10%;
}

.event-edit-race-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    gap: 10px;
}

.event-edit-race {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    width: 900px;
    height: auto;
    padding: 24px 0;
    border-bottom: 1px solid #ffffff;

    gap: 10px;
}

.event-edit-race .dli-plus {
    width: 22px;
    height: 5px;
}

.event-edit-race .dli-plus:first-of-type {
    top: 97.5%;
    left: -1.5%;
}

.event-edit-race .dli-plus:nth-of-type(2) {
    top: 97.5%;
    right: -1.5%;
}

.event-edit-race div {
    width: 25%;
}

.event-edit-race div:first-of-type {
    width: 40%;
}

.event-edit-race div:nth-of-type(3) {
    width: 10%;
}



.event-edit-add-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 900px;
    padding: 10px;
    border-bottom: 1px solid #ffffff;

    gap: 10px;
}

.event-edit-add-menu select {
    width: 326px;
    border-bottom: 1px solid #04e3e3;
}

.event-edit-race select {
    width: 100px;
}

.event-edit-menu-buttons {
    display: flex;
    justify-content: end;
    align-items: center;
    position: sticky;
    background-color: transparent;
    top: 32px;
    right: -48px;
    width: 900px;
    gap: 32px;
    z-index: 999;
    cursor: pointer;
}

.event-edit-menu-buttons button {
    position: relative;
    width: 168px;
    height: 48px;
    background: #ffffff;
    color: #000000;
    font-weight: bold;
}
.event-edit-menu-buttons button span {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: solid 2px #ffffff;
    border-left: solid 2px #ffffff;
    content: '';
}

.event-edit-menu-buttons button .top-page-dec-top-left {
    top: -8px;
    left: -8px;
}

.event-edit-menu-buttons button .top-page-dec-top-right {
    top: -8px;
    right: -8px;
    transform: rotate(90deg);
}

.event-edit-menu-buttons button .top-page-dec-bottom-left {
    bottom: -8px;
    left: -8px;
    transform: rotate(-90deg);
}


.event-edit-menu-buttons button .top-page-dec-bottom-right {
    right: -8px;
    bottom: -8px;
    transform: rotate(180deg);
}


.event-edit-race-date {
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 10px;;
}


.event-edit-race-date .event-edit-race-date-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    gap: 10px;
}

.event-edit-race-date-input .event-edit-time {
    width: 160px;
    margin: 0;
    padding: 0;
}

.event-edit-race-date-input input[type='date'] {
    color: #000000;
}



.edit-notes {
    width: 100% !important;
    font-weight: normal;
    font-size: 10px;
}

.event-edit-race-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.event-edit-race-button {
    position: relative;
    width: 104px;
    height: 39px;
    background-color: #ffffff;
    color: #000000;
    font-weight: bold;
}

.event-edit-race-button::before {
    display: block;
    position: absolute;
    top: -2px;
    left: -13px;
    width: 7px;
    height: 39px;
    background-color: #ffffff;
    color: #000000;
    content: '';
}

.event-edit-time {
    width: 100px;
}

.inlineFlex {
    display: inline-flex;
    vertical-align: middle;
}

.container {
    position: relative;
}

.title {
    width: 21%;
}

/* settingView */
.pitwork-setting-area {
    display: flex;
    flex-direction: column;
}

.pitworktime-area {
    margin-bottom: 10px;
}

.pitwork-setting-area label {
    display: inline-block;
    width: 130px;
    text-align: right;
}

/* ラップ表のチャート全体 */
.lapTime-container{
    position: relative;
}

.lapTime-container .lapTime-flex-container {
    display: flex;
    height: 68vh;
}

/* ラップ表のチャート左のバー　矢印 */
.lapTime-container .updown {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.lapTime-container .lapTime-flex-container .css-qx5f9e-MuiSlider-rail {
    width: 16px;
    border: solid 2px #ffffff;
    border-radius: 0;
    background-color: transparent;
}

.lapTime-container .lapTime-flex-container .css-ada0pd-MuiSlider-track {
    width: 16px;
    border-radius: 0;
    background: repeating-linear-gradient(45deg, #4affff, #4affff 5px, transparent 5px, transparent 10px);
}

.lapTime-container .lapTime-flex-container .css-4kwz88-MuiStack-root {
    margin-left: 16px;
}

.canvas_container{
    width: 100%;
    height: 80%;
    padding: 0 2vw;
}


.horizontalslider{
    justify-content: end;
    width: 80%;
    margin: auto;
    padding-bottom: 10vh;
}

.lapTime-container .lapTime-flex-container .css-y50uh2-MuiSlider-root {
    padding: 0;
}

.lapTime-container .css-y35fuw-MuiSlider-thumb {
    width: 24px;
    height: 8px;
    border-radius: 0;
    background-color: #4affff;
    color: transparent;
}

/* イベント編集 */


/* 横画面のタブレット */
@media only screen and (max-width: 1366px) and (orientation: landscape) {
    .alldriverlaps-main-container {
        /* flex-flow: column;
    align-items: flex-start; */
    }

    .css-hlj6pa-MuiDialogActions-root {
        align-items: flex-start !important;
    }

    /* ラップ表のヘッダー オプション機能 */
    .header-options {
        display: flex;
    }

    .title {
        width: 23%;
    }

    .all-laps-all-options {
        margin-right: 0;

        gap: 0;
    }

    .all-laps-options label {
        min-width: 70px;
    }
}

@media only screen and (max-width: 1366px) and (orientation: portrait) {
    .alldriverlaps-main-container {
        flex-flow: column;
        align-items: flex-start !important;
    }

    .css-hlj6pa-MuiDialogActions-root {
        align-items: flex-start;
    }

    /* ラップ表のヘッダー オプション機能 */
    .header-options {
        display: flex;
    }

    .title {
        width: 23%;
    }

    .all-laps-all-options {
        margin-right: 0;

        gap: 0;
    }

    .all-laps-options label {
        min-width: 70px;
    }
}

/* 縦画面のタブレット */
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    /* 全体 */

    .top-dialog-background{
        background-image: none;
    }

    .modal-exit{
        top: -5px;
        right: -0px;
    }

    .top-dialog {
        padding: 2% 0;
    }

    .custom-modal {
        padding: 0 4px !important;
    }

    .pace-catcher-table th {
        font-size: 16px;
    }

    /* ピットストップ詳細 */

    .css-zw3mfo-MuiModal-root-MuiDialog-root .css-1fc2vt4-MuiPaper-root-MuiDialog-paper {
        margin: 16px;
        padding: 0 8px;
        background: #000000;
    }

    .car-driver-top-info .driver-name-info h4 {
        font-size: 24px;
    }

    .driver-information {
        width: 100%;
    }

    .after-line-graph .timeComparison-flex-container {
        margin: 0;
    }

    .after-line-graph, .pit-bar-graph {
        width: 40vw;
        min-width: auto;
    }

    .after-line-inner {
        width: 40vw;
        min-width: auto;
    }

    .timeComparison-flex-container .flex {
        width: 16px;
    }

    .after-line-graph .timeComparison-flex-container {
        width: 100%;
    }

    .timeComparison-flex-container .css-i1h90w-MuiStack-root {
        height: 30%;
    }

    .pit-table td::before {
        background-size: 95%;
    }

    .pit-table td::after {
        left: 96%;
        width: 10%;
    }

    .pit-table {
        width: 66vw;
    }

    .separator-line, .separator-line {
        right: 23.5%;
    }

    /* ピットストップ詳細ここまで */

    /* ビットストップ詳細 */
    .car-driver-top-info .driver-name-info {
        width: 100%;
    }

    .car-driver-top-info .driver-CarNo-info {
        width: 60%;
    }

    .car-driver-top-info .driver-name-info h4 {
        top: -15%;
        left: 35%;
    }

    .car-driver-top-info .driver-CarNo-info h4 {
        top: 3%;
        right: 6%;
    }

    .pit-stop-info .pit-stop-times-tit {
        top: 20%;
        left: 0;
        font-size: min(2.0vw,20px);
    }

    .pit-stop-info .pit-stop-times-tit span {
        top: -55%;
        right: 12%;
    }

    .car-driver-top-info .pit-select-area {
        flex-direction: column;
        align-items: flex-end;
    }

    .car-driver-top-info .pit-select-area {
        width: 100%;
    }

    .scroll-boxs {
        width: 100%;
        overflow-x: scroll;
    }
    /* ピットストップ詳細ここまで */


    /* 平均ペースをもとに今後の展開 */

    .pace-catcher-area .remarks-area {
        padding: 0 2%;
    }

    .pace-catcher-table {
        padding: 0;
        box-sizing: border-box;
    }

    .pace-catcher-table th {
        min-width: 44px;
    }
    .pace-catcher-table th::before {
        content: none;
    }
    .pace-catcher-table .pace-catcher-tr::after {
        content: none;
    }

    .pace-catcher-table th, .pace-catcher-table td {
        padding: .5%;
    }

    .pace-catcher-table::before {
        content: none;
    }

    .pace-catcher-table::after {
        content: none;
    }

    .side-contens {
        display: none;
    }


    /* 全体のラップの表 */

    .alldriverlaps-main-container {
        width: 100%;
    }

    .header-options {
        display: flex;
        justify-content: flex-end;
        margin-right: 2%;
    }

    .alldriverlaps-main-container .container {
        width: 100%;
    }

    .alldriverlaps-main-container .title {
        width: 18%;
    }

    .alldriverlaps-main-container .all-laps-all-options {
        gap: 0;
    }

    .alldriverlaps-main-container .lap-history-select {
        width: 136px;
    }

    .alldriverlaps-main-container .alldriverlaps-main-container-right {
        width: 100%;
    }

    .alldriverlaps-main-container .lap-history-upper {
        margin-right: 2%;
        margin-bottom: 2%;
    }

    .alldriverlaps-main-container .container-switching-btns {
        margin-right: 2%;
        margin-top: 8px;
    }

    .alldriverlaps-main-container .all-driver-header-container {
        justify-content: flex-end;
        margin-right: 2%;
    }

    .alldriverlaps-main-container .all-driver-header-button {
        width: 30%px;
        height: 40px;
    }



    .alldriverlaps-main-container .all-laps-options label {
        min-width: auto;
    }

    .lap-history-container {
        justify-content: flex-end;
        width: 100%;
    }

    .pace-catcher-area {
        width: 100%;
        max-width: 768px;
        margin: 0 auto;
        overflow: scroll;
    }

    /* スティント履歴 */

    .stint-history-table .follow-column td .rivval, .stint-history-table .follow-column td .follow img {
        width: 4vw;
        height: 4vw;
    }

    .stint-history-table::before, .stint-history-table::after {
        content: none;
    }

    .stint-history-table .follow-tr .stintdata-lasps, .stint-history-table .rival-tr .stintdata-lasps {
        margin-right: 8%;
        padding: 0 5%;
    }

    .stint-history-table .follow-tr .stintdata-lasps, .stint-history-table .rival-tr .stintdata-lasps {
        width: 8%;
        padding: 0 4%;
    }


    .stint-history-alltable .stintdata-lasps {
        width: 8%;
        padding: 0 4%;
    }

    .stint-history-table .follow-tr td {
        position: relative;
    }


    .stint-history-table .follow-tr td::before, .stint-history-table .follow-tr::after {
        display: none;
        content: none;
    }

    .stint-history-table .rival-tr td::before, .stint-history-table .rival-tr::after {
        display: none;
        content: none;
    }

    .tr-before-content {
        display: none;
    }

    .event-edit{
        width: 90%;
    }

    .top-dialog .edit-event-tit .modal-exit{
        top: -8px;
        right: -200px;
    }

}

@media only screen and (max-width: 743px) and (orientation: portrait) {


    .modal-exit{
        top: 0px;
        right: 0px;
    }
    .top-dialog-background{
        background-image: none;
    }

    /* 全体のラップ表 */

    .alldriverlaps-main-container .header-options{
        flex-wrap: wrap;
        justify-content: start;
    }
    
    .alldriverlaps-main-container .all-laps-all-options{
        width: 100%;
        justify-content: start;
    }

    .alldriverlaps-main-container .title{
        width: 100%;
    }

    .alldriverlaps-main-container .all-laps-options{
        width: 25%;
    }

    .all-laps-options label::before{
        width: 14px;
        height: 14px;
    }
    .all-laps-options label::after {
        opacity: 0;
        left: 9px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #4affff;
    }

    .alldriverlaps-main-container{
        font-size: 12px;
    }

    .alldriverlaps-main-container .lap-history-select{
        width: 120px;
    }

    .alldriverlaps-main-container .all-driver-header-button{
        font-size: 12px;
    }

}
.core-admin.input-container {
    width: 55%;
    background: rgba(86, 113, 125, 0.4);
    border: 1px solid white;
    margin: 5% auto 2%;
    padding: 2%;
}

.core-admin.input-container .select-container .select-child,
.core-admin.input-container .settings-content,
.core-admin.input-container .deploy-model-settings {
    display: flex;
    width: 100%;
    margin: 2% 0;
}

.core-admin.input-container .select-container .select-title,
.core-admin.input-container .settings-content .settings-title {
    width: 20%;
    text-align: center;
}

.core-admin.input-container .settings-content .settings-input input[type='file']{
    display: block;
}

.core-admin.input-container .select-container .select-child select,
.core-admin.input-container .settings-content .settings-input {
    width: 70%;
}

.core-admin.input-container .select-container .select-child select {
    padding: 1%;
}

.core-admin.input-container .settings-content .settings-input input {
    width: 98%;
    padding: 1%;
    border: 1px solid #FFFFFF;
}

.core-admin.input-container .settings-content .settings-input textarea {
    width: 100%;
    height: 100px;
}

.core-admin.button-container {
    width: 15%;
    margin: 2% auto 5%;
}

.core-admin.button-container button {
    width: 100%;
    background-color: rgba(86, 113, 125, 0.4);
    color: #FFFFFF;
}

.deploy-model-settings .model-id {
    width: 45%;
}

.deploy-model-settings .model-vehicle,
.deploy-model-settings .model-track,
.deploy-model-settings .model-driver {
    width: 10%;
    text-align: center;
}

.deploy-model-settings .model-remove {
    width: 7%;
    cursor: pointer;
    text-align: center;
}

.deploy-model-settings .model-deploy {
    width: 12%;
    cursor: pointer;
    text-align: center;
}

.deploy-model-settings.header {
    font-weight: bold;
    text-align: center;
}
/* ヘッダー全体 */
header {
    min-height:44px;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 8px;
}

.display-flex.core-header-container {
    justify-content: space-between;
    height: auto;
    align-items: flex-start;
    padding: 40px 24px 32px;
    box-sizing: border-box;
}

/* ヘッダーロゴ　サブタイトル */
header .Core-display-sabtit {
    display: block;
    font-size: 16px;
    color: #fff;
}

/* ヘッダーロゴ */
.core-logo-container {
    width: 290px;
    height: 65px;
    background: bottom left / contain no-repeat url('../image/Logo/logo1_short.png');
    cursor: pointer;
    background-position: top left;
}
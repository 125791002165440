.flex {
    display: flex;
}

.setup-page {
    width: 100%;
    height: 100%;
    color: #fff;
    font-family: 'Noto Sans JP', sans-serif;
    position: relative;
}

.setup-page .loading-container {
    width: 100%;
    height: 97%;
    position: absolute;
    top: 8%;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    display: none;
    z-index: 3;
    pointer-events: none;
}

.setup-page .loading-container #myProgress {
    height: 15px;
    width: 80%;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #fff;
    border-radius: 10px;
    position: absolute;
    top: 72px;
    left: 12%;
}

.setup-page select,
.setup-page option,
.setup-page button {
    font-family: 'Noto Sans JP', sans-serif;
    outline: none;
}

.setup-page table {
    width: 100%;
    table-layout: fixed;
    font-size: 12px;
}

.setup-page.constrain table,
.setup-page.constrain select{
    color: #000000;
    font-size: 16px;
}

.setup-page table tbody tr:nth-child(odd) {
    background-color: rgba(86, 113, 125, 0.8);
}

.setup-page table tbody tr:nth-child(even) {
    background-color: rgba(175, 231, 255, 0.4);
}

.setup-page table td {
    text-overflow: ellipsis;
    overflow: hidden;
}

.setup-page table .center {
    text-align: center;
    padding: 0;
}

.setup-page table .td-dark {
    background-color: #222F3F;
}

.setup-page table .td-transparent {
    background-color: #3b464b;
}

.setup-page table .td-disabled {
    background-color: #3b464b !important;
    border: none;
}

.setup-page table input {
    width: 100%;
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 0;
}

.setup-page table td:not(.td-dark) {
    padding: 0;
}

.setup-page select {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-align-last: center;
    padding: 0;
}

.setup-page td {
    text-align: center;
}

.car-top {
    background: url('../image/gr86_top.png') no-repeat center center / 100% 100%;
}

.car-side {
    background: url('../image/gr86_side.png') no-repeat center center / 100% 100%;
}

/* =================== */

.setup-header {
    position: relative;
    margin-top: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}

.setup-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #FFFFFF;
    height: 2px;
    box-shadow: 0 0 7px #FFFFFF;
}

.setup-header header {
    min-height: 32px;
    margin: 8px 0 8px 16px;
}

.setup-header .core-logo-container {
    width: 145.57px;
    height: 32px;
    min-height: 32px;
    background-size: 100% 100%;
}

.setup-header .header-center {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 25px;
}

.setup-header .action-save {
    position: relative;
    height: 24px;
    width: 56px;
    font-weight: bold;
    padding: 5px 0;
    border: none;
    background-color: #fff;
    color: #000;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    box-shadow: 8px 6px 10px 0px rgba(0, 0, 0, 1);
    position: absolute;
    right: 75px;
}

.setup-header .action-save::before {
    position: absolute;
    top: 0;
    left: -18px;
    content: '';
    width: 8px;
    height: 34px;
    background-color: #fff;
    margin: 0 0 0 3%;
    box-shadow: 5px 0px 0px 0px rgba(0, 0, 0, .4);
}

.setup-circuit-selector-div {
    display: flex;
    border: 1px solid #FFFFFF;
    position: absolute;
    right: 165px;
}

.setup-circuit-selector-div select {
    padding: 8px 0;
}

.setup-event-selector {
    width: 100%;
    height: 32px;
    background-color: rgba(175, 231, 255, 0.4);
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    background: url(../image/event-image.png) no-repeat center center / 100% 100%;
}

.time-kpi-table-container {
    width: 706px;
    height: 154px;
    margin: 9px auto 8px;
    overflow-x: auto;
}

.time-kpi-table-container table {
    width: 100%;
    table-layout: fixed;
    text-align: center;
    font-size: 14px;
}

.time-kpi-table-container thead td:first-child {
    width: 30%;
    background-color: #000000;
    color: #FFFFFF;
}

.result-container {
    width: 100%;
    height: 450px;
    position: relative;
}

.result-container .top-left,
.result-container .bottom-left,
.result-container .top-right,
.result-container .bottom-right {
    position: absolute;
}

.result-container .track-container {
    width: 285px;
    height: 148px;
    margin: 5px auto;
}

.result-container .track-container > img {
    height: 100%;
}

.result-container .chart-container {
    height: 85%;
    width: calc(100% - 16px);
    position: relative;
    margin: auto;
    border: none;
}

.result-container .corner-chart-container,
.result-container .canvas-container {
    height: 95%;
    width: 95%;
    position: relative;
    border: none;
}

.result-container .canvas-container.scat-container {
    height: 85%;
    width: 90%;
    float: right;
}

.result-container .corner-chart-content {
    height: 100%;
    width: 100%;
    margin: auto;
}

.result-container.left .top {
    height: calc((100% - 16px) * 0.6);
    top: 0;
}

.result-container.left .right {
    width: calc((100% - 32px) / 2);
    right: 16px;
}

.result-container.left .bottom {
    height: calc((100% - 16px) * 0.44);
    bottom: 8px;
}

.result-container.left .left {
    width: calc((100% - 32px) / 2);
    left: 16px;
}

/* .x-select-container {
    position: unset;
} */

.x-select-container > .select-child {
    width: 50%;
    height: 20%;
    position: absolute;
    top: 83%;
    left: 25%;
}

.y-select-container {
    width: 45%;
    height: 20%;
    transform: rotate(-90deg);
    position: absolute;
    top: 40%;
    left: -14%;
}

.x-select-container select,
.y-select-container select {
    width: 100% !important;
    border: none !important;
    outline: none !important;
    font-size: 14px;
}


select:focus {
    outline: none;
}

/* result - right */
.result-container.right .top {
    width: 100%;
    height: 50%;
    position: relative;
}

.result-container.right .chart-container .select-container {
    width: 100%;
    height: 15%;
    position: absolute;
    top: 0;
}

.top .chart-container .corner-chart-container {
    height: 80%;
    position: absolute;
    bottom: 0;
}

.result-container.right .bottom {
    width: 100%;
    height: 50%;
    position: relative;
}

.bottom .time-checker-div {
    display: none;
}

.bottom .canvas-container {
    height: 90%;
}

.bottom .css-53tbrc-MuiSlider-root {
    padding: 10px 0;
}

.result-container select {
    width: 50%;
    border: 1px solid #FFFFFF;
}

.result-container .select-title {
    display: none;
}

/* 下半分 */
.user-setup-container {
    width: 100%;
    height: 330px;
    position: relative;
}

.user-setup-options {
    width: 40px;
    height: 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: url(../image/option-button.png) no-repeat center center / 100% 100%;
}

.user-show-container {
    width: 100%;
    height: 25px;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 5px;
}

.user-show-button {
    width: 104px;
    font-size: 13px;
    text-align: center;
    background: url(../image/button-container.png) no-repeat center center / 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
}

.user-show-button.active {
    background: url(../image/active-button-container.png) no-repeat center center / 100% 100%;
}

/* .user-setup-show-base {
    top: 140px;
    right: 1px;
} */

/* .user-setup-is-base-recommend {
    color: #000000;
    border: none;
    background: url(../image/active-button-container.png) no-repeat center center / 100% 100%;
} */

.user-input-container {
    height: 300px;
    background: rgba(175, 231, 255, 0.2);
    border: 1px solid transparent;
}

.user-setup-container .button-container button {
    height: 32px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 4px solid #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-setup-container .button-container .selected-tab {
    border: 1px solid #4AFFFF;
    color: #4AFFFF;
    border-bottom: 2px solid #4AFFFF;
}

.user-input-container td {
    /*padding: 3px 0 2px 8px;*/
    font-weight: bold;
}

.user-input-container .td-input-enabled {
    border: 1px solid white;
}

.user-input-container .td-input-disabled {
    opacity: 0.7;
    border: none !important;
}

.summary-container {
    height: 100%;
}

.summary-left {
    flex: 6;
    margin: 8px auto;
}

.summary-left-left {
    margin-left: 40px;
}

.summary-left-left div:first-child,
.summary-left-right div:first-child  {
    margin-bottom: 10px;
}

.summary-left-middle.car-top {
    height: 171px;
    width: 80px;
    margin: auto 24px;
}

.summary-left-right {
    margin-right: 40px;
}

.summary-small-table {
    width: 125px !important;
    height: 120px !important;
    font-size: 12px;
}

.summary-right {
    flex: 4;
    margin: 8px auto;
}

.summary-right table {
    margin-right: 40px;
}

.summary-single-row {
    width: 250px !important;
    height: 24px !important;
}

.summary-single-row .td-dark-short{
    padding: 3px;
}

.summary-single-row td:first-child {
    width: 55px;
}

.summary-right-middle {
    height: 75px;
    width: 228px;
    margin: 13px 20px 11px 20px;
}

.summary-chassis-table {
    width: 252px !important;
    height: 115px !important;
}

.table-maximize {
    width: 12px;
    height: 12px;
    background: url(../image/maximize-icon.png) no-repeat center center / 100% 100%;
    display: inline-block;
    float: right;
    margin: 3px;
}

/* ALIGNMENT */
.alignment-container.flex {
    justify-content: center;
    align-items: center;
    height: 100%;
}

.alignment-left, 
.alignment-right {
    margin: 3px 0 0;
}

.alignment-middle {
    width: 84px;
    height: 180px;
    margin: 24px;
}

/* CHASSIS */
.chassis-container.flex {
    justify-content: center;
    align-items: center;
    gap : 24px;
}

/* AERO */
.base-selected-recommend-table.aero td:first-child {
    width: 55px;
}

/* 下半分共通 */
.chassis-car,
.aero-car {
    width: 286px;
    height: 96px;
    margin: 8px auto;
    background-size: 100% 90%;
}

.base-selected-recommend-table {
    font-weight: bold;
    background-color: #3b464b;
}

.base-selected-recommend-table.alignment {
    width: 270px !important;
    height: 88px !important;
    margin-top: 8px;
}

.base-selected-recommend-table.chassis {
    width: 290px !important;
    height: 108px !important;
}

.base-selected-recommend-table.aero {
    width: 730px !important;
    height: 64px !important;
    margin: auto;
}

.base-selected-recommend-table .table-edit {
    width: 14px;
    height: 14px;
    background: url(../image/edit.png) no-repeat center center / 100% 100%;
    display: inline-block;
    float: right;
    margin: 3px;
}

.base-selected-recommend-table th {
    background-color: #D8ECF9;
    color: #222F3F;
}

.base-selected-recommend-table td {
    padding: 0 8px;
}

.base-selected-recommend-table .td-selected:not(.td-dark) {
    background-color: #3b464b !important;
    border: 1px solid #FFFFFF;
}

/* SWIPER */
.swiper-button {
    height: 56px;
    width: 28px;
    background: url(../image/swiper.png) no-repeat center center / 100% 100%;
    position: absolute;
    top: 470px ;
    right: 0;
    z-index: 2;
}

.swiper-button.right {
    left: 0;
    transform: rotate(180deg);
}

/* 編集 */
.modal-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.modal-container .modal-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

.base-selected-recommend-table.edit-mode {
    width: 90% !important;
    height: 90% !important;
    margin: auto;
}

.base-selected-recommend-table.edit-mode th {
    font-size: 18px;
}
.base-selected-recommend-table.edit-mode td {
    font-size: 16px;
}

.base-selected-recommend-table .table-save {
    width: 18px;
    height: 18px;
    background: url(../image/save.png) no-repeat center center / 100% 100%;
    display: inline-block;
    float: right;
    margin: 8px;
}

.base-selected-recommend-table.edit-mode select {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.base-selected-recommend-table.edit-mode option {
    font-size: 16px;
}

.base-selected-recommend-table.edit-mode input {
    width: 100%;
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    outline: none;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    text-align-last: center;
}

/* Constrainオプション画面 */
